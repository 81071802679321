import {
  CashDrawer,
  Customer,
  TransactionStatusEnum,
  SignatureImage,
  Site,
  Station,
} from '@emporos/api-enterprise';
import {navigate} from '@reach/router';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TransactionConsolidate,
  OfflineTransaction,
  OfflineSynced,
  Session,
  useAlertState,
  useAuthentication,
  useGlobalData,
  useNetworkAvailable,
  SYNCED_DATE_STORAGE_KEY,
  OTC_CACHE_KEY,
} from '../';
import {useApi} from './ApiProvider';
import {generateSessionKey} from '../utils/session';
import {sessionLocaldb} from '../localDb/sessionLocaldb';
import {useConsoleLogger} from './ConsoleLoggingProvider';
import {User} from '@emporos/hilo-auth';
import {DIFactory} from '../DIFactory';
import {TransactionService} from '../services/TransactionService';

export type TransactionsConfigContextValue = {
  session: Session | null;
  sites: Site[];
  setSession: Dispatch<SetStateAction<Session | null>>;
  loading: boolean;
  ready: boolean;
  createSessionLoading: boolean;
  createSession(
    site: Site,
    station: Station,
    till: CashDrawer,
    tillStartingAmount: number,
    accessCode: string,
    deviceId: string,
    paymentDeviceAddress?: string,
    paymentDevicePort?: string,
  ): Promise<Session | null>;
  closeSessionLoading: boolean;
  closeSession(): void;
  loadUserSession(): void;
  loadSession: () => Promise<void>;
  hardLoadingSession: boolean;
  sessionClosed: boolean;
  setSessionClosed: Dispatch<SetStateAction<boolean>>;
  updatePaymentDeviceAddress(
    session: Session,
    paymentDeviceAddress?: string,
  ): Promise<Session | null>;
  updatePaymentDeviceAddressLoading: boolean;
};
type Props = PropsWithChildren<unknown>;

const noop = () => undefined;

export const transactionsConfigContext =
  createContext<TransactionsConfigContextValue>({
    session: null,
    sites: [],
    setSession: x => x,
    loading: true,
    ready: false,
    createSessionLoading: false,
    createSession: () => Promise.resolve(null),
    closeSessionLoading: false,
    closeSession: noop,
    loadUserSession: noop,
    loadSession: () => Promise.resolve(),
    hardLoadingSession: false,
    sessionClosed: false,
    setSessionClosed: x => x,
    updatePaymentDeviceAddress: () => Promise.resolve(null),
    updatePaymentDeviceAddressLoading: false,
  });

export function TransactionsConfigProvider(props: Props): JSX.Element {
  const api = useApi();
  const {user} = useAuthentication();
  const {notification} = useAlertState();
  const {logError} = useConsoleLogger();
  const [session, setSession] = useState<Session | null>(null);
  const [sessionClosed, setSessionClosed] = useState<boolean>(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [hardLoadingSession, setHardLoadingSession] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);
  const syncingOffline = useRef(false);
  const {
    online,
    tempCachedAccessCode,
    setTempCachedAccessCode,
    userNeedsRefreshed,
  } = useNetworkAvailable();
  const {run: getSites} = api.GetSites();
  const {run: getMySession} = api.GetMySession();
  const {run: getCustomer} = api.GetCustomer();
  const {run: closeSession, loading: closeSessionLoading} = api.CloseSession();
  const {run: createSession, loading: createSessionLoading} =
    api.CreateSession();
  const {
    run: updatePaymentDeviceAddress,
    loading: updatePaymentDeviceAddressLoading,
  } = api.UpdatePaymentDeviceAddress();
  const authStorageService = DIFactory.getAuthStorageService();
  const {paymentTendersResult} = useGlobalData();

  const storeUser = useCallback(
    (user: User, accessCode: string, userSession: Session | null) => {
      authStorageService.storeAuthInfo(user, accessCode, userSession);
    },
    [],
  );

  const removeUser = useCallback((user: User) => {
    authStorageService.removeAuthInfo(user);
  }, []);

  const _updatePaymentDeviceAddress = useCallback(
    async (session: Session, paymentDeviceAddress?: string) => {
      try {
        // transmit the new payment device address to the API
        const remote = await updatePaymentDeviceAddress({
          sessionId: session.sessionId,
          paymentDeviceAddress,
        });

        /**
         * copy the session and replace the payment device address
         * that came back from the API.
         */
        const newSession = {
          ...session,
          paymentDeviceAddress: remote.paymentDeviceAddress,
        };

        // update the session state
        setSession(newSession);

        // notify the user that the operation was successful
        notification({
          type: 'success',
          icon: 'Checkmark',
          title: 'Payment Device Address Updated',
          description:
            'We successfully updated the payment device address for your session.',
        });

        return newSession;
      } catch (error) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Update Payment Device Address Failed',
          description:
            "We couldn't update the payment device address for your session.",
        });
        return null;
      }
    },
    [],
  );

  const _createSession = useCallback(
    async (
      site: Site,
      station: Station,
      till: CashDrawer,
      tillStartAmount: number,
      accessCode: string,
      deviceId: string,
      paymentDeviceAddress?: string,
      paymentDevicePort?: string,
    ) => {
      let remote: Session;
      try {
        //clean OTC cache
        localStorage.removeItem(SYNCED_DATE_STORAGE_KEY);
        await global.caches.delete(OTC_CACHE_KEY);

        // Create a new session.
        remote = await createSession({
          siteId: site.siteId,
          stationId: station.stationId,
          tillId: till.cashDrawerId,
          startingCashBalance: tillStartAmount,
          paymentDeviceAddress: paymentDeviceAddress,
          paymentDevicePort: paymentDevicePort,
          accessCode: accessCode,
          deviceId: deviceId,
        });
      } catch (error) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Open Session Failed',
          description: "We couldn't create a session with your selections.",
        });
        return null;
      }

      setSession(remote);
      storeUser(user as User, accessCode, remote);

      return remote;
    },
    [createSession],
  );

  const _closeSession = useCallback(async () => {
    assert(
      session !== null,
      'Internal Error: called closeSession() with no active session.',
    );

    if (
      session.transactions
        .filter(
          transaction =>
            !(transaction as OfflineSynced).isDeleted &&
            transaction.status !== TransactionStatusEnum.Deleted &&
            transaction.status !== TransactionStatusEnum.Error &&
            transaction.status !== TransactionStatusEnum.Accepted &&
            transaction.status !== TransactionStatusEnum.PatientPay &&
            !(transaction as TransactionConsolidate).isCompleted,
        )
        .some(
          transaction => transaction.status !== TransactionStatusEnum.Complete,
        )
    ) {
      return;
    }
    try {
      const txSvc = new TransactionService();
      txSvc.initialize(
        session.sessionId,
        (user as User).access_token,
        session.accessCode,
        (user as User).profile['sub'],
      );
      const sessionCopy = {...session} as Session;
      await closeSession();
      await txSvc.deleteTransactionsAssociatedWithSessionFromLocalDatabase(
        sessionCopy,
      );
      removeUser(user as User);
      setSessionClosed(true);
      setSession(null);

      return navigate('/sales');
    } catch (error) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Close Session Failed',
        description:
          "We couldn't close your session. Please check your internet connection and try reloading the app.",
      });
    }
  }, [session, user, closeSession]);

  const loadUserSession = useCallback(async (): Promise<Session | null> => {
    if (!online) {
      if (session !== null) {
        return session;
      }
    }

    return getMySession({})
      .then(async sessions => {
        const next = sessions[0];

        if (next && 'sessionId' in next) {
          next.transactions.forEach(transaction => {
            if (transaction.signatures[0]?.signatureImage) {
              (transaction as OfflineTransaction).signatureImage = {
                ...transaction.signatures[0].signatureImage,
                isSynced: true,
              } as SignatureImage;
            }
          });
          await Promise.all(
            next.transactions.map(async transaction => {
              const {customerId} = transaction;
              if (!customerId) {
                return Promise.resolve();
              }
              const {data, error} = await getCustomer({customerId});
              if (error) {
                return Promise.reject(error);
              }
              transaction.customer = data as Customer;
            }),
          );
          setSession(next);
          storeUser(user as User, next.accessCode, next);
        } else {
          logError('No session found');
          setSession(null);
        }
        return next || null;
      })
      .catch(error => {
        logError('Error in loadSession:', error);
        logout();
        throw new Error('No session found');
      });
  }, [online, session, getMySession, getCustomer]);

  const loadSites = useCallback(async () => {
    const {data} = await getSites({});
    if (data) {
      setSites(data);
    }
  }, [getSites]);

  const _loadUserSession = useCallback(async () => {
    setHardLoadingSession(true);

    loadUserSession().finally(() => {
      setHardLoadingSession(false);
    });
  }, [loadUserSession]);

  const initialize = useCallback(async () => {
    if (!loadingSession) {
      setLoadingSession(true);
    }

    // Adding a try catch here because loadUserSession was silently failing
    try {
      await Promise.all([loadUserSession()]);

      if (online) {
        await Promise.all([loadSites()]);
      }
    } catch (err) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Session Failed to Load',
        description:
          'Please check your internet connection and reload the app.',
      });
    }
  }, [loadUserSession, loadSites]);

  const offlineSessionRetrievalAndAssembly = useCallback(
    (nonNullUser: User) => {
      if (!tempCachedAccessCode || !(tempCachedAccessCode.length > 0)) return;
      const sessionKey = 'session:' + nonNullUser.profile['sub'];
      const storedSession = authStorageService.getStoredSession(
        sessionKey,
        tempCachedAccessCode,
      );
      if (storedSession) {
        const txSvc = new TransactionService();
        txSvc.initialize(
          storedSession.sessionId,
          nonNullUser.access_token,
          tempCachedAccessCode,
          nonNullUser.profile['sub'],
        );
        txSvc.getTransactions().then(transactions => {
          setSession({
            ...storedSession,
            transactions,
            accessCode: tempCachedAccessCode,
          });
          setTempCachedAccessCode('');
          setLoadingSession(false);
        });
      }
    },
    [tempCachedAccessCode],
  );

  const buildSessionLocaldb = useCallback(() => {
    const sessionKey = generateSessionKey(user);
    const token = user ? user.access_token : '';
    const userSub = user ? user.profile['sub'] : '';
    const sessionId = session?.sessionId ?? '';
    const accessCode = session?.accessCode ?? '';
    const localSession = new sessionLocaldb(
      sessionKey,
      token,
      sessionId,
      '',
      accessCode,
      userSub,
      paymentTendersResult?.data ?? [],
    );
    return localSession;
  }, [user, session, paymentTendersResult]);

  const {logout} = useAuthentication();

  // loadSession: Loads session data, handles error, and triggers logout on failure
  const loadSession = useCallback(async () => {
    const localSession = buildSessionLocaldb();
    try {
      await localSession.syncTransactionsOffline(session, syncingOffline);
      await initialize();
    } catch (error) {
      // Log error for debugging
      logError('Error in loadSession:', error);
      logout();
    } finally {
      setLoadingSession(false);
    }
  }, [buildSessionLocaldb]);
  // useEffect: Manages session loading and triggers logout on error if necessary
  useEffect(() => {
    if (user) {
      if (!online) {
        offlineSessionRetrievalAndAssembly(user);
      } else {
        loadSession().catch(error => {
          logError('Error in loadSession:', error);
          // Handle any uncaught errors in loadSession
          logout();
        });
      }
    }
    // Dependencies for the effect
  }, [user, offlineSessionRetrievalAndAssembly]);

  useEffect(() => {
    if (online && !userNeedsRefreshed && !syncingOffline.current) {
      const localSession = buildSessionLocaldb();
      const syncOffline = async () => {
        await localSession.syncTransactionsOffline(session, syncingOffline);
      };

      syncOffline().catch(error => {
        logError('TransactionsConfigProvider - Error:', error);
      });
    }
  }, [user?.refresh_token, online]);

  // if session or user changes, store the user and/or session in local storage
  useEffect(() => {
    if (user && session) {
      storeUser(user, session.accessCode, session);
    }
  }, [user, session]);

  const value: TransactionsConfigContextValue = useMemo(
    () => ({
      session,
      sites,
      setSession,
      loading: loadingSession,
      ready: !loadingSession,
      createSession: _createSession,
      createSessionLoading,
      closeSession: _closeSession,
      closeSessionLoading,
      sessionClosed,
      setSessionClosed,
      loadUserSession: _loadUserSession,
      hardLoadingSession,
      updatePaymentDeviceAddress: _updatePaymentDeviceAddress,
      updatePaymentDeviceAddressLoading,
      loadSession,
    }),
    [
      session,
      sites,
      setSession,
      loadingSession,
      createSessionLoading,
      _createSession,
      closeSessionLoading,
      _closeSession,
      hardLoadingSession,
      sessionClosed,
      setSessionClosed,
      _updatePaymentDeviceAddress,
      updatePaymentDeviceAddressLoading,
      loadSession,
    ],
  );

  return (
    <transactionsConfigContext.Provider value={value}>
      {props.children}
    </transactionsConfigContext.Provider>
  );
}

export function useTransactionsConfig(): TransactionsConfigContextValue {
  return useContext(transactionsConfigContext);
}
