import {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {Icon, Icons, IconSize} from './';

interface Props {
  icon: keyof typeof Icons;
  disabled?: boolean;
  offsetX?: number;
  offsetY?: number;
}

const StyledButton = styled.button<Props>`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  color: ${({theme}): string => theme.colors.white};
  background-color: ${({theme}): string => theme.colors.blue};
  border-radius: 50%;
  box-shadow: ${({theme}) => theme.shadows.shadow_16};
  cursor: ${({disabled}): string => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}): string => (disabled ? '0.5' : '1')};
  transition: all 250ms ease;

  position: absolute;
  bottom: ${({offsetY}) => (offsetY ? `${offsetY}px` : '110px')};
  right: ${({offsetX}) => (offsetX ? `${offsetX}px` : '36px')};

  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${({theme, disabled}): string | false =>
      !disabled && theme.colors.indigo};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({theme, disabled}): string | false =>
        !disabled && theme.colors.indigo};
    }
  }
`;

export const ButtonFloating = memo(
  (props: Props & PropsWithoutRef<JSX.IntrinsicElements['button']>) => {
    return (
      <StyledButton {...props}>
        <Icon icon={props.icon} size={IconSize.L} variant="inverted" />
      </StyledButton>
    );
  },
);
