/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignatureImage,
    SignatureImageFromJSON,
    SignatureImageFromJSONTyped,
    SignatureImageToJSON,
    SignatureType,
    SignatureTypeFromJSON,
    SignatureTypeFromJSONTyped,
    SignatureTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionSignature
 */
export interface TransactionSignature {
    /**
     * 
     * @type {string}
     * @memberof TransactionSignature
     */
    transactionSignatureId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSignature
     */
    transactionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSignature
     */
    touchless?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSignature
     */
    signatureImageId?: string | null;
    /**
     * 
     * @type {SignatureImage}
     * @memberof TransactionSignature
     */
    signatureImage?: SignatureImage;
    /**
     * 
     * @type {number}
     * @memberof TransactionSignature
     */
    signatureTypeId: number;
    /**
     * 
     * @type {SignatureType}
     * @memberof TransactionSignature
     */
    signatureType: SignatureType;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSignature
     */
    createdOn: Date;
}

export function TransactionSignatureFromJSON(json: any): TransactionSignature {
    return TransactionSignatureFromJSONTyped(json, false);
}

export function TransactionSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionSignatureId': json['transactionSignatureId'],
        'transactionId': json['transactionId'],
        'touchless': !exists(json, 'touchless') ? undefined : json['touchless'],
        'signatureImageId': !exists(json, 'signatureImageId') ? undefined : json['signatureImageId'],
        'signatureImage': !exists(json, 'signatureImage') ? undefined : SignatureImageFromJSON(json['signatureImage']),
        'signatureTypeId': json['signatureTypeId'],
        'signatureType': SignatureTypeFromJSON(json['signatureType']),
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function TransactionSignatureToJSON(value?: TransactionSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionSignatureId': value.transactionSignatureId,
        'transactionId': value.transactionId,
        'touchless': value.touchless,
        'signatureImageId': value.signatureImageId,
        'signatureImage': SignatureImageToJSON(value.signatureImage),
        'signatureTypeId': value.signatureTypeId,
        'signatureType': SignatureTypeToJSON(value.signatureType),
        'createdOn': (value.createdOn.toISOString()),
    };
}


