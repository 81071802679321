/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerSearchMatch
 */
export interface CustomerSearchMatch {
    /**
     * 
     * @type {number}
     * @memberof CustomerSearchMatch
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    lastName: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerSearchMatch
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    homePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    workPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    mobilePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    customerCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchMatch
     */
    mrn?: string | null;
}

export function CustomerSearchMatchFromJSON(json: any): CustomerSearchMatch {
    return CustomerSearchMatchFromJSONTyped(json, false);
}

export function CustomerSearchMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSearchMatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dob': !exists(json, 'dob') ? undefined : (json['dob'] === null ? null : new Date(json['dob'])),
        'homePhone': !exists(json, 'homePhone') ? undefined : json['homePhone'],
        'workPhone': !exists(json, 'workPhone') ? undefined : json['workPhone'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'customerCode': !exists(json, 'customerCode') ? undefined : json['customerCode'],
        'mrn': !exists(json, 'mrn') ? undefined : json['mrn'],
    };
}

export function CustomerSearchMatchToJSON(value?: CustomerSearchMatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dob': value.dob === undefined ? undefined : (value.dob === null ? null : value.dob.toISOString()),
        'homePhone': value.homePhone,
        'workPhone': value.workPhone,
        'mobilePhone': value.mobilePhone,
        'customerCode': value.customerCode,
        'mrn': value.mrn,
    };
}


