/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReceiptRequest,
    ReceiptRequestFromJSON,
    ReceiptRequestToJSON,
    ReceiptResponseApiResponse,
    ReceiptResponseApiResponseFromJSON,
    ReceiptResponseApiResponseToJSON,
} from '../models';

export interface ClientReceiptCustomerIdTransactionIdSendPostRequest {
    customerId: number;
    transactionId: string;
    receiptRequest?: ReceiptRequest;
}

/**
 * 
 */
export class ReceiptApi extends runtime.BaseAPI {

    /**
     */
    async clientReceiptCustomerIdTransactionIdSendPostRaw(requestParameters: ClientReceiptCustomerIdTransactionIdSendPostRequest): Promise<runtime.ApiResponse<ReceiptResponseApiResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientReceiptCustomerIdTransactionIdSendPost.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientReceiptCustomerIdTransactionIdSendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/receipt/{customerId}/{transactionId}/send`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReceiptRequestToJSON(requestParameters.receiptRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReceiptResponseApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientReceiptCustomerIdTransactionIdSendPost(requestParameters: ClientReceiptCustomerIdTransactionIdSendPostRequest): Promise<ReceiptResponseApiResponse> {
        const response = await this.clientReceiptCustomerIdTransactionIdSendPostRaw(requestParameters);
        return await response.value();
    }

}
