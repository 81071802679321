/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerAccountResponse
 */
export interface CustomerAccountResponse {
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountResponse
     */
    accountNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    accountTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountResponse
     */
    accountType: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    balance: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAccountResponse
     */
    balanceDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAccountResponse
     */
    creditHoldIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    creditLimit: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    currentBalance: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    over90Balance: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    past30Balance: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerAccountResponse
     */
    past60Balance: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAccountResponse
     */
    isPrimary: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAccountResponse
     */
    createDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountResponse
     */
    recordStatus?: string | null;
}

export function CustomerAccountResponseFromJSON(json: any): CustomerAccountResponse {
    return CustomerAccountResponseFromJSONTyped(json, false);
}

export function CustomerAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAccountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': json['accountId'],
        'accountNumber': json['accountNumber'],
        'accountTypeId': json['accountTypeId'],
        'accountType': json['accountType'],
        'balance': json['balance'],
        'balanceDate': !exists(json, 'balanceDate') ? undefined : (json['balanceDate'] === null ? null : new Date(json['balanceDate'])),
        'creditHoldIndicator': json['creditHoldIndicator'],
        'creditLimit': json['creditLimit'],
        'currentBalance': json['currentBalance'],
        'over90Balance': json['over90Balance'],
        'past30Balance': json['past30Balance'],
        'past60Balance': json['past60Balance'],
        'isPrimary': json['isPrimary'],
        'createDate': (new Date(json['createDate'])),
        'recordStatus': !exists(json, 'recordStatus') ? undefined : json['recordStatus'],
    };
}

export function CustomerAccountResponseToJSON(value?: CustomerAccountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountId': value.accountId,
        'accountNumber': value.accountNumber,
        'accountTypeId': value.accountTypeId,
        'accountType': value.accountType,
        'balance': value.balance,
        'balanceDate': value.balanceDate === undefined ? undefined : (value.balanceDate === null ? null : value.balanceDate.toISOString()),
        'creditHoldIndicator': value.creditHoldIndicator,
        'creditLimit': value.creditLimit,
        'currentBalance': value.currentBalance,
        'over90Balance': value.over90Balance,
        'past30Balance': value.past30Balance,
        'past60Balance': value.past60Balance,
        'isPrimary': value.isPrimary,
        'createDate': (value.createDate.toISOString()),
        'recordStatus': value.recordStatus,
    };
}


