import {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {ColorType, Icon, Icons, IconSize, mapColor} from './';

const SMALL = 24;
const DEFAULT = 36;
const LARGE = 80;

export type IndicatorCircleSize = 'small' | 'default' | 'large';

interface StyledProps {
  variant?: ColorType;
  size?: IndicatorCircleSize;
  $loading?: boolean;
}
export interface IndicatorCircleProps {
  icon: keyof typeof Icons;
  variant?: ColorType;
  size?: IndicatorCircleSize;
  loading?: boolean;
}

const circleSize = (size?: string) => {
  switch (size) {
    case 'small':
      return {
        width: `${SMALL}px`,
        minWidth: `${SMALL}px`,
        height: `${SMALL}px`,
      };
    case 'large':
      return {
        width: `${LARGE}px`,
        minWidth: `${LARGE}px`,
        height: `${LARGE}px`,
      };
    default:
      return {
        width: `${DEFAULT}px`,
        minWidth: `${DEFAULT}px`,
        height: `${DEFAULT}px`,
      };
  }
};

const iconSize = (size?: string) => {
  switch (size) {
    case 'small':
      return IconSize.XS;
    case 'large':
      return IconSize.XL;
    default:
      return IconSize.S;
  }
};

const StyledIndicatorCircle = styled.span<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({size}) => ({...circleSize(size)})};
  background-color: ${({theme, variant}) => mapColor(theme, variant)};
  color: ${({theme, variant}) =>
    variant === 'inverted' ? theme.colors.blue : theme.colors.white};

  svg {
    ${({$loading}) =>
      $loading && {
        animationName: 'spin',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'steps(8)',
      }}
  }
`;

export const IndicatorCircle = memo(
  ({
    loading,
    ...props
  }: IndicatorCircleProps & PropsWithoutRef<JSX.IntrinsicElements['span']>) => {
    return (
      <StyledIndicatorCircle {...props} $loading={loading}>
        <Icon
          icon={loading ? 'Spinner' : props.icon}
          size={iconSize(props.size)}
          variant="inverted"
        />
      </StyledIndicatorCircle>
    );
  },
);
