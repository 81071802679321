/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignatureImageRequest,
    SignatureImageRequestFromJSON,
    SignatureImageRequestFromJSONTyped,
    SignatureImageRequestToJSON,
    TransactionExtraRequest,
    TransactionExtraRequestFromJSON,
    TransactionExtraRequestFromJSONTyped,
    TransactionExtraRequestToJSON,
    TransactionIdentificationRequest,
    TransactionIdentificationRequestFromJSON,
    TransactionIdentificationRequestFromJSONTyped,
    TransactionIdentificationRequestToJSON,
    TransactionItemRequest,
    TransactionItemRequestFromJSON,
    TransactionItemRequestFromJSONTyped,
    TransactionItemRequestToJSON,
    TransactionSignatureRequest,
    TransactionSignatureRequestFromJSON,
    TransactionSignatureRequestFromJSONTyped,
    TransactionSignatureRequestToJSON,
    TransactionTaxRequest,
    TransactionTaxRequestFromJSON,
    TransactionTaxRequestFromJSONTyped,
    TransactionTaxRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionSyncRequest
 */
export interface TransactionSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    customerId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSyncRequest
     */
    saleDate: Date;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    subTotal: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    taxableSubTotal: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    totalCost: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    totalSale: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    source?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpRxQty: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpRxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpOtherQty: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpOtherAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpCount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    qhpAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    qhpPartial: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    pseApprovedTransID?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    consumerVerifyID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    deliveryMethods?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    station: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    employeeID: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    taxExemptIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    xFileIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    xFileOriginatingTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    xFileTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    taxExemptNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    orderID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    reference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    sourceID: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    onHoldAtServerIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    saleSiteID: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionSyncRequest
     */
    salesAuditDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    salesAuditIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    dispensingID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    signatureStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    rxSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    sudafedSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    safetyCapsSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    pmsStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    ageSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    age?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    onDeliveryBypassCounselPrompt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSyncRequest
     */
    onDeliveryBypassHIPAAPrompt: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    emailReceipt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    textReceipt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    poNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncRequest
     */
    pseCheckResult?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    dataVersion?: string | null;
    /**
     * 
     * @type {Array<TransactionItemRequest>}
     * @memberof TransactionSyncRequest
     */
    items: Array<TransactionItemRequest>;
    /**
     * 
     * @type {Array<TransactionSignatureRequest>}
     * @memberof TransactionSyncRequest
     */
    signatures: Array<TransactionSignatureRequest>;
    /**
     * 
     * @type {Array<TransactionExtraRequest>}
     * @memberof TransactionSyncRequest
     */
    extras: Array<TransactionExtraRequest>;
    /**
     * 
     * @type {Array<TransactionTaxRequest>}
     * @memberof TransactionSyncRequest
     */
    taxes: Array<TransactionTaxRequest>;
    /**
     * 
     * @type {TransactionIdentificationRequest}
     * @memberof TransactionSyncRequest
     */
    identification?: TransactionIdentificationRequest;
    /**
     * 
     * @type {string}
     * @memberof TransactionSyncRequest
     */
    readonly formattedTextReceipt?: string | null;
    /**
     * 
     * @type {SignatureImageRequest}
     * @memberof TransactionSyncRequest
     */
    signatureImage?: SignatureImageRequest;
}

export function TransactionSyncRequestFromJSON(json: any): TransactionSyncRequest {
    return TransactionSyncRequestFromJSONTyped(json, false);
}

export function TransactionSyncRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSyncRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'sessionId': json['sessionId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'saleDate': (new Date(json['saleDate'])),
        'subTotal': json['subTotal'],
        'taxableSubTotal': json['taxableSubTotal'],
        'totalCost': json['totalCost'],
        'discount': json['discount'],
        'totalTax': json['totalTax'],
        'totalSale': json['totalSale'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'qhpRxQty': json['qhpRxQty'],
        'qhpRxAmount': json['qhpRxAmount'],
        'qhpOtherQty': json['qhpOtherQty'],
        'qhpOtherAmount': json['qhpOtherAmount'],
        'qhpCount': json['qhpCount'],
        'qhpAmount': json['qhpAmount'],
        'qhpPartial': json['qhpPartial'],
        'pseApprovedTransID': !exists(json, 'pseApprovedTransID') ? undefined : json['pseApprovedTransID'],
        'consumerVerifyID': !exists(json, 'consumerVerifyID') ? undefined : json['consumerVerifyID'],
        'deliveryMethods': !exists(json, 'deliveryMethods') ? undefined : json['deliveryMethods'],
        'station': json['station'],
        'employeeID': json['employeeID'],
        'taxExemptIndicator': json['taxExemptIndicator'],
        'xFileIndicator': json['xFileIndicator'],
        'xFileOriginatingTransactionID': !exists(json, 'xFileOriginatingTransactionID') ? undefined : json['xFileOriginatingTransactionID'],
        'xFileTransactionID': !exists(json, 'xFileTransactionID') ? undefined : json['xFileTransactionID'],
        'taxExemptNumber': !exists(json, 'taxExemptNumber') ? undefined : json['taxExemptNumber'],
        'orderID': !exists(json, 'orderID') ? undefined : json['orderID'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'sourceID': json['sourceID'],
        'onHoldAtServerIndicator': json['onHoldAtServerIndicator'],
        'saleSiteID': json['saleSiteID'],
        'salesAuditDate': !exists(json, 'salesAuditDate') ? undefined : (json['salesAuditDate'] === null ? null : new Date(json['salesAuditDate'])),
        'salesAuditIndicator': json['salesAuditIndicator'],
        'dispensingID': !exists(json, 'dispensingID') ? undefined : json['dispensingID'],
        'signatureStatus': !exists(json, 'signatureStatus') ? undefined : json['signatureStatus'],
        'rxSigType': !exists(json, 'rxSigType') ? undefined : json['rxSigType'],
        'sudafedSigType': !exists(json, 'sudafedSigType') ? undefined : json['sudafedSigType'],
        'safetyCapsSigType': !exists(json, 'safetyCapsSigType') ? undefined : json['safetyCapsSigType'],
        'pmsStatus': !exists(json, 'pmsStatus') ? undefined : json['pmsStatus'],
        'ageSource': !exists(json, 'ageSource') ? undefined : json['ageSource'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'onDeliveryBypassCounselPrompt': json['onDeliveryBypassCounselPrompt'],
        'onDeliveryBypassHIPAAPrompt': json['onDeliveryBypassHIPAAPrompt'],
        'emailReceipt': !exists(json, 'emailReceipt') ? undefined : json['emailReceipt'],
        'textReceipt': !exists(json, 'textReceipt') ? undefined : json['textReceipt'],
        'poNumber': !exists(json, 'poNumber') ? undefined : json['poNumber'],
        'pseCheckResult': !exists(json, 'pseCheckResult') ? undefined : json['pseCheckResult'],
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'items': ((json['items'] as Array<any>).map(TransactionItemRequestFromJSON)),
        'signatures': ((json['signatures'] as Array<any>).map(TransactionSignatureRequestFromJSON)),
        'extras': ((json['extras'] as Array<any>).map(TransactionExtraRequestFromJSON)),
        'taxes': ((json['taxes'] as Array<any>).map(TransactionTaxRequestFromJSON)),
        'identification': !exists(json, 'identification') ? undefined : TransactionIdentificationRequestFromJSON(json['identification']),
        'formattedTextReceipt': !exists(json, 'formattedTextReceipt') ? undefined : json['formattedTextReceipt'],
        'signatureImage': !exists(json, 'signatureImage') ? undefined : SignatureImageRequestFromJSON(json['signatureImage']),
    };
}

export function TransactionSyncRequestToJSON(value?: TransactionSyncRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'sessionId': value.sessionId,
        'customerId': value.customerId,
        'saleDate': (value.saleDate.toISOString()),
        'subTotal': value.subTotal,
        'taxableSubTotal': value.taxableSubTotal,
        'totalCost': value.totalCost,
        'discount': value.discount,
        'totalTax': value.totalTax,
        'totalSale': value.totalSale,
        'source': value.source,
        'qhpRxQty': value.qhpRxQty,
        'qhpRxAmount': value.qhpRxAmount,
        'qhpOtherQty': value.qhpOtherQty,
        'qhpOtherAmount': value.qhpOtherAmount,
        'qhpCount': value.qhpCount,
        'qhpAmount': value.qhpAmount,
        'qhpPartial': value.qhpPartial,
        'pseApprovedTransID': value.pseApprovedTransID,
        'consumerVerifyID': value.consumerVerifyID,
        'deliveryMethods': value.deliveryMethods,
        'station': value.station,
        'employeeID': value.employeeID,
        'taxExemptIndicator': value.taxExemptIndicator,
        'xFileIndicator': value.xFileIndicator,
        'xFileOriginatingTransactionID': value.xFileOriginatingTransactionID,
        'xFileTransactionID': value.xFileTransactionID,
        'taxExemptNumber': value.taxExemptNumber,
        'orderID': value.orderID,
        'reference': value.reference,
        'sourceID': value.sourceID,
        'onHoldAtServerIndicator': value.onHoldAtServerIndicator,
        'saleSiteID': value.saleSiteID,
        'salesAuditDate': value.salesAuditDate === undefined ? undefined : (value.salesAuditDate === null ? null : value.salesAuditDate.toISOString()),
        'salesAuditIndicator': value.salesAuditIndicator,
        'dispensingID': value.dispensingID,
        'signatureStatus': value.signatureStatus,
        'rxSigType': value.rxSigType,
        'sudafedSigType': value.sudafedSigType,
        'safetyCapsSigType': value.safetyCapsSigType,
        'pmsStatus': value.pmsStatus,
        'ageSource': value.ageSource,
        'age': value.age,
        'onDeliveryBypassCounselPrompt': value.onDeliveryBypassCounselPrompt,
        'onDeliveryBypassHIPAAPrompt': value.onDeliveryBypassHIPAAPrompt,
        'emailReceipt': value.emailReceipt,
        'textReceipt': value.textReceipt,
        'poNumber': value.poNumber,
        'pseCheckResult': value.pseCheckResult,
        'roomNumber': value.roomNumber,
        'notes': value.notes,
        'dataVersion': value.dataVersion,
        'items': ((value.items as Array<any>).map(TransactionItemRequestToJSON)),
        'signatures': ((value.signatures as Array<any>).map(TransactionSignatureRequestToJSON)),
        'extras': ((value.extras as Array<any>).map(TransactionExtraRequestToJSON)),
        'taxes': ((value.taxes as Array<any>).map(TransactionTaxRequestToJSON)),
        'identification': TransactionIdentificationRequestToJSON(value.identification),
        'signatureImage': SignatureImageRequestToJSON(value.signatureImage),
    };
}


