/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SmsStatusEnum {
    Queued = 'Queued',
    Sending = 'Sending',
    Sent = 'Sent',
    Failed = 'Failed',
    Delivered = 'Delivered',
    Undelivered = 'Undelivered',
    Receiving = 'Receiving',
    Received = 'Received',
    Accepted = 'Accepted',
    Scheduled = 'Scheduled',
    Read = 'Read',
    PartiallyDelivered = 'PartiallyDelivered',
    Canceled = 'Canceled'
}

export function SmsStatusEnumFromJSON(json: any): SmsStatusEnum {
    return SmsStatusEnumFromJSONTyped(json, false);
}

export function SmsStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsStatusEnum {
    return json as SmsStatusEnum;
}

export function SmsStatusEnumToJSON(value?: SmsStatusEnum | null): any {
    return value as any;
}

