/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PseItem
 */
export interface PseItem {
    /**
     * 
     * @type {number}
     * @memberof PseItem
     */
    boxes: number;
    /**
     * 
     * @type {string}
     * @memberof PseItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseItem
     */
    dosagesPerBox?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PseItem
     */
    milligramsPerBox: number;
    /**
     * 
     * @type {string}
     * @memberof PseItem
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseItem
     */
    pillsPerBox?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseItem
     */
    upc?: string | null;
}

export function PseItemFromJSON(json: any): PseItem {
    return PseItemFromJSONTyped(json, false);
}

export function PseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boxes': json['boxes'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dosagesPerBox': !exists(json, 'dosagesPerBox') ? undefined : json['dosagesPerBox'],
        'milligramsPerBox': json['milligramsPerBox'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'pillsPerBox': !exists(json, 'pillsPerBox') ? undefined : json['pillsPerBox'],
        'upc': !exists(json, 'upc') ? undefined : json['upc'],
    };
}

export function PseItemToJSON(value?: PseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boxes': value.boxes,
        'description': value.description,
        'dosagesPerBox': value.dosagesPerBox,
        'milligramsPerBox': value.milligramsPerBox,
        'number': value.number,
        'pillsPerBox': value.pillsPerBox,
        'upc': value.upc,
    };
}


