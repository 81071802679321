/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EligibilitySearchMatchListApiResponse,
    EligibilitySearchMatchListApiResponseFromJSON,
    EligibilitySearchMatchListApiResponseToJSON,
} from '../models';

export interface ClientEligibilityGetRequest {
    includesChildren?: boolean;
    includesDeleted?: boolean;
    sort?: Array<string>;
    page?: number;
    pageSize?: number;
    q?: string;
}

/**
 * 
 */
export class EligibilityApi extends runtime.BaseAPI {

    /**
     * Gets Eligibility.
     */
    async clientEligibilityGetRaw(requestParameters: ClientEligibilityGetRequest): Promise<runtime.ApiResponse<EligibilitySearchMatchListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.includesChildren !== undefined) {
            queryParameters['IncludesChildren'] = requestParameters.includesChildren;
        }

        if (requestParameters.includesDeleted !== undefined) {
            queryParameters['IncludesDeleted'] = requestParameters.includesDeleted;
        }

        if (requestParameters.sort) {
            queryParameters['Sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/eligibility`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EligibilitySearchMatchListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Eligibility.
     */
    async clientEligibilityGet(requestParameters: ClientEligibilityGetRequest): Promise<EligibilitySearchMatchListApiResponse> {
        const response = await this.clientEligibilityGetRaw(requestParameters);
        return await response.value();
    }

}
