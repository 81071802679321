/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    WillCallResponseBody,
    WillCallResponseBodyFromJSON,
    WillCallResponseBodyFromJSONTyped,
    WillCallResponseBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface WillCallResponseBodyApiResponse
 */
export interface WillCallResponseBodyApiResponse {
    /**
     * 
     * @type {string}
     * @memberof WillCallResponseBodyApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof WillCallResponseBodyApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {WillCallResponseBody}
     * @memberof WillCallResponseBodyApiResponse
     */
    data?: WillCallResponseBody;
}

export function WillCallResponseBodyApiResponseFromJSON(json: any): WillCallResponseBodyApiResponse {
    return WillCallResponseBodyApiResponseFromJSONTyped(json, false);
}

export function WillCallResponseBodyApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WillCallResponseBodyApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : WillCallResponseBodyFromJSON(json['data']),
    };
}

export function WillCallResponseBodyApiResponseToJSON(value?: WillCallResponseBodyApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': WillCallResponseBodyToJSON(value.data),
    };
}


