import {Setting} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  ScrollContainer,
  SegmentSlider,
  Select,
  Stack,
  Text,
  TextInput,
  TextVariant as Variant,
  Illustration,
  Variant as BV,
} from '@emporos/components';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {memo, useMemo, useState} from 'react';
import {Controller, ControllerRenderProps, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {
  Transaction,
  isPatientPayTransaction,
  round,
  useBetaFeatures,
  useTotals,
  PatientPayExtras,
} from '../../../../../';
import {EmailForm, SmsForm} from './';
import {useConsoleLogger} from '../../../../../contexts/ConsoleLoggingProvider';
import {mapComplianceIndicators} from '../';

interface Props {
  transaction: Transaction;
  settings: Setting[];
  navigate: NavigateFn;
  onPrint(): void;
  onNoReceipt(): void;
  onEmailReceipt(data: EmailForm): void;
  onSmsReceipt(data: SmsForm): void;
  onBack(onBackLocation: string): void;
}

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .max(50)
    .matches(new RegExp('^[\\w-.]+@([\\w-]+\\.)+[\\w-]{2,50}$'))
    .required(),
});
const smsSchema = yup.object().shape({
  sms: yup
    .string()
    .length(14)
    .matches(new RegExp('^\\((\\d{3})\\)\\ (\\d{3})\\-(\\d{4})'))
    .required(),
});

const ReceiptsChild = ({
  transaction,
  settings,
  onNoReceipt,
  onPrint,
  onEmailReceipt,
  onSmsReceipt,
  onBack,
}: Props): JSX.Element => {
  const printingEnabled = useBetaFeatures().printing;
  const isPtPayTransaction = isPatientPayTransaction(transaction);

  const getPatientPayFormatedPhoneNumber = (): string => {
    const patientPayExtrasString = transaction.extras.find(
      extra => extra.dataKey === 'PatientPay',
    )?.dataValue;

    const patientPayExtras: PatientPayExtras = JSON.parse(
      patientPayExtrasString || '{}',
    );
    const matches = patientPayExtras.lastUsedPhoneNumber?.match(
      /^(\d{1,3})(\d{0,3})(\d{0,4})$/,
    );

    return matches
      ? `(${matches[1]}) ${matches[2]}${matches[3] ? '-' + matches[3] : ''}`
      : '';
  };

  const hasReturnedPayment = transaction.payments.some(
    p => p.recordStatus === 'Voided',
  );

  const availableItems = useMemo(() => {
    const textEnabled =
      settings.find(s => s.name === 'TEXT_RECEIPT_ENABLED')?.value === '1' ??
      false;

    const availableItems = ['Email'];

    if (textEnabled && isPtPayTransaction && !hasReturnedPayment)
      availableItems.push('Text');
    if (printingEnabled) availableItems.push('Print');

    availableItems.push('None');
    return availableItems;
  }, [settings, printingEnabled, isPtPayTransaction, hasReturnedPayment]);

  const EMAIL_INDEX = availableItems.indexOf('Email');
  const TEXT_INDEX = availableItems.indexOf('Text');
  const PRINT_INDEX = availableItems.indexOf('Print');

  const [active, setActive] = useState(EMAIL_INDEX);
  const emailForm = useForm<EmailForm>({
    mode: 'onChange',
    defaultValues: {email: transaction.customer?.email ?? ''},
    resolver: yupResolver(emailSchema),
  });

  const smsForm = useForm<SmsForm>({
    mode: 'onChange',
    defaultValues: {sms: getPatientPayFormatedPhoneNumber()},
    resolver: yupResolver(smsSchema),
  });

  const {serverTransactionID, saleDate} = transaction;

  const {logError} = useConsoleLogger();

  const {totals} = useTotals();

  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const showCompliancePage = showCounsel || showHipaa || showRelationship;
  const showPaymentsPage = totals.totalDue != 0;

  const isInBalance = () => {
    const sumAmount = transaction.payments
      .filter(p => p.recordStatus === 'Active')
      .reduce((preValue, currValue) => {
        preValue += currValue.amount - (currValue.amountReturned ?? 0);
        return preValue;
      }, 0);

    return round(sumAmount) == totals.transactionTotal;
  };

  const getOnBackLocation = () => {
    return isPtPayTransaction
      ? showCompliancePage
        ? '../compliance'
        : showPaymentsPage
        ? '../customer-payment'
        : '../../'
      : '../customer-payment';
  };

  const PrintReceipt = () => {
    return (
      <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="Receipt" />
            <Select
              label="Printer"
              options={['Front Desk']}
              value={null}
              onChangeValue={() => logError('Receipt - Printer Unimplemented')}
              style={{width: 372, flex: 0}}
            />
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button
            disabled={!isInBalance()}
            onClick={onPrint}
            variant={BV.Secondary}
            flex
          >
            Print Receipt
          </Button>
        </FooterGroup>
      </Stack>
    );
  };

  const NoReceipt = () => {
    return (
      <Stack
        style={{height: '100%', width: '100%', minHeight: 0}}
        data-testid="no-receipt"
      >
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="TaskNotDone" />
            <Text variant={Variant.T3}>No receipt</Text>
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button
            data-testid="none-back"
            type="button"
            onClick={() => onBack(getOnBackLocation())}
            variant={BV.Secondary}
            flex
          >
            Back
          </Button>
          <Button
            type="button"
            disabled={!isInBalance()}
            onClick={onNoReceipt}
            flex
          >
            Complete Transaction
          </Button>
        </FooterGroup>
      </Stack>
    );
  };

  const onPhoneNumberChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<SmsForm, 'sms'>,
  ) => {
    if (
      ev.nativeEvent instanceof InputEvent &&
      (ev.nativeEvent.inputType === 'deleteContentBackward' ||
        ev.nativeEvent.inputType === 'deleteContentForward')
    ) {
      field.onChange(ev.target.value);
      return;
    }

    const val = ev.target.value.replace(/\D/g, '');
    const matches = val.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

    const formattedPhoneNumber = matches
      ? `(${matches[1]}) ${matches[2]}${matches[3] ? '-' + matches[3] : ''}`
      : '';

    field.onChange(formattedPhoneNumber);
  };

  return (
    <>
      <Stack gutter={Gutter.L} align="center" style={{flex: 1}}>
        <Header
          title="Receipt Delivery"
          style={{alignSelf: 'stretch'}}
        ></Header>

        <SegmentSlider
          onSelectIndex={setActive}
          active={active}
          items={availableItems}
        />

        {active === EMAIL_INDEX && (
          <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
            <ScrollContainer>
              <Stack
                align="center"
                gutter={Gutter.None}
                style={{flex: 1, marginTop: 36}}
              >
                {serverTransactionID && (
                  <Stack justify="center" align="center" gutter={Gutter.XS}>
                    <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                    <Text variant={Variant.Main}>
                      {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                    </Text>
                  </Stack>
                )}
                <Illustration illustration="NoEmails" />
                <Controller
                  name="email"
                  control={emailForm.control}
                  defaultValue=""
                  render={({field}) => (
                    <TextInput
                      onChange={field.onChange}
                      value={field.value}
                      autoComplete="off"
                      label="Email"
                      style={{width: 422}}
                    />
                  )}
                />
              </Stack>
            </ScrollContainer>

            <FooterGroup>
              <Button
                data-testid="email-back"
                type="button"
                onClick={() => onBack(getOnBackLocation())}
                variant={BV.Secondary}
                flex
              >
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  onEmailReceipt(emailForm.getValues());
                }}
                disabled={!emailForm.formState.isValid || !isInBalance()}
                flex
              >
                Complete Transaction
              </Button>
            </FooterGroup>
          </Stack>
        )}
        {active === TEXT_INDEX && (
          <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
            <ScrollContainer>
              <Stack
                align="center"
                gutter={Gutter.None}
                style={{flex: 1, marginTop: 36}}
              >
                {serverTransactionID && (
                  <Stack justify="center" align="center" gutter={Gutter.XS}>
                    <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                    <Text variant={Variant.Main}>
                      {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                    </Text>
                  </Stack>
                )}
                <Illustration illustration="NoEmails" />
                <Controller
                  name="sms"
                  control={smsForm.control}
                  render={({field}) => (
                    <TextInput
                      onChange={e => onPhoneNumberChange(e, field)}
                      value={field.value}
                      autoComplete="off"
                      type="tel"
                      label="Mobile number (555) 555-5555"
                      maxLength={14}
                      style={{width: 422}}
                    />
                  )}
                />
              </Stack>
            </ScrollContainer>

            <FooterGroup>
              <Button
                data-testid="sms-back"
                type="button"
                onClick={() => onBack(getOnBackLocation())}
                variant={BV.Secondary}
                flex
              >
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  onSmsReceipt(smsForm.getValues());
                }}
                disabled={!smsForm.formState.isValid || !isInBalance()}
                flex
              >
                Complete Transaction
              </Button>
            </FooterGroup>
          </Stack>
        )}
        {active === PRINT_INDEX && <PrintReceipt />}
        {active !== PRINT_INDEX &&
          active !== EMAIL_INDEX &&
          active !== TEXT_INDEX && <NoReceipt />}
      </Stack>
    </>
  );
};

export const Receipts = memo(ReceiptsChild);
