import {
  TransactionsApi,
  Transaction,
  TransactionPayment,
  SignatureImage,
  Configuration,
  HTTPHeaders,
  TransactionStatusEnum,
  CustomerAccountsApi,
  SignatureImagesApi,
  SignatureImageRequest,
  CustomersCustomerIdAccountsArPostRequest,
  PaymentType,
  TransactionPaymentStatusEnum,
  Session,
} from '@emporos/api-enterprise';
import {TransactionConsolidate} from '@emporos/pos/src';
import {TransactionPaymentConsolidate} from '../api';
import {
  ITransactionDatabaseAccess,
  ITransactionPaymentsDatabaseAccess,
} from '../localDb/IDatabaseAccess';
import {TransactionPaymentLocaldb} from '../localDb/TransactionPaymentLocaldb';
import {TransactionLocaldb} from '../localDb/TransactionsLocaldb';
import {ApiVersion} from './ApiVersion';
import {ITransactionService} from './ITransactionService';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
import {EncryptedTransactionsApi} from '../api/EncryptedTransactionsApi';
import {EncryptedTransactionPaymentsApi} from '../api/EncryptedTransactionPaymentsApi';
import {
  EncryptedTransaction,
  TransactionDbPackage,
  encryptionMiddlewareFunction,
  generateKey,
} from '../utils/crypto';
import _ from 'lodash';
import {EncryptedNonSessionSyncApi} from '../api/EncryptedNonSessionSyncApi';

const {CLIENT_API_URL, PAYMENT_DOMAIN_ENABLED} = process.env;

export type NonSessionSyncResult = {
  sessionId: string;
  transaction: EncryptedTransaction;
  needsDeletedLocally: boolean;
  needsCompleted: boolean;
  userSub: string;
};

// defined as an object in case we ever need to return anything else from syncTransactions
export type SyncResult = {
  deletedTransactionPayments: string[];
  updatedTransactionPayments: TransactionPayment[];
};

// instantiate with DIFactory.getTransactionService to use in base react classes (uses a hook)
export class TransactionService implements ITransactionService {
  private _sessionId!: string;
  private _accessCode!: string;
  private _key!: string;
  private _userSub!: string;
  private _token!: string;
  private _version: ApiVersion = '1.5';
  private _headers!: HTTPHeaders;
  private _client!: Configuration;
  private _customerApi!: CustomerAccountsApi;
  private _transactionApi!: TransactionsApi;
  private _signaturaApi!: SignatureImagesApi;
  private _encryptedTransactionsApi!: EncryptedTransactionsApi;
  private _encryptedTransactionPaymentsApi!: EncryptedTransactionPaymentsApi;
  private _encryptedSyncApi!: EncryptedNonSessionSyncApi;
  private _transactionDb!: ITransactionDatabaseAccess;
  private _transactionPaymentDb!: ITransactionPaymentsDatabaseAccess;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();
  private _paymentTenders: PaymentType[] = [];

  initialize = (
    sessionId: string,
    token: string,
    accessCode: string,
    userSub: string,
    paymentTenders?: PaymentType[],
  ): void => {
    this._sessionId = sessionId;
    this._token = token;
    this._accessCode = accessCode;
    this._userSub = userSub;
    this._headers = {
      'Content-Type': `application/json;v=${this._version}`,
      Authorization: `Bearer ${this._token}`,
    };
    this._client = new Configuration({
      basePath: CLIENT_API_URL,
      headers: this._headers,
    });
    this._customerApi = new CustomerAccountsApi(this._client);
    this._transactionApi = new TransactionsApi(this._client);
    this._signaturaApi = new SignatureImagesApi(this._client);
    this._key = generateKey(this._accessCode, this._sessionId);
    this._encryptedTransactionsApi = new EncryptedTransactionsApi(
      this._client,
      this._key,
    );
    this._encryptedTransactionPaymentsApi = new EncryptedTransactionPaymentsApi(
      this._client,
      this._key,
    );
    this._encryptedSyncApi = new EncryptedNonSessionSyncApi(
      this._client,
      this._key,
    );
    const transactiondb = new TransactionLocaldb();
    transactiondb.initialize(this._sessionId, this._accessCode);
    this._transactionDb = transactiondb;
    const transactionPaymentDb = new TransactionPaymentLocaldb();
    transactionPaymentDb.initialize(this._sessionId, this._accessCode);
    this._transactionPaymentDb = transactionPaymentDb;
    this._paymentTenders = paymentTenders || [];
  };

  resetToken = (token: string): void => {
    this._token = token;
    this._headers = {
      'Content-Type': `application/json;v=${this._version}`,
      Authorization: `Bearer ${this._token}`,
    };
    this._client = new Configuration({
      basePath: CLIENT_API_URL,
      headers: this._headers,
    });
    this._customerApi = new CustomerAccountsApi(this._client);
    this._transactionApi = new TransactionsApi(this._client);
    this._signaturaApi = new SignatureImagesApi(this._client);
    this._encryptedTransactionsApi = new EncryptedTransactionsApi(
      this._client,
      this._key,
    );
    this._encryptedTransactionPaymentsApi = new EncryptedTransactionPaymentsApi(
      this._client,
      this._key,
    );
    this._encryptedSyncApi = new EncryptedNonSessionSyncApi(
      this._client,
      this._key,
    );
  };

  getPaymentTenderDescriptor = (payment: TransactionPayment): string => {
    const tender = this._paymentTenders.find(
      tender => tender.id == payment.paymentTypeID,
    );
    return tender?.name || '';
  };

  syncTransactions = async (
    _currentTransactionId: string,
  ): Promise<SyncResult> => {
    const syncResult = {
      deletedTransactionPayments: [],
      updatedTransactionPayments: [],
    } as SyncResult;
    const pkg = await this._transactionDb.getDbPackage(_currentTransactionId);
    if (_.isEmpty(pkg)) {
      return syncResult;
    }
    this._consoleLogger.styledLog(
      'TransactionService - Online Sync Start:',
      ConsoleLoggerVariant.PURPLE,
      pkg.transaction,
    );

    switch (pkg.transaction.status) {
      case TransactionStatusEnum.Active: {
        if (pkg.transaction.serverTransactionID === 0) {
          //Action new transaction
          await this.createTransaction(pkg);
          return syncResult;
        }
        if (
          pkg.transaction.serverTransactionID > 0 &&
          pkg.transaction.isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(
            this._sessionId,
            (pkg.transaction as Transaction).transactionId,
          );
          return syncResult;
        }
        if (
          pkg.transaction.serverTransactionID > 0 &&
          !pkg.transaction.isDeleted
        ) {
          if (
            pkg.transaction.serverTransactionID > 0 &&
            pkg.transaction.payments.length > 0
          ) {
            for (let j = 0; j < pkg.transaction.payments.length; j++) {
              const dbPayment = await this._transactionPaymentDb.get(
                pkg.transaction.payments[j].transactionPaymentId,
              );
              // if payment domain is enabled, we don't want to sync payments
              //check payments
              this._consoleLogger.log(
                'PAYMENT_DOMAIN_ENABLED',
                PAYMENT_DOMAIN_ENABLED,
              );
              if (
                PAYMENT_DOMAIN_ENABLED == 'true' &&
                pkg.transaction.payments[j].gatewayPaymentID &&
                pkg.transaction.payments[j].gatewayPaymentID != ''
              ) {
                //skip payment sync if payment domain is enabled and is credit card payment
                this._consoleLogger.log(
                  'PAYMENT obj',
                  pkg.transaction.payments[j],
                );
                continue;
              } else {
                if (
                  (pkg.transaction.payments[j].transactionPaymentStatus ==
                    null ||
                    pkg.transaction.payments[j].transactionPaymentStatus ==
                      undefined) &&
                  (dbPayment?.transactionPaymentStatus == null ||
                    dbPayment.transactionPaymentStatus == undefined)
                ) {
                  //Action new payment
                  const paymentResponse = await this.postTransactionPayment(
                    pkg.transaction.payments[
                      j
                    ] as TransactionPaymentConsolidate,
                    pkg.transaction.saleSiteID,
                    pkg.transaction.station,
                    pkg.secretByteArray,
                  );
                  pkg.transaction.payments[j] = paymentResponse;
                } else {
                  //Action update payment
                  if (
                    (
                      pkg.transaction.payments[
                        j
                      ] as TransactionPaymentConsolidate
                    ).isDeleted == true &&
                    dbPayment
                  ) {
                    if (
                      !dbPayment.isDeleted &&
                      !pkg.transaction.payments[j].gatewayPaymentID
                    ) {
                      pkg.transaction.payments[j] =
                        await this.deleteTransactionPayment(
                          pkg.transaction.payments[j],
                        );
                      (
                        pkg.transaction.payments[
                          j
                        ] as TransactionPaymentConsolidate
                      ).isDeleted = true;
                      (
                        pkg.transaction.payments[
                          j
                        ] as TransactionPaymentConsolidate
                      ).isSynced = true;

                      // we track deleted payments to ensure they are removed from transactionPaymentdb, transactiondb AND the in-memory Session object
                      syncResult.deletedTransactionPayments.push(
                        pkg.transaction.payments[j].transactionPaymentId,
                      );
                    }
                  }
                  //update payment from pending to active in recordStatus\
                  if (
                    (dbPayment.recordStatus == undefined ||
                      dbPayment.recordStatus == 'Pending') &&
                    pkg.transaction.payments[j].recordStatus == 'Active'
                  ) {
                    //update paymentStatus to approved when this condition is met
                    pkg.transaction.payments[j].transactionPaymentStatus =
                      TransactionPaymentStatusEnum.Approved;
                    const paymentresponse = await this.putTransactionPayment(
                      pkg.transaction.payments[
                        j
                      ] as TransactionPaymentConsolidate,
                      pkg.transaction.saleSiteID,
                      pkg.transaction.station,
                      pkg.secretByteArray,
                    );
                    pkg.transaction.payments[j] = paymentresponse;
                    syncResult.updatedTransactionPayments.push(paymentresponse);
                  }
                }
                await this._transactionPaymentDb.update(
                  pkg.transaction.payments[j] as TransactionPaymentConsolidate,
                );
              }
            }
            await this._transactionDb.update(pkg.transaction);
          }
        }

        if (
          pkg.transaction.signatureImage?.signatureImageId &&
          !pkg.transaction.isCompleted
        ) {
          //Action Signature
          try {
            await this.putSignatureImage(
              pkg.transaction.signatureImage,
              pkg.transaction,
            );
          } catch (error) {
            this._consoleLogger.logError(
              'TransactionService - Error Putting Signature Image:',
              error,
            );
          }
        }

        if (pkg.transaction.isCompleted) {
          //Action complete Transaction
          /**
           * before we complete the transaction with PATCH, call PUT to ensure
           * that any updates (like adding an emailReceipt value) get to the
           * database before the transaction is completed.
           */
          await this.putTransaction(pkg).then(async newTransaction => {
            return await this.patchTransaction(this._sessionId, newTransaction);
          });
          return syncResult;
        } else {
          //Action Update Transaction
          await this.putTransaction(pkg);
          return syncResult;
        }
      }
      case TransactionStatusEnum.Complete:
        return syncResult;
      case TransactionStatusEnum.Error:
        alert(
          'Transaction ' +
            pkg.transaction.transactionId.toString() +
            ' has an error. Please contact support to verify its status.',
        );
      case TransactionStatusEnum.Accepted:
        pkg.transaction.isCompleted = true;
        pkg.transaction.status = TransactionStatusEnum.Complete;
        await this._transactionDb.update(pkg.transaction);
        await this.patchTransaction(this._sessionId, pkg.transaction);
        return syncResult;

      case TransactionStatusEnum.Deleted:
        if (
          pkg.transaction.serverTransactionID > 0 &&
          pkg.transaction.isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(
            this._sessionId,
            (pkg.transaction as Transaction).transactionId,
          );
          return syncResult;
        }
      case TransactionStatusEnum.PatientPay:
        return syncResult;
      default:
        throw new Error('Invalid transaction status');
    }
  };

  createTransaction = async (
    pkg: TransactionDbPackage,
  ): Promise<Transaction> => {
    const response =
      await this._encryptedTransactionsApi.InsertTransactionEncrypted(
        this._sessionId,
        pkg.cipherTextByteArray,
      );

    (response as TransactionConsolidate).isSynced = true;
    (response as TransactionConsolidate).isDeleted = false;
    (response as TransactionConsolidate).isCompleted = false;
    (response as TransactionConsolidate).userSub = this._userSub;

    await this._transactionDb.update(response as TransactionConsolidate);

    return response;
  };

  putTransaction = async (pkg: TransactionDbPackage): Promise<Transaction> => {
    if (
      pkg.transaction.payments.filter(
        x => (x as TransactionPaymentConsolidate).isDeleted,
      ).length !== 0
    ) {
      pkg.transaction.payments
        .filter(
          x =>
            (x as TransactionPaymentConsolidate).isDeleted &&
            !(x as TransactionPaymentConsolidate).isSynced,
        )
        .forEach(async x => {
          await this.deleteTransactionPayment(
            x as TransactionPaymentConsolidate,
          );
        });

      pkg.transaction.payments
        .filter(x => (x as TransactionPaymentConsolidate).isDeleted)
        .forEach(async x => {
          await this._transactionPaymentDb.delete(x.transactionPaymentId);
        });
      pkg.transaction.payments = pkg.transaction.payments.filter(
        x => !(x as TransactionPaymentConsolidate).isDeleted,
      );
      await this._transactionDb.update(pkg.transaction);
      pkg.cipherTextByteArray = await encryptionMiddlewareFunction(
        pkg.secretByteArray,
        pkg.transaction,
      );
    }

    const response =
      await this._encryptedTransactionsApi.UpdateTransactionEncrypted(
        this._sessionId,
        pkg.cipherTextByteArray,
      );

    (response as TransactionConsolidate).isSynced = pkg.transaction.isSynced;
    (response as TransactionConsolidate).isDeleted = pkg.transaction.isDeleted;
    (response as TransactionConsolidate).isCompleted =
      pkg.transaction.isCompleted;

    await this._transactionDb.update(response as TransactionConsolidate);

    //Todo update back database and UI
    return response;
  };

  putTransactionEncrypted = async (
    transaction: EncryptedTransaction,
  ): Promise<boolean> => {
    if (!transaction.cipherTextByteArray) return false;
    const response =
      await this._encryptedTransactionsApi.UpdateTransactionEncrypted(
        transaction.sessionId,
        transaction.cipherTextByteArray,
      );
    if (response) return true;
    else return false;
  };

  patchTransaction = async (
    sessionId: string,
    transaction: TransactionConsolidate,
    updateLocalDb = true,
  ): Promise<Transaction> => {
    const response =
      await this._encryptedTransactionsApi.CompletePatchTransactionEncrypted(
        sessionId,
        transaction.transactionId,
      );

    (response as TransactionConsolidate).isSynced = transaction.isSynced;
    (response as TransactionConsolidate).isDeleted = transaction.isDeleted;
    (response as TransactionConsolidate).isCompleted = transaction.isCompleted;
    (response as TransactionConsolidate).userSub = this._userSub;

    if (updateLocalDb)
      await this._transactionDb.update(response as TransactionConsolidate);

    //Todo update back database and UI
    return response;
  };

  postTransaction = async (transaction: Transaction): Promise<Transaction> => {
    return transaction;
  };

  putTransactionPayment = async (
    payment: TransactionPaymentConsolidate,
    siteId: number,
    stationId: number,
    secretByteArray: Uint8Array,
  ): Promise<TransactionPayment> => {
    const response =
      await this._encryptedTransactionPaymentsApi.UpdateTransactionPaymentEncrypted(
        this._sessionId,
        payment,
        secretByteArray,
      );

    (response as TransactionPaymentConsolidate).isSynced = true;
    (response as TransactionPaymentConsolidate).isDeleted = false;
    (response as TransactionPaymentConsolidate).siteId = siteId;
    (response as TransactionPaymentConsolidate).stationId = stationId;

    await this._transactionPaymentDb.update(
      response as TransactionPaymentConsolidate,
    );

    return response;
  };

  postTransactionPayment = async (
    payment: TransactionPaymentConsolidate,
    siteId: number,
    stationId: number,
    secretByteArray: Uint8Array,
  ): Promise<TransactionPayment> => {
    const response =
      await this._encryptedTransactionPaymentsApi.InsertTransactionPaymentEncrypted(
        this._sessionId,
        payment,
        secretByteArray,
      );

    (response as TransactionPaymentConsolidate).isSynced = true;
    (response as TransactionPaymentConsolidate).isDeleted = false;
    (response as TransactionPaymentConsolidate).siteId = siteId;
    (response as TransactionPaymentConsolidate).stationId = stationId;

    await this._transactionPaymentDb.add(
      response as TransactionPaymentConsolidate,
    );

    return response;
  };

  postTransactionPaymentOffline = async (
    payment: TransactionPaymentConsolidate,
    secretByteArray: Uint8Array,
    accountRequest?: CustomersCustomerIdAccountsArPostRequest,
  ): Promise<TransactionPayment> => {
    // TODO - we may be able to follow same logic here for PD account by checking for that paymentTypeID - if we get either, we need to make an api call to generate new account

    if (
      this.getPaymentTenderDescriptor(payment) === 'AR' &&
      payment.paymentNumber === '0' &&
      accountRequest
    ) {
      const response =
        await this._customerApi.customersCustomerIdAccountsArPost(
          accountRequest,
        );
      payment.paymentNumber = response.data?.accountNumber;
    }
    const response =
      await this._encryptedTransactionPaymentsApi.InsertTransactionPaymentEncrypted(
        this._sessionId,
        payment,
        secretByteArray,
      );

    (response as TransactionPaymentConsolidate).isSynced = true;
    (response as TransactionPaymentConsolidate).isDeleted = false;

    await this._transactionPaymentDb.update(
      response as TransactionPaymentConsolidate,
    );

    return response;
  };

  syncTransactionPaymentsOffline = async (
    transaction: EncryptedTransaction,
  ): Promise<boolean> => {
    let successful = true;
    const payments = await this._transactionPaymentDb.getPaymentsForTransaction(
      transaction.transactionId,
    );
    for (let j = 0; j < payments.length; j++) {
      if (payments[j].isDeleted && !payments[j].isSynced) {
        await this.deleteTransactionPayment(payments[j]);
      } else if (!payments[j].isSynced) {
        successful =
          successful &&
          (await this._encryptedSyncApi.SyncNonUserTransactionPayment(
            payments[j],
            transaction.sessionId,
            transaction.transactionId,
          ));
      }
    }
    return successful;
  };

  putSignatureImage = async (
    signatureImage: SignatureImage,
    transaction: TransactionConsolidate,
  ): Promise<SignatureImage> => {
    const response = await this._signaturaApi.clientCacheSignaturesImagesPut({
      signatureImageRequest: signatureImage as SignatureImageRequest,
    });

    transaction.signatureImage = response;

    await this._transactionDb.update(transaction);

    return response;
  };

  getTransactions = async (): Promise<Transaction[]> =>
    (await this._transactionDb.getAll()).filter(
      n => n.sessionId == this._sessionId,
    );

  deleteTransaction = async (
    sessionId: string,
    transactionId: string,
  ): Promise<void> => {
    await this._transactionApi.clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch(
      {
        sessionId: sessionId,
        transactionId: transactionId,
      },
    );

    await this._transactionDb.delete(transactionId);
  };

  deleteTransactionPayment = async (
    transactionPayment: TransactionPayment,
  ): Promise<TransactionPayment> => {
    const response =
      await this._encryptedTransactionPaymentsApi.DeleteTransactionPayment(
        this._sessionId,
        transactionPayment.transactionId,
        transactionPayment.transactionPaymentId,
      );

    return response;
  };

  syncTransactionsOffline = async (
    _currentTransactionId: string,
  ): Promise<Transaction | null> => {
    let pkg = await this._transactionDb.getDbPackage(_currentTransactionId);
    if (_.isEmpty(pkg)) {
      return null;
    }
    this._consoleLogger.styledLog(
      'TransactionService - Offline Sync Start:',
      ConsoleLoggerVariant.PURPLE,
      pkg.transaction,
    );

    pkg.transaction.isSynced = true;

    switch (pkg.transaction.status) {
      case TransactionStatusEnum.Active: {
        if (
          pkg.transaction.serverTransactionID > 0 &&
          pkg.transaction.isDeleted
        ) {
          await this.deleteTransaction(
            this._sessionId,
            (pkg.transaction as Transaction).transactionId,
          );
          return pkg.transaction;
        }
        if (
          pkg.transaction.serverTransactionID > 0 &&
          !pkg.transaction.isDeleted
        ) {
          if (
            pkg.transaction.serverTransactionID > 0 &&
            pkg.transaction.payments.length > 0
          ) {
            //check payments
            for (let txPayment of pkg.transaction.payments) {
              const dbPayment = await this._transactionPaymentDb.get(
                txPayment.transactionPaymentId,
              );
              if (
                (txPayment.transactionPaymentStatus == null ||
                  txPayment.transactionPaymentStatus == undefined) &&
                (dbPayment?.transactionPaymentStatus == null ||
                  dbPayment.transactionPaymentStatus == undefined)
              ) {
                //Action new payment
                const paymentResponse =
                  (txPayment as TransactionPaymentConsolidate).customerId &&
                  (txPayment as TransactionPaymentConsolidate).customerId > 0
                    ? // non-zero customerId indicates payment by AR account and we may need to create new account
                      await this.postTransactionPaymentOffline(
                        txPayment as TransactionPaymentConsolidate,
                        pkg.secretByteArray,
                        {
                          customerId: (
                            txPayment as TransactionPaymentConsolidate
                          ).customerId,
                          siteId: pkg.transaction.saleSiteID,
                          stationId: pkg.transaction.station,
                        },
                      )
                    : await this.postTransactionPaymentOffline(
                        txPayment as TransactionPaymentConsolidate,
                        pkg.secretByteArray,
                      );
                txPayment = paymentResponse;
              } else {
                //Action update payment Delete
                if (
                  (txPayment as TransactionPaymentConsolidate).isDeleted ==
                    true &&
                  dbPayment
                ) {
                  if (!dbPayment.isDeleted) {
                    const paymentResponse = await this.deleteTransactionPayment(
                      txPayment,
                    );
                    txPayment = paymentResponse;
                    (
                      paymentResponse as TransactionPaymentConsolidate
                    ).isDeleted = true;
                    (
                      paymentResponse as TransactionPaymentConsolidate
                    ).isSynced = true;
                    await this._transactionPaymentDb.update(
                      paymentResponse as TransactionPaymentConsolidate,
                    );
                  }
                }
                //update payment from pending to active in recordStatus
                if (
                  dbPayment.recordStatus == 'Pending' &&
                  txPayment.recordStatus == 'Active'
                ) {
                  const paymentresponse = await this.putTransactionPayment(
                    txPayment as TransactionPaymentConsolidate,
                    pkg.transaction.saleSiteID,
                    pkg.transaction.station,
                    pkg.secretByteArray,
                  );
                  txPayment = paymentresponse;
                }
              }
            }
            await this._transactionDb.update(pkg.transaction);
          }
        }

        if (pkg.transaction.signatureImage?.signatureImageId) {
          //Action Signature
          try {
            await this.putSignatureImage(
              pkg.transaction.signatureImage,
              pkg.transaction,
            );
          } catch (error) {
            this._consoleLogger.logError(
              'TransactionService - Error Putting Signature Image:',
              error,
            );
          }
        }

        if (pkg.transaction.isCompleted) {
          //Action complete Transaction
          //Re fetch the transaction to get the latest changes
          pkg = await this._transactionDb.getDbPackage(_currentTransactionId);
          if (_.isEmpty(pkg)) {
            return null;
          }
          await this.putTransaction(pkg)
            .then(newTransaction => {
              return this.patchTransaction(this._sessionId, newTransaction);
            })
            .then(completed => {
              pkg.transaction = completed;
            });
          return pkg.transaction;
        } else {
          //Action Update Transaction
          await this.putTransaction(pkg);
          return pkg.transaction;
        }
      }
      case TransactionStatusEnum.Complete:
        return pkg.transaction;
      case TransactionStatusEnum.Error:
        alert(
          'Transaction ' +
            pkg.transaction.transactionId.toString() +
            ' has an error. Please contact support to verify its status.',
        );
      case TransactionStatusEnum.Accepted:
        pkg.transaction.isCompleted = true;
        pkg.transaction.status = TransactionStatusEnum.Complete;

        await this.putTransaction(pkg)
          .then(newTransaction => {
            return this.patchTransaction(this._sessionId, newTransaction);
          })
          .then(completed => {
            pkg.transaction = completed;
          });
        return pkg.transaction;
      case TransactionStatusEnum.Deleted:
        if (
          pkg.transaction.serverTransactionID > 0 &&
          pkg.transaction.isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(
            this._sessionId,
            (pkg.transaction as Transaction).transactionId,
          );
          return pkg.transaction;
        }
        return pkg.transaction;
      default:
        throw new Error('Invalid transaction status');
    }
  };

  syncNonSessionTransactionOffline = async (
    transaction: EncryptedTransaction,
  ): Promise<NonSessionSyncResult> => {
    this._consoleLogger.styledLog(
      'TransactionService - Offline Non-Session Sync Start:',
      ConsoleLoggerVariant.PURPLE,
      transaction,
    );

    switch (transaction.status) {
      case TransactionStatusEnum.Error:
      case TransactionStatusEnum.Active: {
        if (transaction.serverTransactionID > 0 && transaction.isDeleted) {
          await this.deleteTransaction(
            transaction.sessionId,
            transaction.transactionId,
          );
          return {
            sessionId: transaction.sessionId,
            transaction: transaction,
            needsDeletedLocally: false,
            needsCompleted: false,
            userSub: transaction.userSub ?? '',
          }; //this entry is already deleted
        }

        if (!transaction.isDeleted) {
          await this.nonSessionSyncSignatureImage(transaction);
          const transactionSynced = await this.nonSessionSyncTransaction(
            transaction,
          );
          const paymentsSynced = await this.nonSessionSyncTransactionPayments(
            transaction,
          );
          return {
            sessionId: transaction.sessionId,
            transaction: transaction,
            needsDeletedLocally: transactionSynced && paymentsSynced,
            needsCompleted: transaction.isCompleted ?? false,
            userSub: transaction.userSub ?? '',
          };
        }

        return {
          sessionId: transaction.sessionId,
          transaction: transaction,
          needsDeletedLocally: true,
          needsCompleted: false,
          userSub: transaction.userSub ?? '',
        }; //this entry never existed on the server (shouldn't be possible unless we add the ability to create offline)
      }

      case TransactionStatusEnum.Complete:
      case TransactionStatusEnum.Accepted:
        // in an unsynced context, both complete and accepted should have the same action
        await this.nonSessionSyncSignatureImage(transaction);
        const transactionSynced = await this.nonSessionSyncTransaction(
          transaction,
        );
        const paymentsSynced = await this.nonSessionSyncTransactionPayments(
          transaction,
        );
        return {
          sessionId: transaction.sessionId,
          transaction: transaction,
          needsDeletedLocally: transactionSynced && paymentsSynced,
          needsCompleted: true,
          userSub: transaction.userSub ?? '',
        };

      case TransactionStatusEnum.Deleted:
        if (transaction.serverTransactionID > 0 && transaction.isDeleted) {
          await this.deleteTransaction(
            transaction.sessionId,
            transaction.transactionId,
          );
          return {
            sessionId: transaction.sessionId,
            transaction: transaction,
            needsDeletedLocally: false,
            needsCompleted: false,
            userSub: transaction.userSub ?? '',
          }; //this entry is already deleted
        }
        return {
          sessionId: transaction.sessionId,
          transaction: transaction,
          needsDeletedLocally: true,
          needsCompleted: false,
          userSub: transaction.userSub ?? '',
        }; //this entry never existed on the server (shouldn't be possible unless we add the ability to create offline)
      default:
        throw new Error('Invalid transaction status');
    }
  };

  // isolated try-catch, if it fails we still want to try to continue and sync all possible transaction data
  nonSessionSyncTransaction = async (
    transaction: EncryptedTransaction,
  ): Promise<boolean> => {
    try {
      return await this.putTransactionEncrypted(transaction);
    } catch {
      this._consoleLogger.logError(
        'TransactionService - Error syncing non-session transaction: ',
        transaction,
      );
      return false;
    }
  };

  // isolated try-catch, if it fails we still want to try to continue and sync all possible transaction data
  nonSessionSyncSignatureImage = async (
    transaction: EncryptedTransaction,
  ): Promise<void> => {
    try {
      await this._encryptedSyncApi.SyncSignatureImageFromTransaction(
        transaction,
      );
    } catch {
      this._consoleLogger.logError(
        'TransactionService - Error syncing signature image for non-session transaction: ',
        transaction,
      );
    }
  };

  // isolated try-catch, if it fails we still want to try to continue and sync all possible transaction data
  nonSessionSyncTransactionPayments = async (
    transaction: EncryptedTransaction,
  ): Promise<boolean> => {
    try {
      return await this.syncTransactionPaymentsOffline(transaction);
    } catch {
      this._consoleLogger.logError(
        'TransactionService - Error syncing non-session transaction payments: ',
        transaction,
      );
      return false;
    }
  };

  deleteTransactionsAssociatedWithSessionFromLocalDatabase = async (
    session: Session,
  ): Promise<void> => {
    session.transactions.forEach(async transaction => {
      await this.deleteTransactionAndPaymentsFromLocalDatabase(
        transaction.transactionId,
      );
    });
  };

  deleteTransactionAndPaymentsFromLocalDatabase = async (
    transactionId: string,
  ): Promise<boolean> => {
    const payments = await this._transactionPaymentDb.getPaymentsForTransaction(
      transactionId,
    );
    payments.forEach(async payment => {
      await this._transactionPaymentDb.delete(payment.transactionPaymentId);
    });
    await this._transactionDb.delete(transactionId);
    return true;
  };
}
