/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {Array<string>}
     * @memberof Agent
     */
    checks: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    name: string;
}

export function AgentFromJSON(json: any): Agent {
    return AgentFromJSONTyped(json, false);
}

export function AgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checks': json['checks'],
        'name': json['name'],
    };
}

export function AgentToJSON(value?: Agent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checks': value.checks,
        'name': value.name,
    };
}


