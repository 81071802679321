import {ScanResult} from '../..';
import {BarcodeComponent, Prescription} from '@emporos/api-enterprise';

type Complete<T> = {
  [P in keyof Required<T>]: Required<T>[P] extends null | infer X
    ? X
    : Required<T>[P];
};

export type CompleteBarcodeComponent = Complete<BarcodeComponent>;

export interface BarcodeParsedResult {
  rx: string | undefined;
  partial: string | undefined;
  site: string | undefined;
  fill: string | undefined;
  rxId: string | undefined | null;
}

export interface IdBarcodeResult {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  expirationDate?: string;
  state?: string;
  idNumber?: string;
}

const PAYOR_PLAN_1 = 'payorplan1';
const PAYOR_PLAN_2 = 'payorplan2';
const PAYOR_PLAN_3 = 'payorplan3';
const PAYOR_PLAN_4 = 'payorplan4';
const PAYOR_PLAN_5 = 'payorplan5';
export const payorPlanNames = new Set([
  PAYOR_PLAN_1,
  PAYOR_PLAN_2,
  PAYOR_PLAN_3,
  PAYOR_PLAN_4,
  PAYOR_PLAN_5,
]);

export const DELIMITER = 'delimiter';
export const PREFIX = 'prefix';
export const RX_NUMBER = 'rxnumber';

export interface IPMSStrategy {
  decode(
    scanResult: ScanResult,
    barcodeComponents: CompleteBarcodeComponent[],
  ): BarcodeParsedResult;

  isRxItem(item: BarcodeParsedResult): boolean;

  findRx(
    prescriptions: Prescription[],
    item: BarcodeParsedResult,
  ): Prescription | undefined;
}
