/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SmsStatusEnum,
    SmsStatusEnumFromJSON,
    SmsStatusEnumFromJSONTyped,
    SmsStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextReceiptResponse
 */
export interface TextReceiptResponse {
    /**
     * 
     * @type {string}
     * @memberof TextReceiptResponse
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof TextReceiptResponse
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof TextReceiptResponse
     */
    errorMessage: string;
    /**
     * 
     * @type {SmsStatusEnum}
     * @memberof TextReceiptResponse
     */
    status: SmsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TextReceiptResponse
     */
    sid: string;
    /**
     * 
     * @type {Date}
     * @memberof TextReceiptResponse
     */
    dateSent?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TextReceiptResponse
     */
    linkToPay: string;
    /**
     * 
     * @type {boolean}
     * @memberof TextReceiptResponse
     */
    readonly isInError: boolean;
}

export function TextReceiptResponseFromJSON(json: any): TextReceiptResponse {
    return TextReceiptResponseFromJSONTyped(json, false);
}

export function TextReceiptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextReceiptResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'to': json['to'],
        'errorMessage': json['errorMessage'],
        'status': SmsStatusEnumFromJSON(json['status']),
        'sid': json['sid'],
        'dateSent': !exists(json, 'dateSent') ? undefined : (json['dateSent'] === null ? null : new Date(json['dateSent'])),
        'linkToPay': json['linkToPay'],
        'isInError': json['isInError'],
    };
}

export function TextReceiptResponseToJSON(value?: TextReceiptResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'to': value.to,
        'errorMessage': value.errorMessage,
        'status': SmsStatusEnumToJSON(value.status),
        'sid': value.sid,
        'dateSent': value.dateSent === undefined ? undefined : (value.dateSent === null ? null : value.dateSent.toISOString()),
        'linkToPay': value.linkToPay,
    };
}


