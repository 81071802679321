import {Redirect, RouteComponentProps} from '@reach/router';
import {memo} from 'react';
import {
  useTransaction,
  useTotals,
  useTransactionsState,
  withRootPage,
  useGlobalData,
} from '../../../';
import {CompletedTransaction as Completed} from './';

function unimplemented() {
  throw new Error('unimplemented!');
}

function CompletedTransactionComponent(
  _: RouteComponentProps & {children?: React.ReactNode},
): JSX.Element {
  const {currentTransactionId} = useTransactionsState();
  const {paymentTendersResult} = useGlobalData();
  const {transaction, updateTransaction} = useTransaction();
  const {totals} = useTotals();

  if (!paymentTendersResult || !paymentTendersResult.data) {
    return <Redirect to="/sales" noThrow />;
  }

  return (
    <Completed
      currentTransactionId={currentTransactionId}
      transaction={transaction}
      totals={totals}
      paymentTenders={paymentTendersResult.data}
      onUpdateTransaction={updateTransaction}
      onEmailReceipt={unimplemented}
      onTextReceipt={unimplemented}
      onPrintReceipt={unimplemented}
    />
  );
}

export const CompletedTransactionIntegration = memo(
  withRootPage(CompletedTransactionComponent),
);
