/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentType
 */
export interface PaymentType {
    /**
     * 
     * @type {number}
     * @memberof PaymentType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentType
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentType
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentType
     */
    paymentNumberRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentType
     */
    customerRequiredIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentType
     */
    creditCardIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentType
     */
    limitToBalanceIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentType
     */
    externalChargeIndicator: boolean;
}

export function PaymentTypeFromJSON(json: any): PaymentType {
    return PaymentTypeFromJSONTyped(json, false);
}

export function PaymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'paymentNumberRequired': json['paymentNumberRequired'],
        'customerRequiredIndicator': json['customerRequiredIndicator'],
        'creditCardIndicator': json['creditCardIndicator'],
        'limitToBalanceIndicator': json['limitToBalanceIndicator'],
        'externalChargeIndicator': json['externalChargeIndicator'],
    };
}

export function PaymentTypeToJSON(value?: PaymentType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'name': value.name,
        'paymentNumberRequired': value.paymentNumberRequired,
        'customerRequiredIndicator': value.customerRequiredIndicator,
        'creditCardIndicator': value.creditCardIndicator,
        'limitToBalanceIndicator': value.limitToBalanceIndicator,
        'externalChargeIndicator': value.externalChargeIndicator,
    };
}


