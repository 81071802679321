/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PseCheckResult {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function PseCheckResultFromJSON(json: any): PseCheckResult {
    return PseCheckResultFromJSONTyped(json, false);
}

export function PseCheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseCheckResult {
    return json as PseCheckResult;
}

export function PseCheckResultToJSON(value?: PseCheckResult | null): any {
    return value as any;
}

