/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CashDrawerListApiResponse,
    CashDrawerListApiResponseFromJSON,
    CashDrawerListApiResponseToJSON,
} from '../models';

export interface ClientTillsGetRequest {
    siteId?: number;
}

/**
 * 
 */
export class TillsApi extends runtime.BaseAPI {

    /**
     * Gets a list of available tills
     */
    async clientTillsGetRaw(requestParameters: ClientTillsGetRequest): Promise<runtime.ApiResponse<CashDrawerListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/tills`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CashDrawerListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets a list of available tills
     */
    async clientTillsGet(requestParameters: ClientTillsGetRequest): Promise<CashDrawerListApiResponse> {
        const response = await this.clientTillsGetRaw(requestParameters);
        return await response.value();
    }

}
