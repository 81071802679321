import App from './App';
// import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {ContainerProvider} from 'brandi-react';
import {DIFactory} from './DIFactory';
import './utils/resize-observer-bug-fix';

// DI
const containerDI = DIFactory.buildDefaultContainer();

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container);

root.render(
  // <StrictMode>
  <ContainerProvider container={containerDI}>
    <App />
  </ContainerProvider>,
  // </StrictMode>,
);
