/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Payment Request for staging payment information
 * @export
 * @interface PaymentProcessorRequest
 */
export interface PaymentProcessorRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorRequest
     */
    requestType?: string | null;
    /**
     * The site id
     * @type {number}
     * @memberof PaymentProcessorRequest
     */
    siteId: number;
    /**
     * Amount to be charged
     * @type {number}
     * @memberof PaymentProcessorRequest
     */
    totalAmount: number;
    /**
     * Total amount of QHP Eligibile products
     * @type {number}
     * @memberof PaymentProcessorRequest
     */
    qhpAmount: number;
    /**
     * Id for the Transaction, this is sent as Meta Data to the Gateway
     * @type {string}
     * @memberof PaymentProcessorRequest
     */
    transactionId?: string | null;
    /**
     * Unique Identifier for terminal, if required.
     * @type {string}
     * @memberof PaymentProcessorRequest
     */
    terminalId?: string | null;
    /**
     * User / Clerk processing payment identifier.
     * @type {string}
     * @memberof PaymentProcessorRequest
     */
    userId?: string | null;
    /**
     * Unique number to pass to gateway.
     * @type {string}
     * @memberof PaymentProcessorRequest
     */
    orderNumber?: string | null;
}

export function PaymentProcessorRequestFromJSON(json: any): PaymentProcessorRequest {
    return PaymentProcessorRequestFromJSONTyped(json, false);
}

export function PaymentProcessorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentProcessorRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestType': !exists(json, 'requestType') ? undefined : json['requestType'],
        'siteId': json['siteId'],
        'totalAmount': json['totalAmount'],
        'qhpAmount': json['qhpAmount'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
    };
}

export function PaymentProcessorRequestToJSON(value?: PaymentProcessorRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestType': value.requestType,
        'siteId': value.siteId,
        'totalAmount': value.totalAmount,
        'qhpAmount': value.qhpAmount,
        'transactionId': value.transactionId,
        'terminalId': value.terminalId,
        'userId': value.userId,
        'orderNumber': value.orderNumber,
    };
}


