import {PropsWithoutRef, forwardRef, memo, useState} from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import {Icons, IndicatorCircle, TextSize} from '.';
import {v4 as uuidv4} from 'uuid';
import {CrossIconProps} from 'react-select/dist/declarations/src/components/indicators';

export interface TextInputWithPlaceholderProps {
  label?: string;
  placeholder?: string;
  isError?: boolean;
  icon?: keyof typeof Icons;
  disabled?: boolean;
  onClear?: () => void;
  mask?: string;
  // eslint-disable-next-line
  as?: any;
  loading?: boolean;
  inputSize?: TextSize;
  grayedOut?: boolean;
}

const Container = styled.div<
  TextInputWithPlaceholderProps & {
    floating: boolean;
  }
>`
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.black};
  font-family: ${({theme}) => theme.typography.Main.fontFamily};
  font-weight: ${({theme}) => theme.typography.Main.fontWeight};
  font-size: ${({theme}) => theme.typography.Main.fontSize};
  line-height: ${({theme}) => theme.typography.Main.lineHeight};
  position: relative;
  width: 100%;

  .input-text-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({inputSize}) =>
      inputSize === TextSize.Large ? '80px' : '50px'};
    position: absolute;
    left: 16px;

    .indicator-circle {
      ${({disabled}) => {
        if (disabled) {
          return 'opacity: 0.4;';
        }
      }};
    }
  }

  label {
    position: absolute;
    font-size: ${({inputSize}) =>
      inputSize === TextSize.Standard ? '0.666em' : '15px'};
    font-weight: ${({theme}) => theme.typography.Main.fontWeight};
    top: ${({inputSize}) =>
      inputSize === TextSize.Standard ? '-1.25em' : '-14px'};
    left: 16px;
    margin-left: -6px;
    padding: 2px 6px;
    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.blue};
  }

  textarea {
    padding-top: 12px;
    resize: vertical;
  }
  textarea,
  input {
    background-color: ${({theme, disabled}) =>
      disabled ? theme.colors.gray_100 : theme.colors.white};
    border: 1px solid
      ${({theme, isError}) =>
        isError ? theme.colors.error : theme.colors.gray_200};
    border-radius: 8px;
    min-height: ${({inputSize}) =>
      inputSize === TextSize.Large ? '80px' : '50px'};
    color: ${({disabled, theme}) =>
      disabled ? theme.colors.steel : 'inherit'};
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding-left: ${({icon}) => (icon ? '52px' : '16px')};
    height: inherit;
    width: inherit;
    -webkit-appearance: none;
    margin: 0;

    ${({disabled}) =>
      disabled && {
        cursor: 'not-allowed',
      }}

    outline: none;
    border-color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.gray_200};
  }

  input::placeholder {
    color: ${({theme, disabled}) => disabled && theme.colors.steel};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Svg = ({
  size,
  ...props
}: JSX.IntrinsicElements['svg'] & {size: number}) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    {...props}
  />
);

const CrossIcon = (props: CrossIconProps) => (
  <Svg size={20} {...props}>
    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
  </Svg>
);

const ClearButton = styled.span<TextInputWithPlaceholderProps>`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 16px;
  height: ${({inputSize}) => (inputSize === TextSize.Large ? '80px' : '50px')};
  display: flex;
  align-items: center;
  fill: ${({grayedOut}) => (grayedOut ? 'hsl(0, 0%, 80%)' : undefined)};
`;

export const TextInputWithPlaceholder = memo(
  forwardRef<
    InputMask,
    TextInputWithPlaceholderProps &
      PropsWithoutRef<JSX.IntrinsicElements['input']>
  >(
    (
      {
        as = InputMask,
        inputSize = TextSize.Standard,
        icon,
        label,
        placeholder,
        isError,
        disabled,
        onClear,
        style,
        mask,
        // maskChar,
        loading,
        ...props
      },
      ref,
    ) => {
      const Input = as;

      // this is a strange usage of useState because we want to ensure that the id
      // is constant through the lifecycle of this component
      const [id] = useState(() => props.id || `text-input-${uuidv4()}`);
      const [indicatorCircleType, setIndicatorCircleType] = useState<
        'gray' | 'primary' | 'error'
      >('gray');
      const hasValue =
        typeof props.value === 'number' ||
        (typeof props.value === 'string' && props.value !== '');

      return (
        <Container
          inputSize={inputSize}
          floating={hasValue}
          disabled={disabled}
          isError={Boolean(isError)}
          icon={icon}
          onFocus={() => setIndicatorCircleType('primary')}
          onBlur={() => setIndicatorCircleType('gray')}
          style={style}
          className={isError ? 'input-error' : ''}
          data-testid={`textInput-${label}`}
        >
          {icon && (
            <div className="input-text-icon">
              <IndicatorCircle
                className="indicator-circle"
                size="small"
                icon={loading ? 'Spinner' : icon}
                loading={loading}
                variant={isError ? 'error' : indicatorCircleType}
              />
            </div>
          )}
          <Input
            ref={ref}
            disabled={disabled}
            type="text"
            mask={mask || ''}
            // maskChar={maskChar}
            {...props}
            id={id}
            data-testid="placeholderInput"
            placeholder={`${placeholder}`}
          />
          <label htmlFor={id}>{label}</label>
          {!disabled && onClear && hasValue && (
            <ClearButton
              onClick={onClear}
              data-testid="placeholder-clear"
              inputSize={inputSize}
              grayedOut={true}
            >
              <CrossIcon />
            </ClearButton>
          )}
        </Container>
      );
    },
  ),
);
TextInputWithPlaceholder.displayName = 'TextInputWithPlaceholder';
