import {TransactionTax} from '@emporos/api-enterprise';

export const mergeTaxes = (
  taxes: TransactionTax[],
  transactionTaxes: TransactionTax[],
): TransactionTax[] => {
  const taxesCopy = [...taxes];
  const transactionTaxesCopy = [...transactionTaxes];

  /**
   * remove any existing taxes if
   * - the taxRate is different than 0
   * - and taxAmount has changed to zero
   * on the corresponding element of transactionTaxes.
   */
  const result = taxesCopy.reduce(
    (accumulator: TransactionTax[], tax: TransactionTax) => {
      const transactionTax = transactionTaxesCopy.find(
        tt =>
          tt.taxGroupID === tax.taxGroupID && tt.taxRateID === tax.taxRateID,
      );

      if (
        !transactionTax ||
        (transactionTax.taxRate && !transactionTax.taxAmount)
      )
        return accumulator;

      accumulator.push({
        ...transactionTax,
        transactionId: tax.transactionId,
        transactionTaxId: tax.transactionTaxId,
      } as TransactionTax);

      return accumulator;
    },
    [],
  );

  /**
   * add any transactionTaxes whose taxAmount > 0, and
   * hasn't already been included in the taxes array.
   */
  return result.concat(
    transactionTaxesCopy.filter(
      transactionTax =>
        (!transactionTax.taxRate || transactionTax.taxAmount) &&
        !result.some(
          tax =>
            tax.taxGroupID === transactionTax.taxGroupID &&
            tax.taxRateID === transactionTax.taxRateID,
        ),
    ),
  );
};
