/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStateEnum {
    Unknown = 'Unknown',
    Approved = 'Approved',
    Declined = 'Declined',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Other = 'Other'
}

export function PaymentStateEnumFromJSON(json: any): PaymentStateEnum {
    return PaymentStateEnumFromJSONTyped(json, false);
}

export function PaymentStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStateEnum {
    return json as PaymentStateEnum;
}

export function PaymentStateEnumToJSON(value?: PaymentStateEnum | null): any {
    return value as any;
}

