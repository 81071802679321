import {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {ColorType, Icons, mapColor, PaymentIcons} from './';

export enum IconSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  Payment = 'Payment',
  CardOnFile = 'CardOnFile',
}
const iconSize = (size: IconSize) => {
  switch (size) {
    case IconSize.XS:
      return 12;
    case IconSize.S:
      return 16;
    case IconSize.L:
      return 30;
    case IconSize.XL:
      return 36;
    case IconSize.Payment:
      return 44;
    case IconSize.CardOnFile:
      return 60;
    case IconSize.M:
    default:
      return 24;
  }
};

export const AllIcons = {
  ...Icons,
  ...PaymentIcons,
};
export interface IconProps {
  icon: keyof typeof AllIcons;
  variant?: ColorType;
  size?: IconSize;
}

const StyledIcon = styled.div<IconProps>`
  display: inline-block;
  width: fit-content;
  color: ${({theme, variant}) => mapColor(theme, variant)};

  svg {
    display: block;
  }
`;

export const Icon = memo(
  ({
    size = IconSize.M,
    variant = 'black',
    ...props
  }: IconProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const Icon = AllIcons[props.icon];
    return (
      <StyledIcon variant={variant} {...props}>
        <Icon
          width={iconSize(size)}
          height={size === IconSize.Payment ? 32 : iconSize(size)}
        />
      </StyledIcon>
    );
  },
);
