import {
  ButtonSquareText,
  Gutter,
  Row,
  RowItem,
  ScrollContainer,
  SmallSidebarWrapper,
  Stack,
  Text,
  TextVariant,
} from '@emporos/components';
import {formatMedication, transactionItemIcons} from '../../../../utils';
import {ITEM_TYPE_IDS} from '../../../../constants';
import {ReactElement, useMemo} from 'react';
import {Transaction} from '@emporos/api-enterprise';
import styled from 'styled-components';

interface PatientPayBodyProps {
  transaction: Transaction;
  children: ReactElement;
}

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    padding: 0 12px 20px 12px;
  }
  @media (max-width: 1180px) {
    margin-left: 15px;
  }
`;

export const ActionButton = styled(ButtonSquareText)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;

export const PatientPayBody = ({
  transaction,
  children,
}: PatientPayBodyProps) => {
  const rxItems = useMemo(() => {
    return transaction.items.filter(item => item.rx);
  }, [transaction]);

  const items = useMemo(() => {
    return transaction.items.filter(item => !item.rx);
  }, [transaction]);

  return (
    <>
      <Row
        gutter={Gutter.XXL}
        style={{height: '100%', marginTop: 20, minHeight: 0}}
        $noWrap
      >
        <Stack className="transaction-mobile" style={{flex: 1}}>
          {(!!rxItems.length || !!items.length) && (
            <ScrollContainer>
              {!!rxItems.length && (
                <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                  <Text
                    variant={TextVariant.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Prescriptions
                  </Text>
                  {rxItems.map(item => (
                    <RowItem
                      key={item.transactionItemId}
                      title={formatMedication(item.description2 || '')}
                      subtitle={item.receiptDescription || ''}
                      rightIcons={transactionItemIcons(item)}
                      rightText={`$${item.price.toFixed(2)}`}
                    />
                  ))}
                </Stack>
              )}
              {!!items.length && (
                <Stack gutter={Gutter.S}>
                  <Text
                    variant={TextVariant.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Over the Counter
                  </Text>
                  {items.map(item => (
                    <RowItem
                      key={item.transactionItemId}
                      inactive={
                        item.itemTypeID === ITEM_TYPE_IDS.METH_FREE &&
                        transaction.pseCheckResult === 1
                      }
                      variant="generic"
                      title={formatMedication(item.description || '')}
                      subtitle={item.itemNumber || ''}
                      rightIcons={transactionItemIcons(item)}
                      rightText={`$${item.price.toFixed(2)}`}
                      rightBadgeColor="success"
                      rightBadgeText={
                        item.discountPercent
                          ? `${(item.discountPercent * 100).toFixed(0)}%`
                          : ''
                      }
                      rightStrikeText={
                        item.price !== item.listPrice && item.discount === 0
                          ? `$${item.listPrice.toFixed(2)}`
                          : ''
                      }
                      quantity={
                        item.quantity > 0 ? item.quantity.toString() : ''
                      }
                    />
                  ))}
                </Stack>
              )}
            </ScrollContainer>
          )}

          {children}
        </Stack>
        <RightSidebar>
          <Row justify="space-between" gutter={Gutter.None}>
            <ActionButton
              type="button"
              icon="CashRegister"
              text="Price"
              data-testid="price-button"
              disabled={true}
            />
            <ActionButton
              type="button"
              icon="Percent2"
              text="Discount"
              data-testid="discount-button"
              disabled={true}
            />
            <ActionButton
              type="button"
              icon="Quantity"
              text="Quantity"
              data-testid="quantity-button"
              disabled={true}
            />
            <ActionButton
              type="button"
              icon="XCircle"
              text="Remove"
              data-testid="remove-button"
              disabled={true}
            />
          </Row>
        </RightSidebar>
      </Row>
    </>
  );
};
