import {trimEnd, trimStart} from 'lodash';
import {CompleteBarcodeComponent, Symbology} from '../..';
import {BarcodeParsedResult, PREFIX} from '../IPMSStrategy';
import {BaseStrategy} from '../BaseStrategy';
import {Prescription} from '@emporos/api-enterprise';
export class OptumStrategy extends BaseStrategy {
  validSymbologies = [Symbology.Code128, Symbology.Code39];

  public override isRxItem(item: BarcodeParsedResult): boolean {
    return !!item.rxId;
  }

  public override findRx(
    prescriptions: Prescription[],
    item: BarcodeParsedResult,
  ): Prescription | undefined {
    if (item.rxId == null) return;
    return prescriptions.find(
      prescription => prescription.rxId === trimStart(item.rxId as string, '0'),
    );
  }

  validate(
    components: CompleteBarcodeComponent[],
    symbology: Symbology | null,
  ): boolean {
    return (
      components.length > 0 &&
      symbology !== null &&
      this.validSymbologies.includes(symbology)
    );
  }

  getDelimiter(
    _components: CompleteBarcodeComponent[],
    _rawBarcode: string,
  ): string | undefined {
    return undefined;
  }

  generateRegexPattern(
    componentsCopy: CompleteBarcodeComponent[],
    componentNames: string[],
    delimiter: string | undefined,
  ): string {
    let regexPattern = '';
    componentsCopy.forEach(component => {
      if (
        component.componentIndex > 0 &&
        component.componentName &&
        component.componentMask
      ) {
        const name = component.componentName.toLowerCase();
        componentNames.push(name);
        const mask = trimEnd(trimStart(component.componentMask, '^'), '$');

        if (name === PREFIX) {
          regexPattern = regexPattern + '(' + mask + ')';
        } else {
          if (delimiter) {
            regexPattern = regexPattern + '(\\' + delimiter + mask + ')';
          } else {
            regexPattern = regexPattern + '(' + mask + ')';
          }
        }
      }
    });
    regexPattern = '^' + regexPattern + '$';

    return regexPattern;
  }

  getComponentsValues(
    componentValues: string[],
    matches: RegExpMatchArray,
    delimiter: string | undefined,
  ): void {
    for (let index = 1; index < matches.length; index++) {
      const match = matches[index];
      if (!delimiter) {
        componentValues.push(match);
      } else {
        componentValues.push(match.replace(delimiter, ''));
      }
    }
  }

  reduceComponents(
    parsedResult: BarcodeParsedResult,
    componentNames: string[],
    componentValues: string[],
  ): BarcodeParsedResult {
    return componentNames.reduce((accumulator, name, index) => {
      const value = name ? componentValues[index] : '';
      if (name === 'rxid') {
        accumulator.rxId = value;
      }
      return accumulator;
    }, parsedResult);
  }
}
