/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentTypeListApiResponse,
    PaymentTypeListApiResponseFromJSON,
    PaymentTypeListApiResponseToJSON,
} from '../models';

export interface ClientPaymentTendersGetRequest {
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class PaymentTypesApi extends runtime.BaseAPI {

    /**
     * Gets Payment Tenders.
     */
    async clientPaymentTendersGetRaw(requestParameters: ClientPaymentTendersGetRequest): Promise<runtime.ApiResponse<PaymentTypeListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment-tenders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentTypeListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Payment Tenders.
     */
    async clientPaymentTendersGet(requestParameters: ClientPaymentTendersGetRequest): Promise<PaymentTypeListApiResponse> {
        const response = await this.clientPaymentTendersGetRaw(requestParameters);
        return await response.value();
    }

}
