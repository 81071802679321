import {
  CustomerCreditCard,
  Transaction,
  TransactionPayment,
  PaymentType,
} from '@emporos/api-enterprise';
import {DeviceUnreachable, Processor} from '@emporos/card-payments';
import {
  Button,
  CardOnFileProps,
  Gutter,
  Modal,
  Row,
  ScrollContainer,
  Select,
  Stack,
  Variant,
} from '@emporos/components';
import {CardOnFile} from '@emporos/components/src/CardOnFile';
import {NavigateFn} from '@reach/router';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  Credentials,
  mapCardsToProps,
  PaymentProvider,
  SESSION_TIMEOUT,
  UpdateTransactionFn,
} from '../../../../../';
import {
  BillBoard,
  HandleTransactionResponse,
  HandleTransactionResponseRef,
  PaymentRef as Handle,
} from './';
import {isDevelopment} from '../../../../../utils/environment';

interface Props {
  cardsOnFile: Array<CustomerCreditCard>;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  totalDue: number;
  qhpAmount: number;
  deviceCredentials: Credentials;
  onContinue: () => void;
  onChangeActivePayment: () => void;
  navigate: NavigateFn;
  paymentTenders: PaymentType[];
  paymentProvider: PaymentProvider;
  transaction: Transaction;
  updateTransaction: UpdateTransactionFn;
  cardHolderName?: string;
}

export enum DeviceStatus {
  Unknown,
  Disconnected,
  Connecting,
  Connected,
  DeviceError,
  Timeout,
  DeviceIsUnreachable,
  AppCancelled,
  UserCancelled,
  PaymentError,
  PaymentProcessing,
  CCOFPaymentProcessing,
  PaymentDeclined,
  PaymentWarning,
  PaymentExpired,
  PaymentSuccess,
  PartialPaymentSuccess,
  CancelTransactionFailed,
  CheckStatus,
  NonCardSelected,
  PaymentErrorUnknown,
}
const HOSTED_PAYMENT = 'HostedPayment';

const CardColumns = styled('div')`
  display: inline-flex;
  justify-content: center;
  width: 50%;
  position: relative;
  z-index: 0;
  @media (max-width: 1600px) {
    width: 100%;
  }
`;

interface CardState {
  cards: CardOnFileProps[];
}

export const CreditCard = forwardRef<Handle, Props>(function CreditCard(
  {
    cardsOnFile,
    showVantiv,
    showHostedPayment,
    creditCardPendingPaymentExpirationMinutes,
    amount,
    totalDue,
    qhpAmount,
    setAmount,
    deviceCredentials,
    onContinue,
    onChangeActivePayment,
    navigate,
    paymentTenders,
    transaction,
    updateTransaction,
    setSelectedPayment,
    paymentProvider: {
      checkDevice,
      stageTransaction,
      initiateTransaction,
      cancelTransaction,
      getTransactionDetails,
      vaultTransaction,
    },
    cardHolderName,
  }: Props,
  ref,
): JSX.Element {
  const [status, setStatus] = useState(DeviceStatus.Unknown);
  const [value, setValue] = useState<Processor | string>(
    deviceCredentials.processor,
  );
  const [pendingPayment, setPendingPayment] =
    useState<TransactionPayment | null>(null);
  const handleRef = useRef<HandleTransactionResponseRef | null>(null);

  const [creditMethod, setCreditMethod] = useState('Device');

  useEffect(() => {
    onChangeActivePayment();
  }, [value]);

  const onPay = useCallback(
    async function onConfirmPayment() {
      setPendingPayment(null);

      switch (value) {
        case Processor.Vantiv:
        case Processor.Cayan:
          {
            try {
              setStatus(DeviceStatus.Connecting);
              await checkDevice();
              setStatus(DeviceStatus.Connected);

              const getPendingPayment = await stageTransaction({
                totalAmount: Number(amount),
                qhpAmount,
              });

              setPendingPayment(getPendingPayment);

              if (!getPendingPayment.cardToken) {
                throw getPendingPayment;
              }

              const result = await initiateTransaction(
                getPendingPayment.cardToken,
                Number(amount),
              );

              handleRef.current?.handleTransactionResponse(
                result,
                getPendingPayment,
              );
            } catch (e) {
              // if we get back a TransactionAbortSignal message, we know that we
              // called the cancelTransaction() message
              if (e === Symbol.for('TransactionAbortSignal')) {
                return null;
              }
              if ((e as Error).message === DeviceUnreachable) {
                setStatus(DeviceStatus.DeviceIsUnreachable);
              } else {
                setStatus(DeviceStatus.PaymentError);
              }
              return null;
            }
          }
          break;
        default: {
          if (creditMethod === 'On File') {
            const card = cardsOnFile.find(f => f.token === value);
            if (!card) {
              setStatus(DeviceStatus.NonCardSelected);
              return null;
            }
            setStatus(DeviceStatus.CCOFPaymentProcessing);

            try {
              const result = await vaultTransaction({
                totalAmount: Number(amount),
                qhpAmount,
                cardToken: card.token,
                paymentTypeId: card.paymentTypeId,
                last4OfCard: card.last4OfCard,
                paymentInformationId: card.paymentInformationId,
              });
              if (!result.jsonPaymentProcessorResponse) {
                throw result;
              }
              const processorStatus = JSON.parse(
                result.jsonPaymentProcessorResponse,
              ).Status;
              if (processorStatus !== 'Approved') {
                throw result;
              }
              updateTransaction(prevTransaction => ({
                payments: prevTransaction.payments.concat(result),
              }));
              setStatus(DeviceStatus.PaymentSuccess);
            } catch (e) {
              setStatus(DeviceStatus.PaymentError);
            }
          }
        }
      }
      return null;
    },
    [amount, value, pendingPayment],
  );

  const onCheckStatus = useCallback(async () => {
    if (!pendingPayment) {
      setStatus(DeviceStatus.PaymentError);
      return null;
    }
    try {
      setStatus(DeviceStatus.CheckStatus);
      setSelectedPayment(pendingPayment.transactionPaymentId);
      const result = await getTransactionDetails(pendingPayment);
      handleRef.current?.handleTransactionResponse(result, pendingPayment);
    } catch {
      setStatus(DeviceStatus.Timeout);
    }
  }, [pendingPayment]);

  useImperativeHandle(ref, () => ({
    onConfirmPayment: onPay,
    isAcceptablePayment: () => value !== HOSTED_PAYMENT,
  }));

  const _onContinue = useCallback(
    function _onContinue() {
      setStatus(DeviceStatus.Unknown);
      onContinue();
    },
    [setStatus, onContinue],
  );

  const onCancel = useCallback(
    function onCancel() {
      setStatus(DeviceStatus.Unknown);
    },
    [setStatus],
  );

  const onCancelTransaction = useCallback(
    async function onCancelTransaction() {
      try {
        await cancelTransaction();
        setStatus(DeviceStatus.AppCancelled);
      } catch (err) {
        setStatus(DeviceStatus.CancelTransactionFailed);
      }
    },
    [setStatus],
  );

  const optionsValue = () => {
    const values: (Processor | string)[] = [Processor.Cayan];
    if (showVantiv) {
      values.push(Processor.Vantiv);
    }
    return [...values];
  };

  const optionsText = () => {
    const texts = ['Cayan Device'];
    if (showVantiv) {
      texts.push('Vantiv Device');
    }
    return [...texts];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reducer = (state: CardState, action: any): CardState => {
    switch (action.type) {
      case 'addCardsOnFile':
        return {
          ...state,
          cards: mapCardsToProps(cardsOnFile, cardHolderName as string),
        };
      case 'selectCard':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectCards = state.cards.reduce((acc: any, card: any) => {
          if (card.cardToken === action.card.cardToken) {
            acc.push({
              ...card,
              isSelected: true,
            });
            setValue(card.cardToken);
          } else {
            acc.push({
              ...card,
              isSelected: false,
            });
          }
          return acc;
        }, []);

        return {...state, cards: selectCards};
      case 'resetCards':
        // eslint-disable-next-line
        const resetCards = state.cards.map((card: any) => ({
          ...card,
          isSelected: false,
        }));

        return {...state, cards: resetCards};
      case 'default':
        return {...state};
    }
    return {...state};
  };

  const [stateCards, dispatch] = useReducer(reducer, {
    cards: mapCardsToProps(cardsOnFile, cardHolderName as string),
  });

  useEffect(() => {
    dispatch({type: 'addCardsOnFile'});
  }, [cardsOnFile]);

  // useEffect hook to monitor changes in the `status` and `onCancel` dependencies
  useEffect(() => {
    // Checkthe current status
    if (
      status === DeviceStatus.NonCardSelected ||
      status === DeviceStatus.Timeout ||
      status === DeviceStatus.PaymentError ||
      status === DeviceStatus.CancelTransactionFailed ||
      status === DeviceStatus.PaymentErrorUnknown ||
      status === DeviceStatus.UserCancelled
    ) {
      // If the status matches, execute the `onCancel` function immediately
      onCancelTransaction();
    }
    // The dependencies array ensures this effect runs only when
    // `status` or `onCancel` changes, optimizing performance
  }, [status, onCancel]);

  // Set timeout for the payment device to cancel the transaction
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    //This will have 1 minute less the global Timeout configured  for HILO
    if (status === DeviceStatus.Connected) {
      timer = setTimeout(() => {
        onCancelTransaction();
      }, SESSION_TIMEOUT - 1000); //
    }

    // Clear the timer when the modal is no longer visible or when the component is unmounted
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [status, onCancelTransaction]);

  return (
    <>
      <ScrollContainer>
        <Stack style={{flex: 1}}>
          <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
          <Row
            gutter={Gutter.XL}
            $noWrap
            data-testid="creditcardpayments-entryoptions"
          >
            <Button
              type="button"
              data-testid="creditcardpayments-manual-button"
              onClick={() => {
                setCreditMethod('Manual');
                setValue('HOSTED_PAYMENT');
              }}
              variant={
                creditMethod === 'Manual' ? Variant.Primary : Variant.Secondary
              }
              disabled={!showHostedPayment || !isDevelopment()}
              flex
            >
              Manual
            </Button>
            <Button
              type="button"
              onClick={() => {
                setCreditMethod('Device');
                setValue(Processor.Cayan);
                dispatch({
                  type: 'resetCards',
                  card: null,
                  index: 0,
                });
              }}
              variant={
                creditMethod === 'Device' ? Variant.Primary : Variant.Secondary
              }
              flex
            >
              Device
            </Button>
            <Button
              type="button"
              data-testid="onfile-button"
              onClick={() => {
                setCreditMethod('On File');

                const initialCard = stateCards.cards[0] ?? null;

                if (initialCard && initialCard.cardToken) {
                  dispatch({
                    type: 'selectCard',
                    card: initialCard,
                    index: 0,
                  });
                  setValue(initialCard.cardToken);
                } else {
                  setCreditMethod('On File');
                  setValue('');
                }
              }}
              variant={
                creditMethod === 'On File' ? Variant.Primary : Variant.Secondary
              }
              disabled={stateCards.cards.length === 0}
              flex
            >
              On File
            </Button>
          </Row>
          {creditMethod === 'Manual' && (
            <Row data-testid="creditcardpayments-manualentry">
              <Button
                onClick={() => navigate('hosted-payment', {state: {amount}})}
                style={{flex: 1}}
              >
                Launch Payment Portal
              </Button>
            </Row>
          )}
          {creditMethod === 'Device' && (
            <Row
              style={{marginTop: 36}}
              data-testid="creditcardpayments-deviceentry"
            >
              <Select
                options={optionsValue()}
                optionsText={optionsText()}
                value={value}
                onChangeValue={setValue}
                label="Credit Card"
              />
            </Row>
          )}

          {creditMethod === 'On File' && stateCards && stateCards.cards && (
            <div data-testid="creditcardpayments-onfileentry">
              {stateCards.cards.map((card: CardOnFileProps, index: number) => (
                <CardColumns
                  key={index}
                  onClick={() => {
                    dispatch({
                      type: 'selectCard',
                      card: card,
                      index: index,
                    });
                  }}
                >
                  <CardOnFile {...card} key={index} showLowerRow={false} />
                </CardColumns>
              ))}
            </div>
          )}
          {value === HOSTED_PAYMENT && (
            <Row>
              <Button
                onClick={() => navigate('hosted-payment', {state: {amount}})}
                style={{flex: 1}}
              >
                Launch Payment Portal
              </Button>
            </Row>
          )}
        </Stack>
      </ScrollContainer>
      <Modal
        data-testid="Modal__DeviceUnreachable"
        visible={status === DeviceStatus.DeviceIsUnreachable}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onPay}
        buttonText="Retry"
        title="Device Unreachable"
        subtitle="The device could not be reached, please check your payment device settings."
      />
      <Modal
        data-testid="Modal__DeviceError"
        visible={status === DeviceStatus.Timeout}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onCheckStatus}
        buttonText="Check Status"
        title="Payment Device Timeout"
        subtitle="Something didn’t work with the payment device. Would you like to try again?"
      />
      <Modal
        visible={status === DeviceStatus.Connected}
        data-testid="Modal__Connected"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Connection is Established"
        subtitle="Connection has been established. Please complete payment on the device."
        buttonText="Cancel"
        onContinue={onCancelTransaction}
      />
      <Modal
        visible={status === DeviceStatus.CCOFPaymentProcessing}
        data-testid="Modal__CCOFPaymentProcessing"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Processing Credit Card Payment"
        subtitle="The payment is currently processing. Please hold tight."
      />
      <Modal
        data-testid="Modal__PaymentError"
        visible={status === DeviceStatus.NonCardSelected}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Payment Error"
        subtitle="Transaction could not be processed. Please select a card or try again."
        errorMessage={''}
      />

      <HandleTransactionResponse
        onCancel={onCancel}
        onContinue={_onContinue}
        transaction={transaction}
        updateTransaction={updateTransaction}
        paymentTenders={paymentTenders}
        status={status}
        setStatus={setStatus}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
        ref={handleRef}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
      />
    </>
  );
});
