/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    CustomerResponse,
    CustomerResponseFromJSON,
    CustomerResponseFromJSONTyped,
    CustomerResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerResponseApiResponse
 */
export interface CustomerResponseApiResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof CustomerResponseApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {CustomerResponse}
     * @memberof CustomerResponseApiResponse
     */
    data?: CustomerResponse;
}

export function CustomerResponseApiResponseFromJSON(json: any): CustomerResponseApiResponse {
    return CustomerResponseApiResponseFromJSONTyped(json, false);
}

export function CustomerResponseApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerResponseApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : CustomerResponseFromJSON(json['data']),
    };
}

export function CustomerResponseApiResponseToJSON(value?: CustomerResponseApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': CustomerResponseToJSON(value.data),
    };
}


