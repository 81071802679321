/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PseCheckRequest,
    PseCheckRequestFromJSON,
    PseCheckRequestToJSON,
    PseCheckResponseApiResponse,
    PseCheckResponseApiResponseFromJSON,
    PseCheckResponseApiResponseToJSON,
} from '../models';

export interface ClientPseCheckPutRequest {
    pseCheckRequest?: PseCheckRequest;
}

/**
 * 
 */
export class PseCheckApi extends runtime.BaseAPI {

    /**
     * Relays will-call to enterprise.
     */
    async clientPseCheckPutRaw(requestParameters: ClientPseCheckPutRequest): Promise<runtime.ApiResponse<PseCheckResponseApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/pse-check`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PseCheckRequestToJSON(requestParameters.pseCheckRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PseCheckResponseApiResponseFromJSON(jsonValue));
    }

    /**
     * Relays will-call to enterprise.
     */
    async clientPseCheckPut(requestParameters: ClientPseCheckPutRequest): Promise<PseCheckResponseApiResponse> {
        const response = await this.clientPseCheckPutRaw(requestParameters);
        return await response.value();
    }

}
