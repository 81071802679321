import {CashDrawer, Site, Station} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Row,
  ScrollContainer,
  ExtendedSelect,
  Stack,
  TextInputWithPlaceholder,
  TextSize as Size,
  toCurrency,
  Variant,
} from '@emporos/components';
import {memo, useEffect, useReducer, useState} from 'react';
import {useNetworkAvailable} from '../../../contexts';

type FormState = {
  site: Site;
  station: Station;
  paymentDeviceAddress: string;
  paymentDevicePort: string;
  till: CashDrawer;
  tillStartingAmount: number;
};

type FormStateWithSite = Pick<FormState, 'site'> & {
  [K in keyof Omit<FormState, 'site'>]: null;
};
type FormStateWithStation = Pick<FormState, 'site' | 'station'> & {
  [K in keyof Omit<FormState, 'site' | 'station'>]:
    | Omit<FormState, 'site' | 'station'>[K]
    | null;
};
export type SessionConfig =
  | {[K in keyof FormState]: null}
  | FormStateWithSite
  | FormStateWithStation;

export function isSessionConfigCompleted(
  config: SessionConfig,
): config is FormState {
  return (
    config.site != null &&
    config.station != null &&
    config.till != null &&
    config.tillStartingAmount != null
  );
}

const compareCollator = new Intl.Collator('en', {sensitivity: 'base'});

interface Props {
  sites: Site[];
  stations: Station[];
  tills: CashDrawer[];
  config: SessionConfig;
  configSession?: boolean;
  loading: boolean;
  tenantId?: string;
  onChange: (config: SessionConfig) => void;
  onLogout?: () => void;
  onConfirm?: () => void;
}

type FormOptionsState = {
  stations: Station[] | null;
  tills: CashDrawer[] | null;
};

type FormOptionsReducerAction = {
  type?: 'clear' | 'setStations' | 'setTills';
  value?: Station[] | CashDrawer[];
};

const formOptionsReducer = (
  state: FormOptionsState,
  {type, value}: FormOptionsReducerAction,
) => {
  switch (type) {
    case 'clear':
      return {...state, stations: null, tills: null};
    case 'setStations':
      return {...state, stations: value as Station[]};
    case 'setTills':
      return {...state, tills: value as CashDrawer[]};
    default:
      return {...state};
  }
};

function CreateSessionComponent({
  sites,
  stations,
  tills,
  config,
  configSession = false,
  onChange,
  onLogout,
  onConfirm,
  loading,
  tenantId,
}: Props): JSX.Element {
  const {site, station, till, tillStartingAmount} = config;
  const [formOptionsState, dispatchFormOptionsState] = useReducer(
    formOptionsReducer,
    {stations: null, tills: null},
  );
  const confirmDisabled = !isSessionConfigCompleted(config) || !tenantId;
  const disableField = configSession || !site;
  const disableFieldStation = !station;
  const {online} = useNetworkAvailable();
  const orderedSites = [...sites].sort((a, b) =>
    compareCollator.compare(a.shortName, b.shortName),
  );
  const [tillStartingAmountDisplay, setTillStartingAmountDisplay] =
    useState<string>(
      tillStartingAmount ? `$${toCurrency(tillStartingAmount)}` : '',
    );

  useEffect(() => {
    dispatchFormOptionsState({type: 'clear'});
    setTillStartingAmountDisplay(
      tillStartingAmount ? `$${toCurrency(tillStartingAmount)}` : '',
    );
  }, [site]);

  useEffect(() => {
    // update station options when the stations prop changes
    const orderedStation = stations.sort((a, b) =>
      compareCollator.compare(a.name ?? '', b.name ?? ''),
    );
    dispatchFormOptionsState({type: 'setStations', value: orderedStation});
  }, [stations]);

  useEffect(() => {
    const orderedTills = [...tills].sort((a, b) =>
      compareCollator.compare(a.cashDrawerName ?? '', b.cashDrawerName ?? ''),
    );
    // update till options when the tills prop changes
    dispatchFormOptionsState({type: 'setTills', value: orderedTills});
  }, [tills]);

  // common function to update the config
  const updateConfig = (newConfig: Partial<SessionConfig>) => {
    onChange({...config, ...(newConfig as SessionConfig)});
  };

  return (
    <Stack>
      {!configSession && <Header title="Create Session" />}
      <ScrollContainer>
        <Stack
          gutter={Gutter.L}
          style={{flex: 1, marginTop: 6, paddingTop: configSession ? 16 : 0}}
        >
          <Row>
            <ExtendedSelect
              label="Site*"
              instanceId={'site'}
              isDisabled={!online || configSession}
              isMulti={false}
              isClearable={true}
              isSearchable={true}
              options={orderedSites}
              value={site}
              getOptionLabel={({shortName}) => shortName}
              onChange={site => {
                updateConfig({
                  site: site,
                  station: null,
                  paymentDeviceAddress: null,
                  paymentDevicePort: null,
                  till: null,
                  tillStartingAmount: null,
                });
              }}
            />
            <ExtendedSelect
              isDisabled={
                !online || disableField || !formOptionsState.stations?.length
              }
              label="Station*"
              instanceId={'station'}
              isLoading={Boolean(site && !formOptionsState.stations)}
              isSearchable={false}
              isMulti={false}
              options={formOptionsState.stations || []}
              getOptionLabel={({name}) => name ?? ''}
              value={station}
              onChange={station => {
                const foundPaymentDevice = {
                  address: station?.paymentDeviceAddress,
                  port: station?.paymentDevicePort,
                };
                updateConfig({
                  station: station || undefined,
                  paymentDeviceAddress: foundPaymentDevice.address || '',
                  paymentDevicePort: foundPaymentDevice.port || '',
                });
              }}
            />
          </Row>
          <Row>
            <ExtendedSelect
              isDisabled={
                !online || disableField || !formOptionsState.tills?.length
              }
              label="Till*"
              instanceId={'till'}
              isSearchable={false}
              isMulti={false}
              isLoading={Boolean(site && !formOptionsState.tills)}
              options={formOptionsState.tills || []}
              getOptionLabel={({cashDrawerName}) => cashDrawerName ?? ''}
              value={till}
              onChange={cashDrawer => {
                updateConfig({
                  till: cashDrawer,
                });
              }}
            />
            <TextInputWithPlaceholder
              disabled={!online || disableField}
              name="tillStartingAmount"
              label="Till Starting Amount*"
              placeholder="$0.00"
              value={tillStartingAmountDisplay}
              onFocus={() => {
                if (!tillStartingAmount) {
                  updateConfig({tillStartingAmount: null});
                  setTillStartingAmountDisplay('$0.00');
                }
              }}
              onBlur={() => {
                if (tillStartingAmount === null) {
                  updateConfig({tillStartingAmount: null});
                  setTillStartingAmountDisplay('');
                }
              }}
              onChange={value => {
                const valueAsCurrency = toCurrency(value.target.value, '.');
                updateConfig({tillStartingAmount: Number(valueAsCurrency)});
                setTillStartingAmountDisplay(`$${valueAsCurrency}`);
              }}
              onClear={() => {
                updateConfig({tillStartingAmount: null});
                setTillStartingAmountDisplay('');
              }}
              style={{flex: '1'}}
              inputSize={Size.Large}
              inputMode="numeric"
            />
          </Row>
          <Row>
            <TextInputWithPlaceholder
              disabled={!online || disableFieldStation}
              name="paymentDeviceAddress"
              label="Payment Device Address"
              placeholder=""
              value={config.paymentDeviceAddress || ''}
              onChange={value => {
                updateConfig({
                  paymentDeviceAddress: value.target.value,
                  paymentDevicePort:
                    (config.station as Station)?.paymentDevicePort || '',
                });
              }}
              onClear={() => {
                updateConfig({
                  paymentDeviceAddress: '',
                });
              }}
              style={{flex: '0.5', marginRight: '15px'}}
              inputSize={Size.Large}
            />
          </Row>
        </Stack>
      </ScrollContainer>
      {!configSession && onLogout && onConfirm && (
        <FooterGroup>
          <Button
            variant={Variant.Danger}
            flex
            disabled={!online}
            onClick={onLogout}
          >
            Logout
          </Button>
          <Button
            flex
            disabled={!online || confirmDisabled}
            onClick={confirmDisabled ? undefined : onConfirm}
            loading={loading}
          >
            Create Access Code
          </Button>
        </FooterGroup>
      )}
    </Stack>
  );
}

export const CreateSession = memo(CreateSessionComponent);
