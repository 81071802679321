import {Modal} from '@emporos/components';

interface ModalProps {
  visible: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  message?: string;
  onClose?: () => void;
}

export const DeleteConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
}: ModalProps) => {
  const handleContinue = () => {
    if (onConfirm) {
      console.log('handleContinue');
      onConfirm();
    }
  };

  return (
    <Modal
      visible={visible}
      title="Confirm Deletion"
      subtitle="Are you sure you want to delete this credit card?"
      buttonText="Delete"
      color="error"
      onContinue={handleContinue}
      onCancel={onCancel}
      icon={'Trash'}
    />
  );
};

export const SuccessModal = ({visible, message, onClose}: ModalProps) => (
  <Modal
    visible={visible}
    title="Success"
    subtitle={message}
    buttonText="Close"
    color="success"
    onContinue={onClose}
    icon={'Checkmark'}
  />
);

export const ErrorModal = ({visible, message, onClose}: ModalProps) => (
  <Modal
    visible={visible}
    title="Error"
    subtitle={message}
    buttonText="Close"
    color="error"
    onContinue={onClose}
    icon={'InfoCircle'}
  />
);

export const ProcessingModal = ({visible}: {visible: boolean}) => (
  <Modal
    visible={visible}
    title="Processing..."
    subtitle="Please wait while we process your request."
    color="primary"
    disableSubmit
    icon={'Check'}
    iconSpinning={true}
  />
);
