/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ReceiptResponseApiResponse,
    ReceiptResponseApiResponseFromJSON,
    ReceiptResponseApiResponseToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
    TransactionRequest,
    TransactionRequestFromJSON,
    TransactionRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdTransactionsGetRequest {
    sessionId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdTransactionsPostRequest {
    sessionId: string;
    transactionRequest?: TransactionRequest;
}

export interface ClientCacheSessionsSessionIdTransactionsPutRequest {
    sessionId: string;
    transactionRequest?: TransactionRequest;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest {
    sessionId: string;
    transactionId: string;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest {
    sessionId: string;
    transactionId: string;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdGetRequest {
    sessionId: string;
    transactionId: string;
    includeChildren?: boolean;
}

/**
 * 
 */
export class TransactionsApi extends runtime.BaseAPI {

    /**
     * Gets a list of transactions for a session
     */
    async clientCacheSessionsSessionIdTransactionsGetRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsGetRequest): Promise<runtime.ApiResponse<Array<Transaction>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransactionFromJSON));
    }

    /**
     * Gets a list of transactions for a session
     */
    async clientCacheSessionsSessionIdTransactionsGet(requestParameters: ClientCacheSessionsSessionIdTransactionsGetRequest): Promise<Array<Transaction>> {
        const response = await this.clientCacheSessionsSessionIdTransactionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts an transaction
     */
    async clientCacheSessionsSessionIdTransactionsPostRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsPostRequest): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionRequestToJSON(requestParameters.transactionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Inserts an transaction
     */
    async clientCacheSessionsSessionIdTransactionsPost(requestParameters: ClientCacheSessionsSessionIdTransactionsPostRequest): Promise<Transaction> {
        const response = await this.clientCacheSessionsSessionIdTransactionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an transaction
     */
    async clientCacheSessionsSessionIdTransactionsPutRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsPutRequest): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionRequestToJSON(requestParameters.transactionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Updates an transaction
     */
    async clientCacheSessionsSessionIdTransactionsPut(requestParameters: ClientCacheSessionsSessionIdTransactionsPutRequest): Promise<Transaction> {
        const response = await this.clientCacheSessionsSessionIdTransactionsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Currently, OTC items and tax info is removed from the transaction, and then sent to Enterprise as ```Canceled```.  Enterprise records the transaction, marks it as ```Deleted```, and returns.  The transaction status then marked as ```Deleted```.
     * Cancels (Deletes) an transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/cancel`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Currently, OTC items and tax info is removed from the transaction, and then sent to Enterprise as ```Canceled```.  Enterprise records the transaction, marks it as ```Deleted```, and returns.  The transaction status then marked as ```Deleted```.
     * Cancels (Deletes) an transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRaw(requestParameters);
    }

    /**
     * Currently, the transaction is posted as a completed transaction to Enterprise.  Changes made by Enterprise (statuses, etc.) are then returned and saved to the transaction itself, and the transaction status is set to ```Complete```.
     * Completes an transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/complete`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Currently, the transaction is posted as a completed transaction to Enterprise.  Changes made by Enterprise (statuses, etc.) are then returned and saved to the transaction itself, and the transaction status is set to ```Complete```.
     * Completes an transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest): Promise<Transaction> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets an transaction by session id and transaction id
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdGetRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdGetRequest): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdGet.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     * Gets an transaction by session id and transaction id
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdGet(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdGetRequest): Promise<Transaction> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdGetRaw(requestParameters);
        return await response.value();
    }

}
