/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BarcodeComponent
 */
export interface BarcodeComponent {
    /**
     * 
     * @type {number}
     * @memberof BarcodeComponent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BarcodeComponent
     */
    barcodeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BarcodeComponent
     */
    componentIndex?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BarcodeComponent
     */
    componentMask?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BarcodeComponent
     */
    componentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BarcodeComponent
     */
    manualEntryDisplayIndex?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BarcodeComponent
     */
    manualEntryDisplayText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BarcodeComponent
     */
    allowManualEntry: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BarcodeComponent
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof BarcodeComponent
     */
    webCallParameterName?: string | null;
}

export function BarcodeComponentFromJSON(json: any): BarcodeComponent {
    return BarcodeComponentFromJSONTyped(json, false);
}

export function BarcodeComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarcodeComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcodeName': !exists(json, 'barcodeName') ? undefined : json['barcodeName'],
        'componentIndex': !exists(json, 'componentIndex') ? undefined : json['componentIndex'],
        'componentMask': !exists(json, 'componentMask') ? undefined : json['componentMask'],
        'componentName': !exists(json, 'componentName') ? undefined : json['componentName'],
        'manualEntryDisplayIndex': !exists(json, 'manualEntryDisplayIndex') ? undefined : json['manualEntryDisplayIndex'],
        'manualEntryDisplayText': !exists(json, 'manualEntryDisplayText') ? undefined : json['manualEntryDisplayText'],
        'allowManualEntry': json['allowManualEntry'],
        'required': json['required'],
        'webCallParameterName': !exists(json, 'webCallParameterName') ? undefined : json['webCallParameterName'],
    };
}

export function BarcodeComponentToJSON(value?: BarcodeComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcodeName': value.barcodeName,
        'componentIndex': value.componentIndex,
        'componentMask': value.componentMask,
        'componentName': value.componentName,
        'manualEntryDisplayIndex': value.manualEntryDisplayIndex,
        'manualEntryDisplayText': value.manualEntryDisplayText,
        'allowManualEntry': value.allowManualEntry,
        'required': value.required,
        'webCallParameterName': value.webCallParameterName,
    };
}


