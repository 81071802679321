import {
  QuerySessionStatusArgs,
  RevokeTokensTypes,
  SessionStatus,
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutPopupArgs,
  SignoutRedirectArgs,
  User,
  UserManager,
  UserManagerEvents,
  UserManagerSettings,
} from '@emporos/hilo-auth';
import {useReducer, useState, useEffect, useCallback, useMemo} from 'react';
import * as React from 'react';
import {hasAuthParams, loginError} from '../utils/auth';
import {authreducer} from '../utils/authreducer';
import {AuthState, initialAuthState} from './AuthState';

export interface AuthContextProps extends AuthState {
  readonly settings: UserManagerSettings;
  readonly events: UserManagerEvents;
  clearStaleState(): Promise<void>;
  getStoredUser(): Promise<User | null>;
  removeUser(): Promise<void>;
  storeUser(user?: User): Promise<void>;
  signinPopup(args?: SigninPopupArgs): Promise<User>;
  signinSilent(args?: SigninSilentArgs): Promise<User | null>;
  signinRedirect(args?: SigninRedirectArgs): Promise<void>;
  signinRefresh(user?: User | undefined): Promise<User>;
  signoutRedirect(args?: SignoutRedirectArgs): Promise<void>;
  signoutPopup(args?: SignoutPopupArgs): Promise<void>;
  querySessionStatus(
    args?: QuerySessionStatusArgs,
  ): Promise<SessionStatus | null>;
  revokeTokens(types?: RevokeTokensTypes): Promise<void>;
  startSilentRenew(): void;
  stopSilentRenew(): void;
}

export const OidcAuthContext = React.createContext<
  AuthContextProps | undefined
>(undefined);

export interface AuthProviderProps extends UserManagerSettings {
  children?: React.ReactNode;
  onSigninCallback?: (user: User | void) => Promise<void> | void;
  skipSigninCallback?: boolean;
  onRemoveUser?: () => Promise<void> | void;
  onSignoutRedirect?: () => Promise<void> | void;
  onSignoutPopup?: () => Promise<void> | void;
  onRefresh?: () => Promise<User> | void;
  implementation?: typeof UserManager | null;
}

const userManagerContextKeys = [
  'clearStaleState',
  'querySessionStatus',
  'revokeTokens',
  'startSilentRenew',
  'stopSilentRenew',
] as const;
const navigatorKeys = [
  'signinPopup',
  'signinSilent',
  'signinRedirect',
  'signoutPopup',
  'signoutRedirect',
  'signinRefresh',
] as const;

const unsupportedEnvironment = (fnName: string) => () => {
  throw new Error(
    `UserManager#${fnName} was called from an unsupported context. If this is a server-rendered page, defer this call with useEffect() or pass a custom UserManager implementation.`,
  );
};
const defaultUserManagerImpl =
  typeof window === 'undefined' ? null : UserManager;

export const OidcAuthProvider = (props: AuthProviderProps): JSX.Element => {
  const {
    children,
    onSigninCallback,
    skipSigninCallback,
    onRemoveUser,
    onSignoutRedirect,
    onSignoutPopup,
    onRefresh,
    implementation: UserManagerImpl = defaultUserManagerImpl,
    ...userManagerSettings
  } = props;

  const [userManager] = useState(() =>
    UserManagerImpl
      ? new UserManagerImpl(userManagerSettings)
      : ({settings: userManagerSettings} as UserManager),
  );
  const [state, dispatch] = useReducer(authreducer, initialAuthState);
  const userManagerContext = useMemo(
    () =>
      Object.assign(
        {
          settings: userManager.settings,
          events: userManager.events,
        },
        Object.fromEntries(
          userManagerContextKeys.map(key => [
            key,
            userManager[key]?.bind(userManager) ?? unsupportedEnvironment(key),
          ]),
        ) as Pick<UserManager, (typeof userManagerContextKeys)[number]>,
        Object.fromEntries(
          navigatorKeys.map(key => [
            key,
            userManager[key]
              ? async (...args: never[]) => {
                  dispatch({type: 'NAVIGATOR_INIT', method: key});
                  try {
                    return await userManager[key](...args);
                  } finally {
                    dispatch({type: 'NAVIGATOR_CLOSE'});
                  }
                }
              : unsupportedEnvironment(key),
          ]),
        ) as Pick<UserManager, (typeof navigatorKeys)[number]>,
      ),
    [userManager],
  );

  useEffect(() => {
    if (!userManager) return;
    void (async (): Promise<void> => {
      try {
        // check if returning back from authority server
        if (hasAuthParams() && !skipSigninCallback) {
          const user = await userManager.signinCallback();
          onSigninCallback && onSigninCallback(user);
        }
        const user = await userManager.getUser();
        dispatch({type: 'INITIALISED', user});
      } catch (error) {
        dispatch({type: 'ERROR', error: loginError(error)});
      }
    })();
  }, [userManager, skipSigninCallback, onSigninCallback]);

  // register to userManager events
  useEffect(() => {
    if (!userManager) return undefined;
    // event UserLoaded (e.g. initial load, silent renew success)
    const handleUserLoaded = (user: User) => {
      dispatch({type: 'USER_LOADED', user});
    };
    userManager.events.addUserLoaded(handleUserLoaded);

    // event UserUnloaded (e.g. userManager.removeUser)
    const handleUserUnloaded = () => {
      dispatch({type: 'USER_UNLOADED'});
    };
    userManager.events.addUserUnloaded(handleUserUnloaded);

    // event SilentRenewError (silent renew error)
    const handleSilentRenewError = (error: Error) => {
      dispatch({type: 'ERROR', error});
    };
    userManager.events.addSilentRenewError(handleSilentRenewError);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
    };
  }, [userManager]);

  const getStoredUser = useCallback(async () => {
    if (userManager) {
      return await userManager.getUser();
    }
    return null;
  }, [userManager]);

  const removeUser = useCallback(
    userManager
      ? () => userManager.removeUser().then(onRemoveUser)
      : unsupportedEnvironment('removeUser'),
    [userManager, onRemoveUser],
  );

  const storeUser = useCallback(
    async (user?: User | undefined) => {
      if (userManager && user) {
        await userManager.storeAndLoadUser(user);
      }
    },
    [userManager],
  );

  const signoutRedirect = useCallback(
    (args?: SignoutRedirectArgs) =>
      userManagerContext.signoutRedirect(args).then(onSignoutRedirect),
    [userManagerContext.signoutRedirect, onSignoutRedirect],
  );

  const signoutPopup = useCallback(
    (args?: SignoutPopupArgs) =>
      userManagerContext.signoutPopup(args).then(onSignoutPopup),
    [userManagerContext.signoutPopup, onSignoutPopup],
  );

  const signinRefresh = useCallback(
    async (user?: User | undefined): Promise<User> => {
      const retVal = await userManagerContext.signinRefresh(user);
      if (onRefresh && onRefresh instanceof Function) {
        await onRefresh();
      }
      return retVal;
    },
    [userManagerContext.signinRefresh, onRefresh],
  );

  return (
    <OidcAuthContext.Provider
      value={{
        ...state,
        ...userManagerContext,
        getStoredUser,
        removeUser,
        storeUser,
        signoutRedirect,
        signoutPopup,
        signinRefresh,
      }}
    >
      {children}
    </OidcAuthContext.Provider>
  );
};

export const useOidcAuth = (): AuthContextProps => {
  const context = React.useContext(OidcAuthContext);

  if (!context) {
    throw new Error(
      'OidcAuthProvider context is undefined, please verify you are calling useOidcAuth() as child of a <OidcAuthProvider> component.',
    );
  }

  return context;
};
