/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BarcodeComponent,
    BarcodeComponentFromJSON,
    BarcodeComponentFromJSONTyped,
    BarcodeComponentToJSON,
    PmsBarcodeMapping,
    PmsBarcodeMappingFromJSON,
    PmsBarcodeMappingFromJSONTyped,
    PmsBarcodeMappingToJSON,
} from './';

/**
 * 
 * @export
 * @interface BarcodeCollection
 */
export interface BarcodeCollection {
    /**
     * 
     * @type {Array<BarcodeComponent>}
     * @memberof BarcodeCollection
     */
    barcodeComponents: Array<BarcodeComponent>;
    /**
     * 
     * @type {Array<PmsBarcodeMapping>}
     * @memberof BarcodeCollection
     */
    pmsBarcodeMappings: Array<PmsBarcodeMapping>;
}

export function BarcodeCollectionFromJSON(json: any): BarcodeCollection {
    return BarcodeCollectionFromJSONTyped(json, false);
}

export function BarcodeCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarcodeCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'barcodeComponents': ((json['barcodeComponents'] as Array<any>).map(BarcodeComponentFromJSON)),
        'pmsBarcodeMappings': ((json['pmsBarcodeMappings'] as Array<any>).map(PmsBarcodeMappingFromJSON)),
    };
}

export function BarcodeCollectionToJSON(value?: BarcodeCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'barcodeComponents': ((value.barcodeComponents as Array<any>).map(BarcodeComponentToJSON)),
        'pmsBarcodeMappings': ((value.pmsBarcodeMappings as Array<any>).map(PmsBarcodeMappingToJSON)),
    };
}


