import {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {ColorType, mapColor} from './';

export interface IndicatorBadgeProps {
  variant: ColorType;
  text: string | number;
}

const StyledIndicatorBadge = styled.span<IndicatorBadgeProps>`
  display: inline-block;
  font-family: ${({theme}) => theme.typography.Caption.fontFamily};
  font-weight: ${({theme}) => theme.typography.Caption.fontWeight};
  font-size: ${({theme}) => theme.typography.Caption.fontSize};
  text-transform: ${({theme}) => theme.typography.Caption.textTransform};
  line-height: ${({theme}) => theme.typography.Caption.lineHeight};
  background-color: ${({variant, theme}) => mapColor(theme, variant)};
  padding: 0 12px;
  border-radius: 20px;
  color: ${({theme}) => theme.colors.white};
  max-width: fit-content;
`;

export const IndicatorBadge = memo(
  (
    props: IndicatorBadgeProps & PropsWithoutRef<JSX.IntrinsicElements['span']>,
  ) => {
    return (
      <StyledIndicatorBadge data-testid="badge" {...props}>
        {props.text}
      </StyledIndicatorBadge>
    );
  },
);
