import {memo, useRef} from 'react';
import {animated, useSpring} from 'react-spring';
import styled from 'styled-components';
import {Icon, Icons} from './';

export interface TabBarItem {
  icon: keyof typeof Icons | JSX.Element;
  disabled?: boolean;
}

export interface TabBarProps {
  items: Array<TabBarItem>;
  active: number;
  onSelect(select: number): void;
  'data-testid'?: string;
}

const Container = styled.div`
  background: ${({theme}) => theme.colors.white};
  width: 100%;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: row;
  padding: 10px 11px;
  box-shadow: ${({theme}) => {
    return theme.shadows.shadow_8;
  }};
`;

const Item = styled.button<{$active: boolean}>`
  border: 0;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  min-width: 25%;
  height: 50px;
  transition: all 300ms ease-out;
  cursor: ${({disabled}): string => (disabled ? 'not-allowed' : 'pointer')};

  /* ensure a stacking context above the background */
  position: relative;
  z-index: 1;

  &:focus {
    outline: none;
  }
`;

const BackgroundDiv = styled.div`
  background: ${({theme}) => theme.colors.gray_100};
  border-radius: 8px;
  position: absolute;
  width: 68px;
  height: 50px;

  // Media query for screens up to 1180px in width
  @media (max-width: 1180px) {
    width: 52.75px;
  }
`;

const Background = animated(BackgroundDiv);

const NotificationIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 24px;
`;

const BaseNotificationDot = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-family: Ubuntu, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: white !important;
  line-height: 19px;
`;

const GreenNotificationDot = styled(BaseNotificationDot)`
  top: 0;
  right: 0;
  background-color: rgba(75, 202, 123, 1);
  margin-top: -13px;
  margin-right: -20px;

  // Media query for screens up to 1180px in width
  @media (max-width: 1180px) {
    margin-right: -14px;
  }
`;

const RedNotificationDot = styled(BaseNotificationDot)`
  bottom: 0;
  right: 0;
  background-color: rgba(255, 74, 113, 1);
  margin-bottom: -13px;
  margin-right: -20px;

  // Media query for screens up to 1180px in width
  @media (max-width: 1180px) {
    margin-right: -14px;
  }
`;

interface TabItemWithNotificationProps {
  icon: keyof typeof Icons;
  notifyRed?: boolean;
  notifyGreen?: boolean;
  active?: boolean;
  countRed?: number;
  countGreen?: number;
  showErrorAlert?: boolean;
  onClick?: () => void;
}

/**
 * A tab item component with notification dots.
 * @param icon - The icon to display.
 * @param notifyRed - Whether to show a red notification dot.
 * @param notifyGreen - Whether to show a green notification dot.
 * @param active - Whether the tab is currently active.
 * @param countRed - The count to display in the red notification dot.
 * @param countGreen - The count to display in the green notification dot.
 * @param showErrorAlert - Whether to show an exclamation mark in the red notification dot.
 */
export const TabItemWithNotification: React.FC<
  React.PropsWithChildren<TabItemWithNotificationProps>
> = ({
  icon,
  notifyRed,
  notifyGreen,
  active,
  countRed = 0,
  countGreen = 0,
  showErrorAlert = false,
  onClick,
}) => (
  <NotificationIconWrapper onClick={onClick}>
    {' '}
    {}
    <Icon icon={icon} variant={active ? 'primary' : 'gray'} />
    {showErrorAlert && <RedNotificationDot>!</RedNotificationDot>}
    {!showErrorAlert && notifyRed && countRed > 0 && (
      <RedNotificationDot>
        {countRed > 99 ? '99+' : countRed}
      </RedNotificationDot>
    )}
    {!showErrorAlert && notifyGreen && countGreen > 0 && (
      <GreenNotificationDot>
        {countGreen > 99 ? '99+' : countGreen}
      </GreenNotificationDot>
    )}
  </NotificationIconWrapper>
);

export const TabBar = memo(
  ({items, active, onSelect, 'data-testid': dataTestId}: TabBarProps) => {
    const ref = useRef(null);
    const spring = useSpring({
      value: active,
      from: {value: 0},
    });
    return (
      <Container data-testid={dataTestId || 'TabBar'}>
        {items.map(({icon, disabled}, index) => {
          return (
            <Item
              onClick={_ => onSelect(index)}
              $active={active === index}
              ref={active === index ? ref : null}
              key={index}
              disabled={disabled}
            >
              {typeof icon === 'string' ? (
                <Icon
                  icon={icon}
                  variant={active === index ? 'primary' : 'gray'}
                />
              ) : (
                icon // if it's not a string, it's a JSX element, so just render it
              )}
            </Item>
          );
        })}
        <Background
          style={{
            transform: spring.value.to(o => `translateX(${o * 100}%)`),
          }}
        />
      </Container>
    );
  },
);
