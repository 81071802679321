import {
  Customer as TransactionCustomer,
  CustomerAccount,
  CustomerAccount as TransactionCustomerAccount,
  CustomerResponseApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  PaymentType,
} from '@emporos/api-enterprise';
import {
  Button,
  Gutter,
  Page,
  Row,
  ScrollContainer,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
} from '@emporos/components';
import moment from 'moment';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import styled from 'styled-components';
import {
  OfflineCustomer,
  formatCustomerName,
  getPaymentTypeId,
  mapTransactionCustomer,
  pds,
  useAlertState,
  useTransaction,
} from '../../../../../';
import {BillBoard, EmployeeSearch, PaymentRef as Handle} from './';
import {useConsoleLogger} from '../../../../../contexts/ConsoleLoggingProvider';

interface Props {
  isOnline: boolean;
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  paymentTenders: PaymentType[];
  onChangeActivePayment(account: CustomerAccount): void;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerResponseApiResponse>;
  onCreatePD: (id: number) => Promise<CustomerAccount | undefined>;
}

const CustomerRow = styled(Row)`
  display: flex;
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  height: 80px;
  border-radius: 8px;
  padding: 16px;
`;

const AccountRow = styled(CustomerRow)`
  width: 100%;
  position: relative;
  margin-top: 0.5rem;

  .select-label {
    position: absolute;
    font-size: 15px;
    font-weight: ${({theme}) => theme.typography.Main.fontWeight};
    color: ${({theme}) => theme.colors.blue};
    font-family: ${({theme}) => theme.typography.Main.fontFamily};
    top: -12px;
    left: 16px;
    padding: 2px 6px;
    background-color: ${({theme}) => theme.colors.white};
  }
`;

const AccountRowDisabled = styled(AccountRow)`
  background-color: ${({theme}) => theme.colors.gray_100};
`;

function isAcceptablePayment(
  account: CustomerAccount,
  amount: number,
  totalDue: number,
) {
  return account.creditLimit - account.balance >= amount && amount <= totalDue;
}

export const PD = forwardRef<Handle, Props>(function PDPayment(
  {
    isOnline,
    amount,
    setAmount,
    totalDue,
    paymentTenders,
    onChangeActivePayment,
    onSearchEligibility,
    onGetEmployee,
    onCreatePD,
  },
  ref,
): JSX.Element {
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const [employee, setEmployee] = useState<TransactionCustomer | null>(null);
  const [pdAccount, setPDAccount] = useState<TransactionCustomerAccount | null>(
    null,
  );
  const {transaction, updateTransaction} = useTransaction();
  const {notification} = useAlertState();
  const {logError} = useConsoleLogger();

  useImperativeHandle(ref, () => ({
    onConfirmPayment() {
      if (!pdAccount) {
        return Promise.reject(
          new TypeError(
            'Attempted to pay with PD without having a PD account available',
          ),
        );
      } else if (!isAcceptablePayment(pdAccount, amount, totalDue)) {
        return Promise.reject(
          new TypeError('Selected account has insufficient funds.'),
        );
      }
      return Promise.resolve({
        paymentId: pdAccount.accountNumber,
        amountApproved: amount,
        paymentTypeId: getPaymentTypeId('PD', paymentTenders),
      });
    },
    isAcceptablePayment() {
      return pdAccount
        ? isAcceptablePayment(pdAccount, amount, totalDue)
        : false;
    },
  }));

  const getEmployee = async (eligibility: EligibilitySearchMatch) => {
    onGetEmployee(eligibility)
      .then(async response => {
        if (!response.data) {
          return;
        }
        const mappedCustomer = mapTransactionCustomer(response.data);
        if (pds(mappedCustomer, false, false).length === 0) {
          await onCreatePD(mappedCustomer.id).then(created => {
            if (created) {
              mappedCustomer.accounts.push(created);
              setEmployee(mappedCustomer);
              setPDAccount(getNewestAccount(mappedCustomer));
              setSearchPanelOpen(false);
            }
          });
        } else {
          setEmployee(mappedCustomer);
          setPDAccount(getNewestAccount(mappedCustomer));
          setSearchPanelOpen(false);
        }
      })
      .catch(error => {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Unable to Locate PD Account',
          description:
            'A PD account could not be located for the employee added. Please contact support.',
        });
        logError(error);
      });
  };

  const getNewestAccount = (customer: OfflineCustomer) => {
    const pdAccounts = pds(customer, false, false);
    const newestAccount = pdAccounts.reduce((prev, current) =>
      prev.createDate > current.createDate ? prev : current,
    );
    return newestAccount;
  };

  /**
   * `onChangeActivePayment` is a prop from the CustomerPayment manager
   * component to listen to the lifecycle of this component and call
   * `ref.isAcceptablePayment()` to determine if the submit button is enabled or
   * not. This is an awkward React pattern, but enables us to delegate the
   * implementation of payment-specific logic within each payment type.
   */
  useEffect(() => {
    if (pdAccount) {
      onChangeActivePayment(pdAccount);
    }
  }, [pdAccount, amount]);

  /**
   * When an employee is selected for PD, and the transaction
   * doesn't already have a customer, use the PD employee as
   * the customer.
   */
  useEffect(() => {
    if (!transaction) return;
    if (!transaction.customer && employee)
      updateTransaction({...transaction, customer: employee});
  }, [employee]);

  return (
    <ScrollContainer>
      <Stack gutter={Gutter.L} style={{flex: 1}}>
        <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
        <CustomerRow justify="space-between" align="center">
          {employee ? (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>{formatCustomerName(employee)}</Text>
                <Text variant={TV.SubMainLight}>
                  DOB: {moment.utc(employee.dob).format('MM/DD/YYYY')}
                </Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Change
              </Button>
            </>
          ) : (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>Employee</Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Search
              </Button>
            </>
          )}
        </CustomerRow>
        <Row>
          {pdAccount ? (
            <AccountRow
              justify="space-between"
              align="center"
              data-testid="pd-account-row"
            >
              <span className="select-label" data-testid="pd-account-label">
                PD Account
              </span>
              <Stack gutter={Gutter.XXS} style={{flex: 1, margin: 0}}>
                <Text variant={TV.Main} data-testid="pd-account-available">
                  {`Available: $${(
                    pdAccount.creditLimit - pdAccount.balance
                  ).toFixed(2)}`}
                </Text>
                <Text variant={TV.SubMainLight} data-testid="pd-account-limit">
                  {`Limit: $${pdAccount.creditLimit.toFixed(2)} • Account: ${
                    pdAccount.accountNumber
                  }`}
                </Text>
              </Stack>
            </AccountRow>
          ) : (
            <AccountRowDisabled
              justify="space-between"
              align="center"
              data-testid="pd-account-row"
            >
              <span className="select-label" data-testid="pd-account-label">
                PD Account
              </span>
              <Stack gutter={Gutter.XXS} style={{flex: 1, margin: 0}}>
                <Text variant={TV.Main} data-testid="pd-account-available">
                  No Account
                </Text>
              </Stack>
            </AccountRowDisabled>
          )}
        </Row>
      </Stack>

      <Page level={3} open={searchPanelOpen} style={{left: 36, zIndex: 1}}>
        <EmployeeSearch
          label="Employee Number or Badge ID"
          online={isOnline}
          onAddEmployee={getEmployee}
          onCancel={() => setSearchPanelOpen(false)}
          onSearch={onSearchEligibility}
        />
      </Page>
    </ScrollContainer>
  );
});
