import {memo, PropsWithoutRef} from 'react';
import {
  ColorType,
  DividerLine,
  Gutter,
  IndicatorBadge,
  Row,
  Stack,
  Text,
  TextVariant as Variant,
} from './';

export interface HeaderProps {
  title: string;
  subtitle?: string;
  badgeType?: ColorType;
  badgeText?: string;
}

export const Header = memo(
  ({
    title,
    subtitle,
    badgeType = 'indigo',
    badgeText,
    ...props
  }: HeaderProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <Stack gutter={Gutter.M} style={{flexShrink: 0}} {...props}>
        <Row align="center" justify="space-between" $noWrap>
          <Row align="end" gutter={Gutter.XS}>
            <Stack gutter={Gutter.None}>
              <Text variant={Variant.T2}>{title}</Text>
              <Row gutter={Gutter.XS}>
                {subtitle && (
                  <Text variant={Variant.MainLight}>{subtitle}</Text>
                )}
                {badgeText && (
                  <IndicatorBadge variant={badgeType} text={badgeText} />
                )}
              </Row>
            </Stack>
          </Row>

          <Row align="center" gutter={Gutter.XL} $noWrap>
            {props.children}
          </Row>
        </Row>
        <DividerLine />
      </Stack>
    );
  },
);
