import {RouteComponentProps} from '@reach/router';
import {
  PatientPayBody,
  PatientPayFooter,
  PatientPayHeader,
} from './patient-pay';
import {Gutter, Modal, Stack} from '@emporos/components';
import {useSession, useTotals, useTransaction} from '../../../hooks';
import {memo, useCallback, useEffect, useState} from 'react';
import {
  Transaction,
  useSessionData,
  useTransactionsState,
} from '../../../contexts';
import {withRootPage} from '../../../hocs/withChildPage';
import assert from 'assert';

export interface PatientPayExtras {
  linkToPayCode?: string | null;
  lastUsedPhoneNumber?: string | null;
}

export const PatientPayTransactionIntegration = memo(
  withRootPage(
    ({navigate}: RouteComponentProps & {children?: React.ReactNode}) => {
      assert(
        navigate,
        '<TransactionPage /> must have a `navigate` prop.' +
          String(process.env.NODE_ENV) ===
          'production'
          ? ''
          : ' This likely means that you need to have it as a direct child of a <Router />',
      );

      const {transaction, canDelete, updateTransaction} = useTransaction();
      const {totals} = useTotals();
      const {updateSession} = useSession();
      const {settingsResult, stationType} = useSessionData();
      const {currentTransactionId} = useTransactionsState();
      const [loadingModalOpen, setLoadingModalOpen] = useState(
        !transaction.customer,
      );

      const onRemoveTransaction = useCallback(
        ({transactionId}: Transaction) => {
          updateSession(
            prevSession => ({
              transactions: prevSession.transactions.map(inv =>
                inv.transactionId === transactionId
                  ? {...inv, isDeleted: true, isSynced: false}
                  : inv,
              ),
            }),
            currentTransactionId,
          );
        },
        [updateSession, currentTransactionId],
      );

      useEffect(() => {
        if (transaction.customer) {
          setLoadingModalOpen(false);
        } else {
          setLoadingModalOpen(true);
        }
      }, [transaction]);

      return (
        <>
          {transaction.customer && (
            <Stack gutter={Gutter.None} style={{height: '100%'}}>
              <PatientPayHeader
                transaction={transaction}
                canDeleteTransaction={canDelete}
                onRemoveTransaction={onRemoveTransaction}
              />
              <PatientPayBody transaction={transaction}>
                <PatientPayFooter
                  totals={totals}
                  transaction={transaction}
                  settings={settingsResult?.data ?? []}
                  stationType={stationType}
                  onUpdateTransaction={updateTransaction}
                  navigate={navigate}
                />
              </PatientPayBody>
            </Stack>
          )}

          <Modal
            visible={loadingModalOpen}
            data-testid="loading-modal"
            icon="Spinner"
            color="primary"
            iconSpinning={true}
            title="Loading Transaction"
            subtitle="Loading the transaction to be delivered. Please hold tight."
            buttonText="Cancel"
            onContinue={() => {
              setLoadingModalOpen(false);
              navigate('/sales');
            }}
          />
        </>
      );
    },
  ),
);
