import {TransactionStatusEnum, PaymentType} from '@emporos/api-enterprise';
import {
  Button,
  ButtonShape,
  Gutter,
  Header,
  PaymentCard,
  PriceFooter,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  Select,
  ButtonSize as Size,
  Stack,
  Text,
  TextInput,
  TextVariant as TV,
  Illustration,
  IllustrationSize,
  FooterGroup,
} from '@emporos/components';
import {Link} from '@reach/router';
import moment from 'moment';
import {memo, useMemo, useState} from 'react';
import styled from 'styled-components';
import {
  BetaFeatureSwitch,
  displayPaymentNumber,
  formatCustomerName,
  formatMedication,
  getPaymentIconType,
  getPaymentType,
  getSimplePaymentIcon,
  Transaction,
  transactionItemIcons,
  TotalsResult,
  isPatientPayTransaction,
} from '../../../';
import {useConsoleLogger} from '../../../contexts/ConsoleLoggingProvider';

const ITEMS_TAB = 0;
const PAYMENTS_TAB = 1;
const RECEIPT_TAB = 2;

const CardRow = styled(Row)`
  height: 100%;
  flex: 1;
  & > * {
    margin: 18px;
  }
`;

const Footer = memo(function Footer({
  overTheCounter,
  prescriptionCopay,
  salesTax,
  qhpAmount,
  transactionTotal,
}: TotalsResult) {
  return (
    <PriceFooter
      totals={{
        overTheCounter,
        prescriptionCopay,
        salesTax,
        qhpAmount,
        transactionTotal,
      }}
    />
  );
});

interface Props {
  currentTransactionId: string;
  transaction: Transaction;
  totals: TotalsResult;
  paymentTenders: PaymentType[];
  onUpdateTransaction(updates: Partial<Transaction>): void;
  onEmailReceipt(): void;
  onTextReceipt(): void;
  onPrintReceipt(): void;
}
export function CompletedTransaction({
  currentTransactionId,
  transaction,
  totals,
  paymentTenders,
  onEmailReceipt,
  onTextReceipt,
  onPrintReceipt,
}: Props): JSX.Element {
  const [active, setActive] = useState(0);
  const [receiptActive, setReceiptActive] = useState(0);
  const {
    serverTransactionID,
    saleDate,
    textReceipt,
    items,
    customer,
    payments,
    emailReceipt,
  } = transaction;

  const activeReceipts = useMemo(() => {
    const activeRecipts = ['Email'];

    if (isPatientPayTransaction(transaction)) activeRecipts.push('Text');

    activeRecipts.push('Print');
    return activeRecipts;
  }, [transaction]);

  const formattedTextReceipt = useMemo(() => {
    const matches = textReceipt?.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    return matches
      ? `(${matches[1]}) ${matches[2]}${matches[3] ? '-' + matches[3] : ''}`
      : '';
  }, [textReceipt]);

  const {logError} = useConsoleLogger();

  if (!currentTransactionId || !transaction) {
    return (
      <Stack
        align="center"
        justify="center"
        style={{height: 'calc(100vh - 72px)'}}
      >
        <Illustration illustration="UploadFile" size={IllustrationSize.Large} />
      </Stack>
    );
  }

  const RECEIPT_EMAIL_TAB = activeReceipts.indexOf('Email');
  const RECEIPT_TEXT_TAB = activeReceipts.indexOf('Text');
  const RECEIPT_PRINTER_TAB = activeReceipts.indexOf('Print');

  const prescriptions = items.filter(({rx}) => rx);
  const otcItems = items.filter(({rx}) => !rx);

  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}}>
      <Header
        title={customer ? formatCustomerName(customer) : 'General Sale'}
        subtitle={
          transaction.status == TransactionStatusEnum.Error ||
          transaction.status == TransactionStatusEnum.Accepted
            ? '(Transaction with error, Contact Support)'
            : ''
        }
      >
        <SegmentSlider
          items={['Items', 'Payment', 'Receipt']}
          onSelectIndex={setActive}
          active={active}
        />
        <ButtonShape
          //  we are using styled-components `as` override to use a real link here, with ButtonShape styling
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          as={Link}
          to="customer-info"
          size={Size.Small}
          icon="User"
          data-testid="customer-info-btn"
        />
      </Header>

      {active === ITEMS_TAB && (
        <Row
          gutter={Gutter.XXL}
          style={{height: '100%', marginTop: 20, minHeight: 0}}
          $noWrap
        >
          <Stack style={{flex: 1}}>
            <ScrollContainer>
              {!!prescriptions.length && (
                <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                  <Text
                    variant={TV.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Prescriptions
                  </Text>
                  {prescriptions.map(item => (
                    <RowItem
                      key={item.transactionItemId}
                      title={formatMedication(item.description2 || '')}
                      subtitle={item.receiptDescription || ''}
                      rightIcons={transactionItemIcons(item)}
                      rightText={`$${item.price.toFixed(2)}`}
                      noClick
                      data-testid="rowItem-generic-rx"
                    />
                  ))}
                </Stack>
              )}

              {!!otcItems.length && (
                <Stack gutter={Gutter.S}>
                  <Text
                    variant={TV.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Over the Counter
                  </Text>
                  {otcItems.map(item => (
                    <RowItem
                      key={item.transactionItemId}
                      variant="generic"
                      title={formatMedication(item.description || '')}
                      subtitle={item.itemNumber || ''}
                      rightText={`$${item.price.toFixed(2)}`}
                      rightIcons={transactionItemIcons(item)}
                      quantity={item.quantity.toString()}
                      noClick
                    />
                  ))}
                </Stack>
              )}
            </ScrollContainer>

            <Footer {...totals} />
          </Stack>
        </Row>
      )}

      {active === PAYMENTS_TAB && (
        <>
          <ScrollContainer>
            <CardRow justify="center" align="center" gutter={Gutter.None}>
              {payments.map((p, index) => {
                const {
                  amount,
                  paymentTypeID,
                  createdOn,
                  recordStatus,
                  paymentTypeDesc,
                } = p;
                const isCreditCardVoided =
                  recordStatus === 'Voided' &&
                  getPaymentType(paymentTypeID || 0, paymentTenders) ===
                    'Credit Card';

                return (
                  <PaymentCard
                    key={index}
                    icon={getSimplePaymentIcon(
                      paymentTypeID || 0,
                      paymentTenders,
                    )}
                    color={getPaymentIconType(
                      paymentTypeID || 0,
                      paymentTenders,
                    )}
                    title={
                      isCreditCardVoided
                        ? `Return: -$${amount.toFixed(2)}`
                        : `${
                            getPaymentType(
                              paymentTypeID || 0,
                              paymentTenders,
                            ) == 'UDP'
                              ? paymentTypeDesc
                              : getPaymentType(
                                  paymentTypeID || 0,
                                  paymentTenders,
                                )
                          }: $${amount.toFixed(2)}`
                    }
                    subtitle={displayPaymentNumber(p, paymentTenders)}
                    dateTime={moment(createdOn).format('MM/DD/YYYY - hh:mmA')}
                  />
                );
              })}
            </CardRow>
          </ScrollContainer>

          <Footer {...totals} />
        </>
      )}

      {active === RECEIPT_TAB && (
        <Stack align="center" style={{width: '100%', flex: 1}}>
          <SegmentSlider
            items={activeReceipts}
            onSelectIndex={setReceiptActive}
            active={receiptActive}
          />

          {receiptActive === RECEIPT_EMAIL_TAB && (
            <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
              <Stack
                align="center"
                gutter={Gutter.None}
                style={{flex: 1, marginTop: 36}}
              >
                {serverTransactionID && (
                  <Stack justify="center" align="center" gutter={Gutter.XS}>
                    <Text variant={TV.T2}>{serverTransactionID}</Text>
                    <Text variant={TV.Main}>
                      {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                    </Text>
                  </Stack>
                )}
                <Illustration illustration="NoEmails" />
                <TextInput
                  name="email"
                  label="Email"
                  value={emailReceipt || ''}
                  style={{width: 422}}
                  disabled={true}
                />
              </Stack>
              <FooterGroup>
                <Button flex onClick={onEmailReceipt} disabled={true}>
                  Send Email
                </Button>
              </FooterGroup>
            </Stack>
          )}
          {receiptActive === RECEIPT_TEXT_TAB && (
            <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
              <Stack
                align="center"
                gutter={Gutter.None}
                style={{flex: 1, marginTop: 36}}
              >
                {serverTransactionID && (
                  <Stack justify="center" align="center" gutter={Gutter.XS}>
                    <Text variant={TV.T2}>{serverTransactionID}</Text>
                    <Text variant={TV.Main}>
                      {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                    </Text>
                  </Stack>
                )}
                <Illustration illustration="NoEmails" />
                <TextInput
                  name="phoneNumber"
                  label="Phone Number"
                  value={formattedTextReceipt || ''}
                  style={{width: 422}}
                  disabled={true}
                />
              </Stack>
              <FooterGroup>
                <Button flex onClick={onTextReceipt} disabled={true}>
                  Send Text
                </Button>
              </FooterGroup>
            </Stack>
          )}
          {receiptActive === RECEIPT_PRINTER_TAB && (
            <BetaFeatureSwitch feature="printing">
              {enabled =>
                enabled ? (
                  <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
                    <Stack
                      align="center"
                      gutter={Gutter.L}
                      style={{
                        flex: 1,
                        marginTop: 36,
                      }}
                    >
                      <Illustration illustration="Receipt" />
                      <Select
                        label="Printer"
                        options={['Front Desk']}
                        value=""
                        onChangeValue={() =>
                          logError(
                            'CompletedTransaction - Printed Receipt Not Implemented',
                          )
                        }
                        style={{width: 372, flex: 0, marginTop: 0}}
                      />
                    </Stack>
                    <FooterGroup>
                      <Button flex onClick={onPrintReceipt}>
                        Print Receipt
                      </Button>
                    </FooterGroup>
                  </Stack>
                ) : (
                  <Text>Printing is not supported during Early Access</Text>
                )
              }
            </BetaFeatureSwitch>
          )}
        </Stack>
      )}
    </Stack>
  );
}
