import {memo, useState} from 'react';
import * as React from 'react';
import assert from 'assert';
import {Stack, Text, TextVariant as Variant, theme} from './';

export enum ProgressVariant {
  Primary = 'Primary',
  Invert = 'Invert',
}

/**
 * Props object used when implementing the ProgressBar component
 * @param barWidth An integer used to determine the width of the progress bar - represents a percentage of the parent div
 * @param percentComplete A decimal value that represents the percentage of the progress bar to be filled (ie portion of process that has been completed)
 * @param isError A boolean value indicating if there is an error during loading progress
 * @param onErrorclick A method that allows us to pass in a handler for what should happen when there is an error and the user clicks on the progress bar (launch modal or alert with details)
 * @param containerWidth A nullable string value that allows us to specify the width of the parent container that the bar sits in as any type of css unit (px, rem, %, etc)
 * @param itemType A nullable string value that describes the entity or object being loaded / completed
 */
export interface ProgressBarProps {
  barWidth: number;
  percentComplete: number;
  isError: boolean;
  onErrorClick?: () => void | undefined;
  containerWidth?: string;
  itemType?: string;
  variant?: ProgressVariant;
}

export const ProgressBar = memo(
  ({
    barWidth,
    percentComplete,
    isError = false,
    containerWidth,
    itemType,
    onErrorClick,
    variant,
    ...props
  }: ProgressBarProps &
    React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const [value, setValue] = useState('0');

    React.useEffect(() => {
      assert(
        percentComplete <= 1,
        'Percent completed should not be greater than 100',
      );
      setValue(decimalTopercentComplete(percentComplete));
    }, [percentComplete, barWidth]);

    const decimalTopercentComplete = (percentComplete: number) => {
      return (percentComplete * 100).toFixed(0);
    };

    const progressDiv = {
      backgroundColor: theme.colors.gray_200,
      border: `.1rem solid ${
        variant === ProgressVariant.Invert
          ? theme.colors.white
          : theme.colors.blue_50
      }`,
      borderRadius: '.5rem',
      width: `${barWidth}%`,
    };

    const progress = {
      backgroundColor: `${isError ? theme.colors.error : theme.colors.blue}`,
      height: '.85rem',
      borderRadius: '.5rem',
      transition: '.5s ease',
      width: `${value}%`,
    };

    const container = {
      width: `${containerWidth ? containerWidth : '100%'}`,
      padding: '10px 10px 5px 10px',
      cursor: `${isError ? 'pointer' : 'default'}`,
    };

    const legendStyle = {
      color: `${
        variant === ProgressVariant.Invert
          ? theme.colors.white
          : theme.colors.black
      }`,
      marginTop: '5px',
      padding: '0px 5px 0px 5px',
    };

    return (
      <Stack
        data-testid={'progressBar-container'}
        onClick={() => {
          if (isError && onErrorClick) {
            onErrorClick();
          }
        }}
        align={'center'}
        style={container}
      >
        <div style={progressDiv} data-testid={'progressBar-fullBar'}>
          <div style={progress} data-testid={'progressBar-completed'} />
        </div>
        {!isError && (
          <Text
            variant={Variant.Body}
            style={legendStyle}
            data-testid={'progressBar-legend'}
          >
            {itemType && <span>{itemType}</span>}{' '}
            {decimalTopercentComplete(percentComplete)}% loaded
          </Text>
        )}
        {isError && (
          <Text
            variant={Variant.Body}
            style={legendStyle}
            data-testid={'progressBar-legend'}
          >
            Click bar for details
          </Text>
        )}
      </Stack>
    );
  },
);
