/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentVoidResponse
 */
export interface PaymentVoidResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidResponse
     */
    authorizationCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidResponse
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidResponse
     */
    rawResponse: string;
}

export function PaymentVoidResponseFromJSON(json: any): PaymentVoidResponse {
    return PaymentVoidResponseFromJSONTyped(json, false);
}

export function PaymentVoidResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentVoidResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationCode': json['authorizationCode'],
        'status': json['status'],
        'referenceNumber': json['referenceNumber'],
        'message': json['message'],
        'rawResponse': json['rawResponse'],
    };
}

export function PaymentVoidResponseToJSON(value?: PaymentVoidResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationCode': value.authorizationCode,
        'status': value.status,
        'referenceNumber': value.referenceNumber,
        'message': value.message,
        'rawResponse': value.rawResponse,
    };
}


