import styled from 'styled-components';
import {DividerLine, Gutter, Row, Stack} from './';

const FooterWrapper = styled(Stack)`
  background-color: ${({theme}) => theme.colors.white};
  margin-top: 0;
`;

export const FooterGroup = ({children}: {children?: React.ReactNode}) => {
  return (
    <FooterWrapper>
      <DividerLine />
      <Row gutter={Gutter.XL} $noWrap>
        {children}
      </Row>
    </FooterWrapper>
  );
};
