/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PseIdentification
 */
export interface PseIdentification {
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    idNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    dob?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    idExpirationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PseIdentification
     */
    idTypeID: number;
    /**
     * 
     * @type {string}
     * @memberof PseIdentification
     */
    idTypeCode?: string | null;
}

export function PseIdentificationFromJSON(json: any): PseIdentification {
    return PseIdentificationFromJSONTyped(json, false);
}

export function PseIdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseIdentification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'idExpirationDate': !exists(json, 'idExpirationDate') ? undefined : json['idExpirationDate'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'idTypeID': json['idTypeID'],
        'idTypeCode': !exists(json, 'idTypeCode') ? undefined : json['idTypeCode'],
    };
}

export function PseIdentificationToJSON(value?: PseIdentification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idNumber': value.idNumber,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'dob': value.dob,
        'gender': value.gender,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'idExpirationDate': value.idExpirationDate,
        'note': value.note,
        'idTypeID': value.idTypeID,
        'idTypeCode': value.idTypeCode,
    };
}


