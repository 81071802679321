/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SessionStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function SessionStatusEnumFromJSON(json: any): SessionStatusEnum {
    return SessionStatusEnumFromJSONTyped(json, false);
}

export function SessionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionStatusEnum {
    return json as SessionStatusEnum;
}

export function SessionStatusEnumToJSON(value?: SessionStatusEnum | null): any {
    return value as any;
}

