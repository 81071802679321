/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CashDrawer,
    CashDrawerFromJSON,
    CashDrawerFromJSONTyped,
    CashDrawerToJSON,
    SessionStatusEnum,
    SessionStatusEnumFromJSON,
    SessionStatusEnumFromJSONTyped,
    SessionStatusEnumToJSON,
    Site,
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
    Station,
    StationFromJSON,
    StationFromJSONTyped,
    StationToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    drawerTransactionId: number;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    drawerDate: Date;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    tillId: number;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    startingCashBalance: number;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof Session
     */
    transactions: Array<Transaction>;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    dataVersion?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    siteId: number;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    stationId: number;
    /**
     * 
     * @type {SessionStatusEnum}
     * @memberof Session
     */
    status: SessionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    hostedPaymentsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    paymentDeviceAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    paymentDevicePort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    accessCode: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    deviceId: string;
    /**
     * 
     * @type {CashDrawer}
     * @memberof Session
     */
    till: CashDrawer;
    /**
     * 
     * @type {Station}
     * @memberof Session
     */
    station: Station;
    /**
     * 
     * @type {Site}
     * @memberof Session
     */
    site: Site;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'userId': json['userId'],
        'drawerTransactionId': json['drawerTransactionId'],
        'drawerDate': (new Date(json['drawerDate'])),
        'tillId': json['tillId'],
        'startingCashBalance': json['startingCashBalance'],
        'transactions': ((json['transactions'] as Array<any>).map(TransactionFromJSON)),
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'siteId': json['siteId'],
        'stationId': json['stationId'],
        'status': SessionStatusEnumFromJSON(json['status']),
        'hostedPaymentsEnabled': json['hostedPaymentsEnabled'],
        'paymentDeviceAddress': !exists(json, 'paymentDeviceAddress') ? undefined : json['paymentDeviceAddress'],
        'paymentDevicePort': !exists(json, 'paymentDevicePort') ? undefined : json['paymentDevicePort'],
        'accessCode': json['accessCode'],
        'deviceId': json['deviceId'],
        'till': CashDrawerFromJSON(json['till']),
        'station': StationFromJSON(json['station']),
        'site': SiteFromJSON(json['site']),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'userId': value.userId,
        'drawerTransactionId': value.drawerTransactionId,
        'drawerDate': (value.drawerDate.toISOString()),
        'tillId': value.tillId,
        'startingCashBalance': value.startingCashBalance,
        'transactions': ((value.transactions as Array<any>).map(TransactionToJSON)),
        'dataVersion': value.dataVersion,
        'siteId': value.siteId,
        'stationId': value.stationId,
        'status': SessionStatusEnumToJSON(value.status),
        'hostedPaymentsEnabled': value.hostedPaymentsEnabled,
        'paymentDeviceAddress': value.paymentDeviceAddress,
        'paymentDevicePort': value.paymentDevicePort,
        'accessCode': value.accessCode,
        'deviceId': value.deviceId,
        'till': CashDrawerToJSON(value.till),
        'station': StationToJSON(value.station),
        'site': SiteToJSON(value.site),
    };
}


