/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentOptionResultListApiResponse,
    PaymentOptionResultListApiResponseFromJSON,
    PaymentOptionResultListApiResponseToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ClientPaymentOptionsGetRequest {
    siteId?: number;
}

/**
 * 
 */
export class PaymentOptionsApi extends runtime.BaseAPI {

    /**
     * Gets payment options for a site.
     */
    async clientPaymentOptionsGetRaw(requestParameters: ClientPaymentOptionsGetRequest): Promise<runtime.ApiResponse<PaymentOptionResultListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentOptionResultListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets payment options for a site.
     */
    async clientPaymentOptionsGet(requestParameters: ClientPaymentOptionsGetRequest): Promise<PaymentOptionResultListApiResponse> {
        const response = await this.clientPaymentOptionsGetRaw(requestParameters);
        return await response.value();
    }

}
