import {CustomerSearchMatch} from '@emporos/api-enterprise';
import {Redirect, RouteComponentProps} from '@reach/router';
import {memo, useCallback, useEffect} from 'react';
import {
  activeTransactionCustomersCodes,
  mapTransactionCustomer,
  SearchParams,
  useCustomerSearch,
  useCustomerWillCall,
  useSessionData,
  useNetworkAvailable,
  useSession,
  useTransactionsState,
  withChildPage,
} from '../../../';
import {useApi} from '../../../contexts/ApiProvider';
import {CustomerSearch, parseDateNoTime} from './';

interface Props {
  addToAr?: boolean;
  disableWillCall?: boolean;
  variant?: 'customer' | 'account';
}

function AddCustomerComponent(props: Props & RouteComponentProps) {
  const api = useApi();
  const {setCustomer} = useCustomerSearch();
  const {online} = useNetworkAvailable();
  const {session, currentTransactionId} = useTransactionsState();
  const {pendingTransactions} = useSession();
  const {pmsName, setSkipWillCall} = useSessionData();
  const {addToAr, disableWillCall, navigate, variant} = props;
  const {siteId, stationId} = session;

  if (!currentTransactionId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {run: postSearchCustomers} = api.PostSearchCustomers();
  const {run: getCustomerById} = api.GetCustomer();
  const {run: patchCustomerByCode} = api.PatchCustomer();
  const {run: putWillCall, customer} = useCustomerWillCall();
  const onSearch = useCallback(
    async ({lastName, firstName, dob, mrn}: SearchParams) => {
      return postSearchCustomers({
        siteId: siteId,
        stationId: stationId,
        customerSearchCriteria: {
          lastName: lastName || undefined,
          firstName: firstName || undefined,
          dob: dob ? parseDateNoTime(dob) : undefined,
          mrn: mrn || undefined,
        },
      });
    },
    [postSearchCustomers, siteId, stationId],
  );
  const onCancel = useCallback(() => navigate && navigate('../'), [navigate]);
  const onWillCallFailed = useCallback(
    async ({id}: CustomerSearchMatch) => {
      const customerInfo = await getCustomerById({
        customerId: id,
      });
      if (customerInfo?.data) {
        setCustomer(mapTransactionCustomer(customerInfo.data));
        navigate &&
          navigate('../', {
            replace: true,
          });
      }
    },
    [getCustomerById],
  );
  const skipWillCallCustomer = useCallback(
    async (customer: CustomerSearchMatch, skipWillCall: boolean) => {
      const customerInfo =
        customer.id && customer.id > 0
          ? await getCustomerById({
              customerId: customer.id,
            })
          : await patchCustomerByCode({
              // customerCode won't never be null here.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              customerCode: customer.customerCode!,
              siteId: session.siteId,
              stationId: session.stationId,
            });
      if (customerInfo?.data) {
        setCustomer(mapTransactionCustomer(customerInfo.data));
        setSkipWillCall(skipWillCall);
        navigate &&
          navigate('../', {
            replace: true,
          });
      }
    },
    [getCustomerById, patchCustomerByCode],
  );
  const tryLoadCustomer = useCallback(
    async (match: CustomerSearchMatch, skipWillCall: boolean) => {
      if (!match.customerCode && !match.mrn) {
        throw new Error(
          'Failed to load customer: customer code is required to perform will call',
        );
      }
      if (skipWillCall) {
        return skipWillCallCustomer(match, skipWillCall);
      } else {
        return putWillCall({
          customerCode: match.customerCode,
          mrn: match.mrn,
        }).catch(() => onWillCallFailed(match));
      }
    },
    [putWillCall],
  );

  useEffect(() => {
    if (customer) {
      setCustomer(customer);
      navigate &&
        navigate('../', {
          replace: true,
        });
    }
  }, [customer]);

  return (
    <CustomerSearch
      addToAr={addToAr}
      disableWillCall={disableWillCall}
      onSearch={onSearch}
      onAddCustomer={tryLoadCustomer}
      onCancel={onCancel}
      online={online}
      activeTransactionCustomersCodes={activeTransactionCustomersCodes(
        pendingTransactions,
      )}
      pmsName={pmsName}
      variant={variant}
    />
  );
}

export const AddCustomer = memo(withChildPage(AddCustomerComponent));
