/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PseCheckResponseBody,
    PseCheckResponseBodyFromJSON,
    PseCheckResponseBodyFromJSONTyped,
    PseCheckResponseBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PseCheckResponse
 */
export interface PseCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof PseCheckResponse
     */
    message?: string | null;
    /**
     * 
     * @type {PseCheckResponseBody}
     * @memberof PseCheckResponse
     */
    result: PseCheckResponseBody;
}

export function PseCheckResponseFromJSON(json: any): PseCheckResponse {
    return PseCheckResponseFromJSONTyped(json, false);
}

export function PseCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'result': PseCheckResponseBodyFromJSON(json['result']),
    };
}

export function PseCheckResponseToJSON(value?: PseCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'result': PseCheckResponseBodyToJSON(value.result),
    };
}


