/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    Session,
    SessionFromJSON,
    SessionToJSON,
} from '../models';

export interface ClientCacheSessionsMyGetRequest {
    includeChildren?: boolean;
}

export interface ClientCacheSessionsPostRequest {
    siteId?: number;
    stationId?: number;
    tillId?: number;
    startingCashBalance?: number;
    paymentDeviceAddress?: string;
    paymentDevicePort?: string;
    accessCode?: string;
    deviceId?: string;
}

export interface ClientCacheSessionsSessionIdClosePostRequest {
    sessionId: string;
}

export interface ClientCacheSessionsSessionIdGetRequest {
    sessionId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest {
    sessionId: string;
    paymentDeviceAddress?: string;
}

/**
 * 
 */
export class SessionsApi extends runtime.BaseAPI {

    /**
     * If successful, the session and all associeted children will be removed from the session cache data store.
     * Attempts to close the current user\'s session
     */
    async clientCacheSessionsMyClosePostRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/my/close`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * If successful, the session and all associeted children will be removed from the session cache data store.
     * Attempts to close the current user\'s session
     */
    async clientCacheSessionsMyClosePost(): Promise<void> {
        await this.clientCacheSessionsMyClosePostRaw();
    }

    /**
     * Gets the active session for current logged-in user
     */
    async clientCacheSessionsMyGetRaw(requestParameters: ClientCacheSessionsMyGetRequest): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     * Gets the active session for current logged-in user
     */
    async clientCacheSessionsMyGet(requestParameters: ClientCacheSessionsMyGetRequest): Promise<Array<Session>> {
        const response = await this.clientCacheSessionsMyGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates and starts a session for the current user
     */
    async clientCacheSessionsPostRaw(requestParameters: ClientCacheSessionsPostRequest): Promise<runtime.ApiResponse<Session>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        if (requestParameters.tillId !== undefined) {
            queryParameters['tillId'] = requestParameters.tillId;
        }

        if (requestParameters.startingCashBalance !== undefined) {
            queryParameters['startingCashBalance'] = requestParameters.startingCashBalance;
        }

        if (requestParameters.paymentDeviceAddress !== undefined) {
            queryParameters['paymentDeviceAddress'] = requestParameters.paymentDeviceAddress;
        }

        if (requestParameters.paymentDevicePort !== undefined) {
            queryParameters['paymentDevicePort'] = requestParameters.paymentDevicePort;
        }

        if (requestParameters.accessCode !== undefined) {
            queryParameters['accessCode'] = requestParameters.accessCode;
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Creates and starts a session for the current user
     */
    async clientCacheSessionsPost(requestParameters: ClientCacheSessionsPostRequest): Promise<Session> {
        const response = await this.clientCacheSessionsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * If successful, the session and all associeted children will be removed from the session cache data store.
     * Attempts to close the specified session
     */
    async clientCacheSessionsSessionIdClosePostRaw(requestParameters: ClientCacheSessionsSessionIdClosePostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdClosePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/close`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * If successful, the session and all associeted children will be removed from the session cache data store.
     * Attempts to close the specified session
     */
    async clientCacheSessionsSessionIdClosePost(requestParameters: ClientCacheSessionsSessionIdClosePostRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdClosePostRaw(requestParameters);
    }

    /**
     * Gets the active session for current logged-in user
     */
    async clientCacheSessionsSessionIdGetRaw(requestParameters: ClientCacheSessionsSessionIdGetRequest): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Gets the active session for current logged-in user
     */
    async clientCacheSessionsSessionIdGet(requestParameters: ClientCacheSessionsSessionIdGetRequest): Promise<Session> {
        const response = await this.clientCacheSessionsSessionIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the PaymentDeviceAddress for the current user\'s session.
     */
    async clientCacheSessionsSessionIdPaymentDeviceAddressPutRaw(requestParameters: ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdPaymentDeviceAddressPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.paymentDeviceAddress !== undefined) {
            queryParameters['paymentDeviceAddress'] = requestParameters.paymentDeviceAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/paymentDeviceAddress`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Updates the PaymentDeviceAddress for the current user\'s session.
     */
    async clientCacheSessionsSessionIdPaymentDeviceAddressPut(requestParameters: ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest): Promise<Session> {
        const response = await this.clientCacheSessionsSessionIdPaymentDeviceAddressPutRaw(requestParameters);
        return await response.value();
    }

}
