/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPhone
 */
export interface CustomerPhone {
    /**
     * 
     * @type {string}
     * @memberof CustomerPhone
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerPhone
     */
    capabilities: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPhone
     */
    isPrimary: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerPhone
     */
    number?: string | null;
}

export function CustomerPhoneFromJSON(json: any): CustomerPhone {
    return CustomerPhoneFromJSONTyped(json, false);
}

export function CustomerPhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPhone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'capabilities': json['capabilities'],
        'isPrimary': json['isPrimary'],
        'number': !exists(json, 'number') ? undefined : json['number'],
    };
}

export function CustomerPhoneToJSON(value?: CustomerPhone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'capabilities': value.capabilities,
        'isPrimary': value.isPrimary,
        'number': value.number,
    };
}


