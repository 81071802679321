import {
  Transaction,
  TransactionExtra,
  Relationship,
  Setting,
} from '@emporos/api-enterprise';
import {
  Button,
  ButtonShape,
  ButtonSize as Size,
  FooterGroup,
  Gutter,
  Header,
  Row,
  SegmentSlider,
  Select,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
} from '@emporos/components';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import {memo, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {
  COMPLIANCE_DATA_KEY,
  formatCustomerName,
  mapIdCheckIndicators,
} from '../../../../../';
import {
  ComplianceForm,
  COUNSEL_LATER,
  COUNSELING_LINE1,
  EMPTY_COMPLIANCE,
  HIPAA_LINE1,
  mapComplianceIndicators,
  NavigateTo,
  NO_COUNSELING,
  NO_INDEX,
} from './';

const mapExtrasToCompliance = (
  extras: Array<TransactionExtra>,
): ComplianceForm => {
  const compliance = extras.find(
    extra => extra.dataKey === COMPLIANCE_DATA_KEY,
  );
  return compliance
    ? JSON.parse(compliance.dataValue || '{}')
    : EMPTY_COMPLIANCE;
};

export interface ComplianceProps {
  transaction: Transaction;
  defaultAnimatePanel: boolean;
  settings: Setting[];
  relationships: Relationship[];
  navigate: NavigateFn;
  onBack(navigateTo: NavigateTo): void;
  onSubmit(form: ComplianceForm): void;
}

const ComplianceChild = ({
  transaction,
  defaultAnimatePanel,
  settings,
  relationships,
  navigate,
  onBack,
  onSubmit,
}: ComplianceProps): JSX.Element => {
  const [animatePanel, setAnimatePanel] = useState(defaultAnimatePanel);
  const {customer, extras} = transaction;
  const defaultValues = mapExtrasToCompliance(extras);
  const {showCounsel, showCounselRequired, showHipaa, showRelationship} =
    mapComplianceIndicators(transaction, settings);

  /**
   * this seems to trigger the sliding animation for the panel
   * on the first render
   */
  useEffect(() => {
    if (!animatePanel) {
      setAnimatePanel(true);
    }
  }, []);

  if (showCounselRequired && !defaultValues.counseling) {
    defaultValues.counseling = COUNSEL_LATER;
  }

  if (showHipaa && !defaultValues.hipaa) {
    defaultValues.hipaa = NO_INDEX;
  }

  const schema = yup.object().shape({
    counseling: showCounsel
      ? yup.string().required()
      : yup.string().notRequired(),
    hipaa: showHipaa ? yup.number().required() : yup.number().notRequired(),
    relationship: showRelationship
      ? yup.string().required()
      : yup.string().notRequired(),
  });

  const {handleSubmit, control, formState, register} = useForm<ComplianceForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <>
      <Stack
        data-testid="compliance-form"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title="Compliance">
          <ButtonShape
            type="button"
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigate('customer-info')}
          />
        </Header>

        <Stack gutter={Gutter.XXL} style={{flex: 1}}>
          {showCounsel && (
            <Row justify="space-between" align="center" $noWrap>
              <Text variant={TV.Main}>
                {(
                  settings.find(s => s.name === COUNSELING_LINE1)?.value || ''
                ).replace('~True', '')}
              </Text>
              <Controller
                name="counseling"
                control={control}
                render={({field}) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onChangeValue={field.onChange}
                    label="Counseling"
                    options={[COUNSEL_LATER, NO_COUNSELING]}
                    optionsText={['Counsel Later', 'No Counseling']}
                    disabled={showCounselRequired}
                    style={{flex: '0 0 278px'}}
                  />
                )}
              />
            </Row>
          )}

          {showRelationship && customer && (
            <Row justify="space-between" align="center" $noWrap>
              <Text variant={TV.Main}>
                What is your relationship to {formatCustomerName(customer)}?
              </Text>
              <Controller
                name="relationship"
                control={control}
                render={({field}) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onChangeValue={field.onChange}
                    label="Relationship"
                    options={relationships.map(({name: n}) => n)}
                    optionsText={relationships.map(({name: n}) => n)}
                    style={{flex: '0 0 278px'}}
                  />
                )}
              />
            </Row>
          )}

          {showHipaa && (
            <Row
              justify="space-between"
              align="center"
              gutter={Gutter.None}
              $noWrap
            >
              <Controller
                name="hipaa"
                control={control}
                render={({field}) => (
                  <>
                    <input
                      {...register('hipaa')}
                      type="hidden"
                      name="hipaa"
                      value={field.value as number}
                    />
                    <Text variant={TV.Main}>
                      {(
                        settings.find(s => s.name === HIPAA_LINE1)?.value || ''
                      ).replace('~True', '')}
                    </Text>
                    <SegmentSlider
                      items={['Yes', 'No']}
                      active={field.value as number}
                      onSelectIndex={field.onChange}
                    />
                  </>
                )}
              />
            </Row>
          )}
        </Stack>

        <FooterGroup>
          <Button
            type="button"
            variant={BV.Secondary}
            flex
            onClick={() => {
              const {isNplex, isControlledSubstance, isRequiredAge} =
                mapIdCheckIndicators(transaction);
              const navigateTo =
                (isNplex && transaction.pseCheckResult !== 1) ||
                isControlledSubstance ||
                isRequiredAge
                  ? NavigateTo.IDCheck
                  : NavigateTo.Transactions;
              onBack(navigateTo);
              setAnimatePanel(false);
            }}
          >
            Back
          </Button>
          <Button
            data-testid="confirm-button"
            flex
            type="submit"
            disabled={!formState.isValid}
          >
            Confirm
          </Button>
        </FooterGroup>
      </Stack>
    </>
  );
};

export const Compliance = memo(ComplianceChild);
