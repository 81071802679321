/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentDetailRequest,
    PaymentDetailRequestFromJSON,
    PaymentDetailRequestToJSON,
    PaymentInitiateResponseApiResponse,
    PaymentInitiateResponseApiResponseFromJSON,
    PaymentInitiateResponseApiResponseToJSON,
    PaymentProcessorRequest,
    PaymentProcessorRequestFromJSON,
    PaymentProcessorRequestToJSON,
    PaymentProcessorResponseApiResponse,
    PaymentProcessorResponseApiResponseFromJSON,
    PaymentProcessorResponseApiResponseToJSON,
    PaymentVaultRequest,
    PaymentVaultRequestFromJSON,
    PaymentVaultRequestToJSON,
    PaymentVoidRequest10,
    PaymentVoidRequest10FromJSON,
    PaymentVoidRequest10ToJSON,
    PaymentVoidResponseApiResponse,
    PaymentVoidResponseApiResponseFromJSON,
    PaymentVoidResponseApiResponseToJSON,
} from '../models';

export interface ClientPaymentDetailSiteIdPostRequest {
    siteId: number;
    paymentDetailRequest?: PaymentDetailRequest;
}

export interface ClientPaymentInitSiteIdPostRequest {
    siteId: number;
    paymentProcessorRequest?: PaymentProcessorRequest;
}

export interface ClientPaymentVaultSiteIdPostRequest {
    siteId: number;
    paymentVaultRequest?: PaymentVaultRequest;
}

export interface ClientPaymentVoidSiteIdPostRequest {
    siteId: number;
    paymentVoidRequest10?: PaymentVoidRequest10;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Get the Payment Details
     */
    async clientPaymentDetailSiteIdPostRaw(requestParameters: ClientPaymentDetailSiteIdPostRequest): Promise<runtime.ApiResponse<PaymentProcessorResponseApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientPaymentDetailSiteIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment/detail/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentDetailRequestToJSON(requestParameters.paymentDetailRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentProcessorResponseApiResponseFromJSON(jsonValue));
    }

    /**
     * Get the Payment Details
     */
    async clientPaymentDetailSiteIdPost(requestParameters: ClientPaymentDetailSiteIdPostRequest): Promise<PaymentProcessorResponseApiResponse> {
        const response = await this.clientPaymentDetailSiteIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets Payment Tenders.
     */
    async clientPaymentInitSiteIdPostRaw(requestParameters: ClientPaymentInitSiteIdPostRequest): Promise<runtime.ApiResponse<PaymentInitiateResponseApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientPaymentInitSiteIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment/init/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentProcessorRequestToJSON(requestParameters.paymentProcessorRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentInitiateResponseApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Payment Tenders.
     */
    async clientPaymentInitSiteIdPost(requestParameters: ClientPaymentInitSiteIdPostRequest): Promise<PaymentInitiateResponseApiResponse> {
        const response = await this.clientPaymentInitSiteIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Processes Payment with a vault token
     */
    async clientPaymentVaultSiteIdPostRaw(requestParameters: ClientPaymentVaultSiteIdPostRequest): Promise<runtime.ApiResponse<PaymentProcessorResponseApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientPaymentVaultSiteIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment/vault/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentVaultRequestToJSON(requestParameters.paymentVaultRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentProcessorResponseApiResponseFromJSON(jsonValue));
    }

    /**
     * Processes Payment with a vault token
     */
    async clientPaymentVaultSiteIdPost(requestParameters: ClientPaymentVaultSiteIdPostRequest): Promise<PaymentProcessorResponseApiResponse> {
        const response = await this.clientPaymentVaultSiteIdPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Voids a Payment
     */
    async clientPaymentVoidSiteIdPostRaw(requestParameters: ClientPaymentVoidSiteIdPostRequest): Promise<runtime.ApiResponse<PaymentVoidResponseApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientPaymentVoidSiteIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/payment/void/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentVoidRequest10ToJSON(requestParameters.paymentVoidRequest10),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentVoidResponseApiResponseFromJSON(jsonValue));
    }

    /**
     * Voids a Payment
     */
    async clientPaymentVoidSiteIdPost(requestParameters: ClientPaymentVoidSiteIdPostRequest): Promise<PaymentVoidResponseApiResponse> {
        const response = await this.clientPaymentVoidSiteIdPostRaw(requestParameters);
        return await response.value();
    }

}
