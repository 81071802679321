/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionItemRequest
 */
export interface TransactionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    transactionItemId: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    serverDetailID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    serverTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    itemID: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    itemNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    rx?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    fill?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    partial?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    site?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    rxCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    cost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    listPrice: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    itemListPrice: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    saleType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    itemTypeID: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    discountPercent: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    extension: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    quantityReturned?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    qhpIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    qhpPrivateIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    taxableIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    taxGroupID: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    priceChangeIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    discountableIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    noSafetyCapIndicator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    proofIDIndicator?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    unitOfMeasure?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    saleUnitOfMeasure: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    counselRequiredIndicator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    counselPromptIndicator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    captureRelationshipIndicator?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    hipaaCompliant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    hipaaRelationship?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    departmentID: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    controlGrams: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionItemRequest
     */
    adjudicationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    inventoryGroupID: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    updateInventoryIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    relatedDetailID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    familyDetailID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    parentDetailID?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    kitIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    saleUnitOfMeasureID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    unitOfMeasureRatio?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    serialNumberIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    rentalIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    rentalPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    rentalQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    timeUnitOfMeasureID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    employeeID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    pricingMethod?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    extensionBeforeDiscount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    relatedDetailID2?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    saleIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    cashDisbursementReasonID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    discountReasonID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    returnReasonID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    vendor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    priceChangeReasonID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    originalPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    type2BarcodeIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    weighedItemIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    departmentKeyIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    attachmentIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    priceQuantity?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    manualWeighedIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    commitStockIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    priceLevelID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    eventCodeID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    priceGroupID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    displayPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    vendorID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    detailReference?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    negativeInventoryIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    createItemNumber?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    directShipmentIndicator: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    description2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    discountType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    itemCrossReference?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    signatureIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    rxCustomerID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    categoryID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    subCategoryID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    deaClass?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TransactionItemRequest
     */
    adjInsDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    plan1?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    planAmount1?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    plan2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    planAmount2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    plan3?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    planAmount3?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    plan4?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    planAmount4?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    plan5?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    planAmount5?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    prescriptionID?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    ebtIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    wicIndicator: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    rxRelationship?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    pickupAllEmployeeID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    customerMRN?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    rxBarcodeScanData?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    controlledSubstanceIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    counselLater?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    counselorID?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    counselIndicator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    offerCounsel?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    recordStatus?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    dispensable: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionItemRequest
     */
    receiptDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    medicationStatus?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    requiresHIPAACompliance: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionItemRequest
     */
    requiredAge?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionItemRequest
     */
    discontinuedIndicator: boolean;
}

export function TransactionItemRequestFromJSON(json: any): TransactionItemRequest {
    return TransactionItemRequestFromJSONTyped(json, false);
}

export function TransactionItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionItemId': json['transactionItemId'],
        'serverDetailID': !exists(json, 'serverDetailID') ? undefined : json['serverDetailID'],
        'serverTransactionID': !exists(json, 'serverTransactionID') ? undefined : json['serverTransactionID'],
        'itemID': json['itemID'],
        'itemNumber': !exists(json, 'itemNumber') ? undefined : json['itemNumber'],
        'rx': !exists(json, 'rx') ? undefined : json['rx'],
        'fill': !exists(json, 'fill') ? undefined : json['fill'],
        'partial': !exists(json, 'partial') ? undefined : json['partial'],
        'site': !exists(json, 'site') ? undefined : json['site'],
        'rxCode': !exists(json, 'rxCode') ? undefined : json['rxCode'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'quantity': json['quantity'],
        'listPrice': json['listPrice'],
        'price': json['price'],
        'itemListPrice': json['itemListPrice'],
        'saleType': !exists(json, 'saleType') ? undefined : json['saleType'],
        'itemTypeID': json['itemTypeID'],
        'discountPercent': json['discountPercent'],
        'discount': json['discount'],
        'extension': json['extension'],
        'quantityReturned': !exists(json, 'quantityReturned') ? undefined : json['quantityReturned'],
        'qhpIndicator': json['qhpIndicator'],
        'qhpPrivateIndicator': json['qhpPrivateIndicator'],
        'taxableIndicator': json['taxableIndicator'],
        'taxGroupID': json['taxGroupID'],
        'priceChangeIndicator': json['priceChangeIndicator'],
        'discountableIndicator': json['discountableIndicator'],
        'noSafetyCapIndicator': !exists(json, 'noSafetyCapIndicator') ? undefined : json['noSafetyCapIndicator'],
        'proofIDIndicator': !exists(json, 'proofIDIndicator') ? undefined : json['proofIDIndicator'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'saleUnitOfMeasure': json['saleUnitOfMeasure'],
        'counselRequiredIndicator': !exists(json, 'counselRequiredIndicator') ? undefined : json['counselRequiredIndicator'],
        'counselPromptIndicator': !exists(json, 'counselPromptIndicator') ? undefined : json['counselPromptIndicator'],
        'captureRelationshipIndicator': !exists(json, 'captureRelationshipIndicator') ? undefined : json['captureRelationshipIndicator'],
        'hipaaCompliant': !exists(json, 'hipaaCompliant') ? undefined : json['hipaaCompliant'],
        'hipaaRelationship': !exists(json, 'hipaaRelationship') ? undefined : json['hipaaRelationship'],
        'departmentID': json['departmentID'],
        'controlGrams': json['controlGrams'],
        'adjudicationDate': !exists(json, 'adjudicationDate') ? undefined : (json['adjudicationDate'] === null ? null : new Date(json['adjudicationDate'])),
        'inventoryGroupID': json['inventoryGroupID'],
        'updateInventoryIndicator': json['updateInventoryIndicator'],
        'relatedDetailID': !exists(json, 'relatedDetailID') ? undefined : json['relatedDetailID'],
        'familyDetailID': !exists(json, 'familyDetailID') ? undefined : json['familyDetailID'],
        'parentDetailID': !exists(json, 'parentDetailID') ? undefined : json['parentDetailID'],
        'kitIndicator': json['kitIndicator'],
        'saleUnitOfMeasureID': !exists(json, 'saleUnitOfMeasureID') ? undefined : json['saleUnitOfMeasureID'],
        'unitOfMeasureRatio': !exists(json, 'unitOfMeasureRatio') ? undefined : json['unitOfMeasureRatio'],
        'serialNumberIndicator': json['serialNumberIndicator'],
        'rentalIndicator': json['rentalIndicator'],
        'rentalPrice': !exists(json, 'rentalPrice') ? undefined : json['rentalPrice'],
        'rentalQuantity': !exists(json, 'rentalQuantity') ? undefined : json['rentalQuantity'],
        'timeUnitOfMeasureID': !exists(json, 'timeUnitOfMeasureID') ? undefined : json['timeUnitOfMeasureID'],
        'employeeID': !exists(json, 'employeeID') ? undefined : json['employeeID'],
        'pricingMethod': !exists(json, 'pricingMethod') ? undefined : json['pricingMethod'],
        'extensionBeforeDiscount': !exists(json, 'extensionBeforeDiscount') ? undefined : json['extensionBeforeDiscount'],
        'relatedDetailID2': !exists(json, 'relatedDetailID2') ? undefined : json['relatedDetailID2'],
        'saleIndicator': json['saleIndicator'],
        'cashDisbursementReasonID': !exists(json, 'cashDisbursementReasonID') ? undefined : json['cashDisbursementReasonID'],
        'discountReasonID': !exists(json, 'discountReasonID') ? undefined : json['discountReasonID'],
        'returnReasonID': !exists(json, 'returnReasonID') ? undefined : json['returnReasonID'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'priceChangeReasonID': !exists(json, 'priceChangeReasonID') ? undefined : json['priceChangeReasonID'],
        'originalPrice': !exists(json, 'originalPrice') ? undefined : json['originalPrice'],
        'type2BarcodeIndicator': json['type2BarcodeIndicator'],
        'weighedItemIndicator': json['weighedItemIndicator'],
        'departmentKeyIndicator': json['departmentKeyIndicator'],
        'attachmentIndicator': json['attachmentIndicator'],
        'priceQuantity': !exists(json, 'priceQuantity') ? undefined : json['priceQuantity'],
        'manualWeighedIndicator': json['manualWeighedIndicator'],
        'commitStockIndicator': json['commitStockIndicator'],
        'priceLevelID': !exists(json, 'priceLevelID') ? undefined : json['priceLevelID'],
        'eventCodeID': !exists(json, 'eventCodeID') ? undefined : json['eventCodeID'],
        'priceGroupID': !exists(json, 'priceGroupID') ? undefined : json['priceGroupID'],
        'displayPrice': !exists(json, 'displayPrice') ? undefined : json['displayPrice'],
        'vendorID': !exists(json, 'vendorID') ? undefined : json['vendorID'],
        'detailReference': !exists(json, 'detailReference') ? undefined : json['detailReference'],
        'negativeInventoryIndicator': json['negativeInventoryIndicator'],
        'createItemNumber': !exists(json, 'createItemNumber') ? undefined : json['createItemNumber'],
        'directShipmentIndicator': json['directShipmentIndicator'],
        'description2': !exists(json, 'description2') ? undefined : json['description2'],
        'discountType': !exists(json, 'discountType') ? undefined : json['discountType'],
        'itemCrossReference': !exists(json, 'itemCrossReference') ? undefined : json['itemCrossReference'],
        'signatureIndicator': json['signatureIndicator'],
        'rxCustomerID': !exists(json, 'rxCustomerID') ? undefined : json['rxCustomerID'],
        'categoryID': !exists(json, 'categoryID') ? undefined : json['categoryID'],
        'subCategoryID': !exists(json, 'subCategoryID') ? undefined : json['subCategoryID'],
        'deaClass': !exists(json, 'deaClass') ? undefined : json['deaClass'],
        'adjInsDate': !exists(json, 'adjInsDate') ? undefined : (json['adjInsDate'] === null ? null : new Date(json['adjInsDate'])),
        'plan1': !exists(json, 'plan1') ? undefined : json['plan1'],
        'planAmount1': !exists(json, 'planAmount1') ? undefined : json['planAmount1'],
        'plan2': !exists(json, 'plan2') ? undefined : json['plan2'],
        'planAmount2': !exists(json, 'planAmount2') ? undefined : json['planAmount2'],
        'plan3': !exists(json, 'plan3') ? undefined : json['plan3'],
        'planAmount3': !exists(json, 'planAmount3') ? undefined : json['planAmount3'],
        'plan4': !exists(json, 'plan4') ? undefined : json['plan4'],
        'planAmount4': !exists(json, 'planAmount4') ? undefined : json['planAmount4'],
        'plan5': !exists(json, 'plan5') ? undefined : json['plan5'],
        'planAmount5': !exists(json, 'planAmount5') ? undefined : json['planAmount5'],
        'prescriptionID': !exists(json, 'prescriptionID') ? undefined : json['prescriptionID'],
        'ebtIndicator': json['ebtIndicator'],
        'wicIndicator': json['wicIndicator'],
        'rxRelationship': !exists(json, 'rxRelationship') ? undefined : json['rxRelationship'],
        'pickupAllEmployeeID': !exists(json, 'pickupAllEmployeeID') ? undefined : json['pickupAllEmployeeID'],
        'customerMRN': !exists(json, 'customerMRN') ? undefined : json['customerMRN'],
        'rxBarcodeScanData': !exists(json, 'rxBarcodeScanData') ? undefined : json['rxBarcodeScanData'],
        'controlledSubstanceIndicator': json['controlledSubstanceIndicator'],
        'counselLater': !exists(json, 'counselLater') ? undefined : json['counselLater'],
        'counselorID': !exists(json, 'counselorID') ? undefined : json['counselorID'],
        'counselIndicator': !exists(json, 'counselIndicator') ? undefined : json['counselIndicator'],
        'offerCounsel': !exists(json, 'offerCounsel') ? undefined : json['offerCounsel'],
        'recordStatus': !exists(json, 'recordStatus') ? undefined : json['recordStatus'],
        'dispensable': json['dispensable'],
        'receiptDescription': !exists(json, 'receiptDescription') ? undefined : json['receiptDescription'],
        'medicationStatus': !exists(json, 'medicationStatus') ? undefined : json['medicationStatus'],
        'requiresHIPAACompliance': json['requiresHIPAACompliance'],
        'requiredAge': !exists(json, 'requiredAge') ? undefined : json['requiredAge'],
        'discontinuedIndicator': json['discontinuedIndicator'],
    };
}

export function TransactionItemRequestToJSON(value?: TransactionItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionItemId': value.transactionItemId,
        'serverDetailID': value.serverDetailID,
        'serverTransactionID': value.serverTransactionID,
        'itemID': value.itemID,
        'itemNumber': value.itemNumber,
        'rx': value.rx,
        'fill': value.fill,
        'partial': value.partial,
        'site': value.site,
        'rxCode': value.rxCode,
        'cost': value.cost,
        'description': value.description,
        'quantity': value.quantity,
        'listPrice': value.listPrice,
        'price': value.price,
        'itemListPrice': value.itemListPrice,
        'saleType': value.saleType,
        'itemTypeID': value.itemTypeID,
        'discountPercent': value.discountPercent,
        'discount': value.discount,
        'extension': value.extension,
        'quantityReturned': value.quantityReturned,
        'qhpIndicator': value.qhpIndicator,
        'qhpPrivateIndicator': value.qhpPrivateIndicator,
        'taxableIndicator': value.taxableIndicator,
        'taxGroupID': value.taxGroupID,
        'priceChangeIndicator': value.priceChangeIndicator,
        'discountableIndicator': value.discountableIndicator,
        'noSafetyCapIndicator': value.noSafetyCapIndicator,
        'proofIDIndicator': value.proofIDIndicator,
        'unitOfMeasure': value.unitOfMeasure,
        'saleUnitOfMeasure': value.saleUnitOfMeasure,
        'counselRequiredIndicator': value.counselRequiredIndicator,
        'counselPromptIndicator': value.counselPromptIndicator,
        'captureRelationshipIndicator': value.captureRelationshipIndicator,
        'hipaaCompliant': value.hipaaCompliant,
        'hipaaRelationship': value.hipaaRelationship,
        'departmentID': value.departmentID,
        'controlGrams': value.controlGrams,
        'adjudicationDate': value.adjudicationDate === undefined ? undefined : (value.adjudicationDate === null ? null : value.adjudicationDate.toISOString()),
        'inventoryGroupID': value.inventoryGroupID,
        'updateInventoryIndicator': value.updateInventoryIndicator,
        'relatedDetailID': value.relatedDetailID,
        'familyDetailID': value.familyDetailID,
        'parentDetailID': value.parentDetailID,
        'kitIndicator': value.kitIndicator,
        'saleUnitOfMeasureID': value.saleUnitOfMeasureID,
        'unitOfMeasureRatio': value.unitOfMeasureRatio,
        'serialNumberIndicator': value.serialNumberIndicator,
        'rentalIndicator': value.rentalIndicator,
        'rentalPrice': value.rentalPrice,
        'rentalQuantity': value.rentalQuantity,
        'timeUnitOfMeasureID': value.timeUnitOfMeasureID,
        'employeeID': value.employeeID,
        'pricingMethod': value.pricingMethod,
        'extensionBeforeDiscount': value.extensionBeforeDiscount,
        'relatedDetailID2': value.relatedDetailID2,
        'saleIndicator': value.saleIndicator,
        'cashDisbursementReasonID': value.cashDisbursementReasonID,
        'discountReasonID': value.discountReasonID,
        'returnReasonID': value.returnReasonID,
        'vendor': value.vendor,
        'priceChangeReasonID': value.priceChangeReasonID,
        'originalPrice': value.originalPrice,
        'type2BarcodeIndicator': value.type2BarcodeIndicator,
        'weighedItemIndicator': value.weighedItemIndicator,
        'departmentKeyIndicator': value.departmentKeyIndicator,
        'attachmentIndicator': value.attachmentIndicator,
        'priceQuantity': value.priceQuantity,
        'manualWeighedIndicator': value.manualWeighedIndicator,
        'commitStockIndicator': value.commitStockIndicator,
        'priceLevelID': value.priceLevelID,
        'eventCodeID': value.eventCodeID,
        'priceGroupID': value.priceGroupID,
        'displayPrice': value.displayPrice,
        'vendorID': value.vendorID,
        'detailReference': value.detailReference,
        'negativeInventoryIndicator': value.negativeInventoryIndicator,
        'createItemNumber': value.createItemNumber,
        'directShipmentIndicator': value.directShipmentIndicator,
        'description2': value.description2,
        'discountType': value.discountType,
        'itemCrossReference': value.itemCrossReference,
        'signatureIndicator': value.signatureIndicator,
        'rxCustomerID': value.rxCustomerID,
        'categoryID': value.categoryID,
        'subCategoryID': value.subCategoryID,
        'deaClass': value.deaClass,
        'adjInsDate': value.adjInsDate === undefined ? undefined : (value.adjInsDate === null ? null : value.adjInsDate.toISOString()),
        'plan1': value.plan1,
        'planAmount1': value.planAmount1,
        'plan2': value.plan2,
        'planAmount2': value.planAmount2,
        'plan3': value.plan3,
        'planAmount3': value.planAmount3,
        'plan4': value.plan4,
        'planAmount4': value.planAmount4,
        'plan5': value.plan5,
        'planAmount5': value.planAmount5,
        'prescriptionID': value.prescriptionID,
        'ebtIndicator': value.ebtIndicator,
        'wicIndicator': value.wicIndicator,
        'rxRelationship': value.rxRelationship,
        'pickupAllEmployeeID': value.pickupAllEmployeeID,
        'customerMRN': value.customerMRN,
        'rxBarcodeScanData': value.rxBarcodeScanData,
        'controlledSubstanceIndicator': value.controlledSubstanceIndicator,
        'counselLater': value.counselLater,
        'counselorID': value.counselorID,
        'counselIndicator': value.counselIndicator,
        'offerCounsel': value.offerCounsel,
        'recordStatus': value.recordStatus,
        'dispensable': value.dispensable,
        'receiptDescription': value.receiptDescription,
        'medicationStatus': value.medicationStatus,
        'requiresHIPAACompliance': value.requiresHIPAACompliance,
        'requiredAge': value.requiredAge,
        'discontinuedIndicator': value.discontinuedIndicator,
    };
}


