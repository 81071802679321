/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    PaymentProcessorResponse,
    PaymentProcessorResponseFromJSON,
    PaymentProcessorResponseFromJSONTyped,
    PaymentProcessorResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentProcessorResponseApiResponse
 */
export interface PaymentProcessorResponseApiResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponseApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof PaymentProcessorResponseApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {PaymentProcessorResponse}
     * @memberof PaymentProcessorResponseApiResponse
     */
    data?: PaymentProcessorResponse;
}

export function PaymentProcessorResponseApiResponseFromJSON(json: any): PaymentProcessorResponseApiResponse {
    return PaymentProcessorResponseApiResponseFromJSONTyped(json, false);
}

export function PaymentProcessorResponseApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentProcessorResponseApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : PaymentProcessorResponseFromJSON(json['data']),
    };
}

export function PaymentProcessorResponseApiResponseToJSON(value?: PaymentProcessorResponseApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': PaymentProcessorResponseToJSON(value.data),
    };
}


