/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SitePhone,
    SitePhoneFromJSON,
    SitePhoneFromJSONTyped,
    SitePhoneToJSON,
} from './';

/**
 * 
 * @export
 * @interface SiteResponse
 */
export interface SiteResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SiteResponse
     */
    siteId: number;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    alternateNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    businessUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    department?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiteResponse
     */
    inventoryBase: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    location?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiteResponse
     */
    marketId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    operatingUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    recordStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiteResponse
     */
    siteType?: string | null;
    /**
     * 
     * @type {Array<SitePhone>}
     * @memberof SiteResponse
     */
    phones: Array<SitePhone>;
}

export function SiteResponseFromJSON(json: any): SiteResponse {
    return SiteResponseFromJSONTyped(json, false);
}

export function SiteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'siteId': json['siteId'],
        'number': json['number'],
        'alternateNumber': !exists(json, 'alternateNumber') ? undefined : json['alternateNumber'],
        'shortName': json['shortName'],
        'description': json['description'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'businessUnit': !exists(json, 'businessUnit') ? undefined : json['businessUnit'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'inventoryBase': json['inventoryBase'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'marketId': !exists(json, 'marketId') ? undefined : json['marketId'],
        'operatingUnit': !exists(json, 'operatingUnit') ? undefined : json['operatingUnit'],
        'recordStatus': !exists(json, 'recordStatus') ? undefined : json['recordStatus'],
        'siteType': !exists(json, 'siteType') ? undefined : json['siteType'],
        'phones': ((json['phones'] as Array<any>).map(SitePhoneFromJSON)),
    };
}

export function SiteResponseToJSON(value?: SiteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'siteId': value.siteId,
        'number': value.number,
        'alternateNumber': value.alternateNumber,
        'shortName': value.shortName,
        'description': value.description,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'county': value.county,
        'businessUnit': value.businessUnit,
        'company': value.company,
        'department': value.department,
        'inventoryBase': value.inventoryBase,
        'location': value.location,
        'marketId': value.marketId,
        'operatingUnit': value.operatingUnit,
        'recordStatus': value.recordStatus,
        'siteType': value.siteType,
        'phones': ((value.phones as Array<any>).map(SitePhoneToJSON)),
    };
}


