/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxGroup
 */
export interface TaxGroup {
    /**
     * 
     * @type {number}
     * @memberof TaxGroup
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TaxGroup
     */
    taxRateId: number;
    /**
     * 
     * @type {string}
     * @memberof TaxGroup
     */
    taxRateDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaxGroup
     */
    taxRate: number;
}

export function TaxGroupFromJSON(json: any): TaxGroup {
    return TaxGroupFromJSONTyped(json, false);
}

export function TaxGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'taxRateId': json['taxRateId'],
        'taxRateDescription': !exists(json, 'taxRateDescription') ? undefined : json['taxRateDescription'],
        'taxRate': json['taxRate'],
    };
}

export function TaxGroupToJSON(value?: TaxGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxRateId': value.taxRateId,
        'taxRateDescription': value.taxRateDescription,
        'taxRate': value.taxRate,
    };
}


