/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReceiptChannel,
    ReceiptChannelFromJSON,
    ReceiptChannelFromJSONTyped,
    ReceiptChannelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReceiptRequest
 */
export interface ReceiptRequest {
    /**
     * 
     * @type {ReceiptChannel}
     * @memberof ReceiptRequest
     */
    channel: ReceiptChannel;
    /**
     * 
     * @type {string}
     * @memberof ReceiptRequest
     */
    recipient: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptRequest
     */
    receiptNumber: number;
    /**
     * 
     * @type {number}
     * @memberof ReceiptRequest
     */
    siteId: number;
}

export function ReceiptRequestFromJSON(json: any): ReceiptRequest {
    return ReceiptRequestFromJSONTyped(json, false);
}

export function ReceiptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiptRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': ReceiptChannelFromJSON(json['channel']),
        'recipient': json['recipient'],
        'receiptNumber': json['receiptNumber'],
        'siteId': json['siteId'],
    };
}

export function ReceiptRequestToJSON(value?: ReceiptRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': ReceiptChannelToJSON(value.channel),
        'recipient': value.recipient,
        'receiptNumber': value.receiptNumber,
        'siteId': value.siteId,
    };
}


