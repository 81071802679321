/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerSearchCriteria
 */
export interface CustomerSearchCriteria {
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    mrn?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomerSearchCriteria
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    phone?: string | null;
}

export function CustomerSearchCriteriaFromJSON(json: any): CustomerSearchCriteria {
    return CustomerSearchCriteriaFromJSONTyped(json, false);
}

export function CustomerSearchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSearchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'mrn': !exists(json, 'mrn') ? undefined : json['mrn'],
        'dob': !exists(json, 'dob') ? undefined : (json['dob'] === null ? null : new Date(json['dob'])),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function CustomerSearchCriteriaToJSON(value?: CustomerSearchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastName': value.lastName,
        'firstName': value.firstName,
        'mrn': value.mrn,
        'dob': value.dob === undefined ? undefined : (value.dob === null ? null : value.dob.toISOString()),
        'phone': value.phone,
    };
}


