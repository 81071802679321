import {ComponentType, memo, PropsWithoutRef} from 'react';
import styled, {css} from 'styled-components';
import {ColorType, Icon, Icons, IconSize, mapColor} from './';

export enum Variant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Danger = 'Danger',
  Link = 'Link',
}
export enum Size {
  Standard = 'Standard',
  Small = 'Small',
}

export interface ButtonProps {
  as?: ComponentType<React.PropsWithChildren<unknown>>;
  color?: ColorType;
  disabled?: boolean;
  flex?: boolean;
  icon?: keyof typeof Icons;
  loading?: boolean;
  size?: Size;
  variant?: Variant;
}

const hoverStyles = css<ButtonProps>`
  background-color: ${({variant, disabled, theme, color}): string => {
    switch (variant) {
      case Variant.Secondary:
        return disabled ? theme.colors.gray_100 : theme.colors.gray_200;
      case Variant.Danger:
        return disabled ? theme.colors.gray_100 : theme.colors.gray_200;
      case Variant.Link:
        return 'transparent';
      case Variant.Primary:
      default:
        const hasColorProp = color ? mapColor(theme, color, !disabled) : null;
        const primaryColor = disabled ? theme.colors.blue : theme.colors.indigo;
        return hasColorProp || primaryColor;
    }
  }};
  color: ${({variant, theme, color}): string => {
    switch (variant) {
      case Variant.Secondary:
        return theme.colors.blue;
      case Variant.Danger:
        return theme.colors.error;
      case Variant.Link:
        return theme.colors.indigo;
      case Variant.Primary:
      default: {
        return color && color === 'inverted'
          ? theme.colors.indigo
          : theme.colors.white;
      }
    }
  }};
`;

const StyledButton = styled.button<{
  $loading: boolean;
  color?: ColorType;
  disabled?: boolean;
  size?: Size;
  $flex?: boolean;
  variant?: Variant;
}>`
  border: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({$flex}) => ($flex ? '1' : 'initial')};
  padding: ${({size}): string =>
    size === Size.Small ? '8px 22px' : '14px 40px'};
  height: ${({size}): string => (size === Size.Small ? '34px' : '50px')};
  font-family: ${({theme}): string => theme.typography.Main.fontFamily};
  font-size: ${({size, theme}): string =>
    size === Size.Small
      ? theme.typography.SubMain.fontSize
      : theme.typography.Main.fontSize};
  font-weight: ${({theme}): number => theme.typography.Main.fontWeight};
  white-space: nowrap;
  color: ${({variant, disabled, theme, color}): string => {
    switch (variant) {
      case Variant.Secondary:
        return theme.colors.blue;
      case Variant.Danger:
        return theme.colors.error;
      case Variant.Link:
        return disabled ? theme.colors.indigo : theme.colors.blue;
      case Variant.Primary:
      default:
        return color && color === 'inverted'
          ? disabled
            ? theme.colors.indigo
            : theme.colors.blue
          : theme.colors.white;
    }
  }};
  background-color: ${({variant, theme, color}): string => {
    switch (variant) {
      case Variant.Secondary:
        return theme.colors.gray_100;
      case Variant.Danger:
        return theme.colors.gray_100;
      case Variant.Link:
        return 'transparent';
      case Variant.Primary:
      default:
        return color ? mapColor(theme, color) : theme.colors.blue;
    }
  }};

  cursor: ${({disabled}): string => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}): string => (disabled ? '0.5' : '1')};
  transition: all 250ms ease;
  text-decoration: none;

  svg {
    ${props =>
      props.$loading
        ? {
            animationName: 'spin',
            animationDuration: '1s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'steps(8)',
          }
        : ''}
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }

  span:not(:first-child) {
    margin-left: 8px;
  }

  &:active {
    ${hoverStyles}
  }
  @media (hover: hover) {
    &:hover {
      ${hoverStyles}
    }
  }

  &:focus {
    outline: none;
  }
`;

const iconColor = (variant: Variant) => {
  switch (variant) {
    case 'Secondary':
    case 'Link':
      return 'primary';
    case 'Danger':
      return 'error';
    case 'Primary':
    default:
      return 'inverted';
  }
};

export const Button = memo(
  ({
    variant = Variant.Primary,
    size = Size.Standard,
    color,
    disabled,
    loading,
    icon,
    flex,
    ...props
  }: ButtonProps & PropsWithoutRef<JSX.IntrinsicElements['button']>) => {
    return (
      <StyledButton
        color={color}
        variant={variant}
        size={size}
        disabled={loading ? true : disabled}
        $flex={flex}
        // eslint-disable-next-line
        // @ts-ignore use a transient prop so this isn’t passed to the DOM
        $loading={loading}
        {...props}
      >
        {icon && (
          <Icon
            icon={loading ? 'Spinner' : icon}
            size={size === Size.Small ? IconSize.S : IconSize.M}
            variant={iconColor(variant)}
          />
        )}
        {loading && !icon && (
          <Icon
            icon="Spinner"
            size={size === Size.Small ? IconSize.S : IconSize.M}
            variant={iconColor(variant)}
          />
        )}
        <span>{props.children}</span>
      </StyledButton>
    );
  },
);
