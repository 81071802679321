/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionSignatureRequest
 */
export interface TransactionSignatureRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionSignatureRequest
     */
    transactionSignatureId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSignatureRequest
     */
    touchless?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionSignatureRequest
     */
    signatureImageId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionSignatureRequest
     */
    signatureTypeId: number;
}

export function TransactionSignatureRequestFromJSON(json: any): TransactionSignatureRequest {
    return TransactionSignatureRequestFromJSONTyped(json, false);
}

export function TransactionSignatureRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionSignatureRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionSignatureId': json['transactionSignatureId'],
        'touchless': !exists(json, 'touchless') ? undefined : json['touchless'],
        'signatureImageId': !exists(json, 'signatureImageId') ? undefined : json['signatureImageId'],
        'signatureTypeId': json['signatureTypeId'],
    };
}

export function TransactionSignatureRequestToJSON(value?: TransactionSignatureRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionSignatureId': value.transactionSignatureId,
        'touchless': value.touchless,
        'signatureImageId': value.signatureImageId,
        'signatureTypeId': value.signatureTypeId,
    };
}


