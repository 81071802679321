/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    Relationship,
    RelationshipFromJSON,
    RelationshipFromJSONTyped,
    RelationshipToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelationshipListApiResponse
 */
export interface RelationshipListApiResponse {
    /**
     * 
     * @type {string}
     * @memberof RelationshipListApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof RelationshipListApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {Array<Relationship>}
     * @memberof RelationshipListApiResponse
     */
    data?: Array<Relationship> | null;
}

export function RelationshipListApiResponseFromJSON(json: any): RelationshipListApiResponse {
    return RelationshipListApiResponseFromJSONTyped(json, false);
}

export function RelationshipListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationshipListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(RelationshipFromJSON)),
    };
}

export function RelationshipListApiResponseToJSON(value?: RelationshipListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(RelationshipToJSON)),
    };
}


