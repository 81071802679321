import {
  BarcodeCollection,
  Transaction,
  TransactionItem,
  OtcItem,
  Setting,
} from '@emporos/api-enterprise';
import {ScanResult} from '@emporos/barcodes';
import {
  Button,
  ButtonShape,
  ButtonSize as Size,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Modal,
  Row,
  RowItem,
  ScrollContainer,
  SmallSidebarWrapper,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
  ButtonSquareText,
  VerificationModal,
} from '@emporos/components';
import {PriceFooter} from '@emporos/components/src/PriceFooter';
import {NumberPanel} from '@emporos/components/src/Numberpad';
import {PageBarcodeScanner} from '@emporos/components-pos';
import {NavigateFn} from '@reach/router';
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useTransition,
} from 'react';
import styled from 'styled-components';
import {
  formatCustomerName,
  formatMedication,
  transactionItemIcons,
  TransactionUpdates,
  MultiSelectMode,
  MultiSelectResult,
  OfflineSynced,
  TotalsResult,
  updateDiscount,
  updatePrice,
  updateQuantity,
  useAlertState,
  useNetworkAvailable,
  ITEM_TYPE_IDS,
  useBetaFeatures,
  OfflineTransaction,
  normalizeCustomer,
  useAuthentication,
  StationType,
  useGlobalSettings,
} from '../../../';
import {mapComplianceIndicators, mapIdCheckIndicators} from './';
import {mergeTaxes} from '../../../utils/taxes';

import {PaymentService} from '../../../services/PaymentService';
import {useTransactionsState} from '../../../';
import {usePaymentsWindow} from '../../../contexts/PaymentsWindowProvider';
import {FeatureConfig} from '../../../services/IPaymentService';
import {isMailout} from '../../../utils/session';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    padding: 0 12px 20px 12px;
  }
  @media (max-width: 1180px) {
    margin-left: 15px;
  }
`;

export const ActionButton = styled(ButtonSquareText)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;

export interface TransactionPageProps {
  transaction: Transaction;
  canDeleteTransaction: boolean;
  totals: TotalsResult;
  settings: Setting[];
  otcItems: OtcItem[];
  barcodes: BarcodeCollection;
  multiselect: MultiSelectResult<string>;
  enabledFeatures: FeatureConfig[];
  stationType: StationType;
  navigate: NavigateFn;
  onRemoveTransaction: (transaction: Transaction) => void;
  onUpdateTransaction: (updates: TransactionUpdates) => Promise<void>;
  onUpdateTransactionItem: (
    item: TransactionItem,
    updates: Partial<TransactionItem>,
  ) => void;
  setCurrentTransactionId: Dispatch<SetStateAction<string>>;
  onScan: (scanResult: ScanResult) => void;
}

function TransactionComponent({
  transaction,
  canDeleteTransaction,
  totals,
  settings,
  multiselect,
  enabledFeatures,
  stationType,
  navigate,
  onRemoveTransaction,
  onUpdateTransaction,
  onUpdateTransactionItem,
  onScan,
}: TransactionPageProps): JSX.Element {
  const [, startTransition] = useTransition();
  const {notification} = useAlertState();
  const {online} = useNetworkAvailable();
  // To ensure feature flag prevents verification functionality
  const {verificationModal} = useBetaFeatures();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showMailoutPseErrorModal, setShowMailoutPseErrorModal] =
    useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showChangePriceNumpad, setShowChangePriceNumpad] = useState(false);
  const [showDiscountNumpad, setShowDiscountNumpad] = useState(false);
  const [showQuantityNumpad, setShowQuantityNumpad] = useState(false);
  const [numpadNumber, setNumpadNumber] = useState('');
  const [ltpClicked, setLtpClicked] = useState(false);
  const useMailoutFlow = isMailout(stationType);

  const {isNplex, isControlledSubstance, isIDRequiredOTC, isRequiredAge} =
    mapIdCheckIndicators(transaction);
  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const {items, customer, taxes} = transaction;

  const {user} = useAuthentication();
  const token = user ? user.access_token : '';
  const {savingSession, setCurrentTransactionId} = useTransactionsState();
  const {paymentsWindowService} = usePaymentsWindow();
  const {tenantId} = useGlobalSettings();

  const single = multiselect.single();

  const showComplianceUrl =
    showCounsel || showHipaa || showRelationship
      ? './payments/compliance'
      : './payments/customer-payment';

  const url = useMemo(() => {
    if (useMailoutFlow) {
      return './payments/customer-payment';
    } else
      return !transaction.identification &&
        ((isNplex && transaction.pseCheckResult !== 1) ||
          isControlledSubstance ||
          isRequiredAge ||
          isIDRequiredOTC)
        ? './payments/id-check'
        : showComplianceUrl;
  }, [
    transaction.identification,
    transaction.pseCheckResult,
    isControlledSubstance,
    isNplex,
    isIDRequiredOTC,
    isRequiredAge,
    showComplianceUrl,
  ]);

  const ltpDisabled =
    totals.totalDue <= 0 ||
    !enabledFeatures.map(ef => ef.feature).includes('LinkToPay') ||
    !settings?.some(({name, value}) => {
      return name === 'LinkToPaySMSEnabled' && value === '1';
    }) ||
    !customer ||
    !online;

  const createClickHandlersForItem = (item: TransactionItem) => {
    return {
      onClick: () => {
        if (showChangePriceNumpad || showDiscountNumpad || showQuantityNumpad) {
          return;
        }

        if (multiselect.isSelected(item.transactionItemId)) {
          multiselect.deselect(item.transactionItemId);
        } else if (multiselect.mode === MultiSelectMode.Multiple) {
          multiselect.selectMultiple(item.transactionItemId);
        } else {
          multiselect.selectOne(item.transactionItemId);
        }
      },
    };
  };

  const rxItems = useMemo(() => {
    return items.filter(({rx}) => rx);
  }, [items]);

  const continueTransactionButtonText = useMemo(() => {
    if (useMailoutFlow) {
      return 'Accept Payment';
    } else
      return !transaction.identification &&
        ((isNplex && transaction.pseCheckResult !== 1) ||
          isIDRequiredOTC ||
          isRequiredAge ||
          !!rxItems.length)
        ? 'Proceed to Compliance'
        : 'Accept Payment';
  }, [
    transaction.identification,
    transaction.pseCheckResult,
    isNplex,
    isIDRequiredOTC,
    isRequiredAge,
    rxItems,
  ]);

  // When ltpClicked is true we want to call the paymentService, but not until the transaction has been updated
  useEffect(() => {
    const openLtp = async () => {
      if (ltpClicked && !savingSession) {
        setLtpClicked(false);
        const paymentService = new PaymentService(token, tenantId);
        await paymentService.RedirectLtpSms(transaction, paymentsWindowService);

        setCurrentTransactionId('');
      }
    };

    openLtp();
  }, [savingSession, ltpClicked]);

  const baseUpdateTransaction = useCallback(async () => {
    let fieldsToUpdate = {
      isSynced: false,
      taxableSubTotal: totals.taxableSubTotal,
      discount: totals.discount,
      subTotal: totals.subTotal,
      totalSale: totals.transactionTotal,
      totalTax: totals.salesTax,
      qhpRxAmount: totals.qhpRxAmount,
      qhpAmount: totals.qhpAmount,
      qhpRxQty: totals.qhpRxQty,
      qhpOtherAmount: totals.qhpOtherAmount,
      qhpOtherQty: totals.qhpOtherQty,
      taxes: mergeTaxes(taxes, totals.transactionTaxes),
    } as Partial<Transaction> & OfflineSynced;

    if (!!rxItems.length) {
      fieldsToUpdate = {
        ...fieldsToUpdate,
        isVerified: true,
      } as Partial<Transaction> & OfflineSynced;
    }

    await onUpdateTransaction(fieldsToUpdate);
  }, [taxes, totals]);

  const updateTransaction = useCallback(() => {
    setShowVerificationModal(false);
    baseUpdateTransaction();
    return continueTransaction();
  }, [taxes, totals]);

  const validateTransaction = useCallback(() => {
    if (useMailoutFlow && isNplex) {
      return setShowMailoutPseErrorModal(true);
    }
    if (
      !useMailoutFlow &&
      !!rxItems.length &&
      !(transaction as OfflineTransaction)?.isVerified
    ) {
      setShowVerificationModal(true);
    } else {
      updateTransaction();
    }
  }, [transaction, updateTransaction, rxItems]);

  const continueTransaction = () => {
    if (isNplex && transaction.pseCheckResult !== 1 && !online) {
      notification({
        title: 'Offline Error: PSE Items',
        description:
          'This transaction cannot move forward while offline with unapproved PSE items in the cart.',
        type: 'warning',
        icon: 'Warning',
      });
      return;
    }
    return navigate(url, {
      state: {animatePanel: true},
    });
  };

  return (
    <>
      <Stack
        data-testid="transaction-page"
        gutter={Gutter.None}
        style={{height: '100%'}}
      >
        <Header
          title={customer ? formatCustomerName(customer) : 'General Sale'}
          badgeText={transaction.roomNumber || ''}
          data-testid="navbar-header"
        >
          <ButtonShape
            size={Size.Small}
            icon="Messageltp"
            disabled={ltpDisabled}
            onClick={() => {
              baseUpdateTransaction().then(() => {
                startTransition(() => {
                  setLtpClicked(true);
                });
              });
            }}
            data-testid="smsltp"
          />
          <ButtonShape
            size={Size.Small}
            icon="User"
            onClick={() => navigate('customer')}
            data-testid="customer-info-btn"
          />
          <ButtonShape
            disabled={!canDeleteTransaction}
            size={Size.Small}
            icon="Trash"
            onClick={() => setDeleteModalOpen(true)}
            data-testid="delete"
          />
        </Header>

        <Row
          gutter={Gutter.XXL}
          style={{height: '100%', marginTop: 20, minHeight: 0}}
          $noWrap
        >
          <Stack className="transaction-mobile" style={{flex: 1}}>
            {!items.length && (
              <Stack
                align="center"
                justify="center"
                gutter={Gutter.None}
                style={{flex: 1}}
              >
                <Illustration illustration="AddProduct" />
                <Text
                  variant={TV.Main}
                  data-testid="no-items-added"
                  align="center"
                >
                  No items added
                </Text>
              </Stack>
            )}

            {!!items.length && (
              <ScrollContainer>
                {!!rxItems.length && (
                  <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Prescriptions
                    </Text>
                    {rxItems.map(item => (
                      <RowItem
                        key={item.transactionItemId}
                        title={formatMedication(item.description2 || '')}
                        subtitle={item.receiptDescription || ''}
                        rightIcons={transactionItemIcons(item)}
                        rightText={`$${item.price.toFixed(2)}`}
                        selected={multiselect.isSelected(
                          item.transactionItemId,
                        )}
                        {...createClickHandlersForItem(item)}
                      />
                    ))}
                  </Stack>
                )}
                {items.some(({rx}) => !rx) && (
                  <Stack gutter={Gutter.S}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Over the Counter
                    </Text>
                    {items
                      .filter(({rx}) => !rx)
                      .map(item => (
                        <RowItem
                          key={item.transactionItemId}
                          inactive={
                            item.itemTypeID === ITEM_TYPE_IDS.METH_FREE &&
                            transaction.pseCheckResult === 1
                          }
                          variant="generic"
                          title={formatMedication(item.description || '')}
                          subtitle={item.itemNumber || ''}
                          rightIcons={transactionItemIcons(item)}
                          rightText={`$${item.price.toFixed(2)}`}
                          rightBadgeColor="success"
                          rightBadgeText={
                            item.discountPercent
                              ? `${(item.discountPercent * 100).toFixed(0)}%`
                              : ''
                          }
                          rightStrikeText={
                            item.price !== item.listPrice && item.discount === 0
                              ? `$${item.listPrice.toFixed(2)}`
                              : ''
                          }
                          selected={multiselect.isSelected(
                            item.transactionItemId,
                          )}
                          quantity={
                            item.quantity > 0 ? item.quantity.toString() : ''
                          }
                          {...createClickHandlersForItem(item)}
                        />
                      ))}
                  </Stack>
                )}
              </ScrollContainer>
            )}

            <Stack style={{marginTop: 0}}>
              <PriceFooter
                totals={totals}
                style={{marginBottom: 16, marginTop: 0}}
              />
              <FooterGroup>
                <Button
                  data-testid="otc-search-btn"
                  variant={BV.Secondary}
                  flex
                  onClick={() => navigate('otc-search')}
                >
                  OTC Search
                </Button>
                <Button
                  flex
                  data-testid="accept-payment-btn"
                  disabled={!items.length}
                  onClick={validateTransaction}
                >
                  {continueTransactionButtonText}
                </Button>
              </FooterGroup>
            </Stack>
          </Stack>

          <RightSidebar>
            <Row justify="space-between" gutter={Gutter.None}>
              <ActionButton
                type="button"
                icon="CashRegister"
                text="Price"
                data-testid="price-button"
                disabled={
                  true || // TODO: temporarily disabled until requirements and permissions are ready
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );

                  setNumpadNumber(item ? item.price.toFixed(2) : '0.00');
                  setShowChangePriceNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Percent2"
                text="Discount"
                data-testid="discount-button"
                disabled={
                  true || // TODO: temporarily disabled until requirements and permissions are ready
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );
                  setNumpadNumber(
                    item ? (item.discountPercent * 100).toFixed(0) : '0',
                  );
                  setShowDiscountNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Quantity"
                text="Quantity"
                data-testid="quantity-button"
                disabled={
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );
                  setNumpadNumber(item ? item.quantity.toString() : '0');
                  setShowQuantityNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="XCircle"
                text="Remove"
                disabled={multiselect.size === 0}
                onClick={() => {
                  onUpdateTransaction(prevTransaction => ({
                    ...prevTransaction,
                    items: prevTransaction.items.filter(
                      transactionItem =>
                        !multiselect.isSelected(
                          transactionItem.transactionItemId,
                        ),
                    ),
                  }));

                  multiselect.reset();
                }}
              />
            </Row>
          </RightSidebar>
        </Row>

        {single && (
          <>
            <NumberPanel
              title="Discount"
              type="percent"
              number={numpadNumber}
              setNumber={n => setNumpadNumber(Number(n) > 100 ? '100' : n)}
              defaultValue="0%"
              onClear={() => setNumpadNumber('0')}
              onConfirm={() => {
                const item = items.find(
                  ({transactionItemId}) => transactionItemId === single,
                );
                if (!item) {
                  return;
                }
                const percent = Number(numpadNumber.replace('%', '')) / 100;
                const updatedItem = updateDiscount(item, percent);
                onUpdateTransactionItem(item, {
                  ...updatedItem,
                });
                setShowDiscountNumpad(false);
              }}
              onCancel={() => {
                setShowDiscountNumpad(false);
              }}
              open={showDiscountNumpad}
            />

            <NumberPanel
              title="Quantity"
              number={numpadNumber}
              setNumber={n => {
                const negativeQuantity = Number(n) < 1 ? '1' : n;
                setNumpadNumber(Number(n) > 99 ? '99' : negativeQuantity);
              }}
              onClear={() => setNumpadNumber('')}
              onConfirm={() => {
                const item = items.find(
                  ({transactionItemId}) => transactionItemId === single,
                );
                if (!item) {
                  return;
                }

                const quantity = Number(numpadNumber);
                const updatedItem = updateQuantity(item, quantity);
                onUpdateTransactionItem(item, {
                  ...updatedItem,
                });
                setShowQuantityNumpad(false);
              }}
              onCancel={() => {
                setShowQuantityNumpad(false);
              }}
              open={showQuantityNumpad}
            />
            {items.some(item => !item.rx) && (
              <NumberPanel
                title="Price Change"
                type="price"
                number={numpadNumber}
                setNumber={setNumpadNumber}
                defaultValue="$0.00"
                onClear={() => setNumpadNumber('0')}
                onConfirm={() => {
                  const item = items.find(
                    ({transactionItemId}) => transactionItemId === single,
                  );
                  if (!item) {
                    return;
                  }
                  const price = Number(numpadNumber);
                  const updatedItem = updatePrice(item, price);
                  onUpdateTransactionItem(item, {
                    ...updatedItem,
                  });
                  setShowChangePriceNumpad(false);
                }}
                onCancel={() => {
                  setShowChangePriceNumpad(false);
                }}
                open={showChangePriceNumpad}
              />
            )}
          </>
        )}

        <Modal
          data-testid="delete-modal"
          visible={deleteModalOpen}
          icon="Trash"
          color="error"
          onCancel={() => setDeleteModalOpen(false)}
          onContinue={() => {
            onRemoveTransaction(transaction);

            return navigate('/sales');
          }}
          title="Delete This Transaction"
          subtitle="This transaction will be deleted and cannot be recovered. Would you like to continue?"
        />
        <Modal
          data-testid="pseError-modal"
          visible={showMailoutPseErrorModal}
          icon="Trash"
          color="error"
          onContinue={() => setShowMailoutPseErrorModal(false)}
          title="PSE Items Not Available For Mailout Transactions"
          subtitle="Mailout transactions cannot contain PSE items. Remove the PSE items from the transaction in order to continue."
        />
      </Stack>
      {!showChangePriceNumpad &&
        !showQuantityNumpad &&
        !showDiscountNumpad &&
        online && <PageBarcodeScanner onScan={onScan} />}
      {verificationModal && customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={updateTransaction}
        />
      )}
    </>
  );
}

export const TransactionPage = memo(TransactionComponent);
