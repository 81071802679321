/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerFromJSONTyped,
    CustomerToJSON,
    PseCheckResultEnum,
    PseCheckResultEnumFromJSON,
    PseCheckResultEnumFromJSONTyped,
    PseCheckResultEnumToJSON,
    TransactionExtra,
    TransactionExtraFromJSON,
    TransactionExtraFromJSONTyped,
    TransactionExtraToJSON,
    TransactionIdentification,
    TransactionIdentificationFromJSON,
    TransactionIdentificationFromJSONTyped,
    TransactionIdentificationToJSON,
    TransactionItem,
    TransactionItemFromJSON,
    TransactionItemFromJSONTyped,
    TransactionItemToJSON,
    TransactionPayment,
    TransactionPaymentFromJSON,
    TransactionPaymentFromJSONTyped,
    TransactionPaymentToJSON,
    TransactionSignature,
    TransactionSignatureFromJSON,
    TransactionSignatureFromJSONTyped,
    TransactionSignatureToJSON,
    TransactionStatusEnum,
    TransactionStatusEnumFromJSON,
    TransactionStatusEnumFromJSONTyped,
    TransactionStatusEnumToJSON,
    TransactionTax,
    TransactionTaxFromJSON,
    TransactionTaxFromJSONTyped,
    TransactionTaxToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    serverTransactionID: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    serverTransactionNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    saleDate: Date;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    subTotal: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    taxableSubTotal: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    totalCost: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    totalSale: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    source?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpRxQty: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpRxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpOtherQty: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpOtherAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpCount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    qhpAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    qhpPartial: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    pseApprovedTransID?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    consumerVerifyID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    deliveryMethods?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    station: number;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    drawerDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    employeeID: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    taxExemptIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    xFileIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    xFileOriginatingTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    xFileTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    taxExemptNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    orderID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    sourceID: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    onHoldAtServerIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    saleSiteID: number;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    salesAuditDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    salesAuditIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    dispensingID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    signatureStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    rxSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sudafedSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    safetyCapsSigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    pmsStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    ageSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    age?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    onDeliveryBypassCounselPrompt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    onDeliveryBypassHIPAAPrompt: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    emailReceipt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    textReceipt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    poNumber?: string | null;
    /**
     * 
     * @type {Array<TransactionItem>}
     * @memberof Transaction
     */
    items: Array<TransactionItem>;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    customerId?: number | null;
    /**
     * 
     * @type {Customer}
     * @memberof Transaction
     */
    customer?: Customer;
    /**
     * 
     * @type {Array<TransactionPayment>}
     * @memberof Transaction
     */
    payments: Array<TransactionPayment>;
    /**
     * 
     * @type {Array<TransactionTax>}
     * @memberof Transaction
     */
    taxes: Array<TransactionTax>;
    /**
     * 
     * @type {Array<TransactionSignature>}
     * @memberof Transaction
     */
    signatures: Array<TransactionSignature>;
    /**
     * 
     * @type {Array<TransactionExtra>}
     * @memberof Transaction
     */
    extras: Array<TransactionExtra>;
    /**
     * 
     * @type {TransactionIdentification}
     * @memberof Transaction
     */
    identification?: TransactionIdentification;
    /**
     * 
     * @type {PseCheckResultEnum}
     * @memberof Transaction
     */
    pseCheckResult: PseCheckResultEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    dataVersion?: string | null;
    /**
     * 
     * @type {TransactionStatusEnum}
     * @memberof Transaction
     */
    status: TransactionStatusEnum;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'sessionId': json['sessionId'],
        'serverTransactionID': json['serverTransactionID'],
        'serverTransactionNumber': json['serverTransactionNumber'],
        'saleDate': (new Date(json['saleDate'])),
        'subTotal': json['subTotal'],
        'taxableSubTotal': json['taxableSubTotal'],
        'totalCost': json['totalCost'],
        'discount': json['discount'],
        'totalTax': json['totalTax'],
        'totalSale': json['totalSale'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'qhpRxQty': json['qhpRxQty'],
        'qhpRxAmount': json['qhpRxAmount'],
        'qhpOtherQty': json['qhpOtherQty'],
        'qhpOtherAmount': json['qhpOtherAmount'],
        'qhpCount': json['qhpCount'],
        'qhpAmount': json['qhpAmount'],
        'qhpPartial': json['qhpPartial'],
        'pseApprovedTransID': !exists(json, 'pseApprovedTransID') ? undefined : json['pseApprovedTransID'],
        'consumerVerifyID': !exists(json, 'consumerVerifyID') ? undefined : json['consumerVerifyID'],
        'deliveryMethods': !exists(json, 'deliveryMethods') ? undefined : json['deliveryMethods'],
        'station': json['station'],
        'drawerDate': !exists(json, 'drawerDate') ? undefined : (json['drawerDate'] === null ? null : new Date(json['drawerDate'])),
        'employeeID': json['employeeID'],
        'taxExemptIndicator': json['taxExemptIndicator'],
        'xFileIndicator': json['xFileIndicator'],
        'xFileOriginatingTransactionID': !exists(json, 'xFileOriginatingTransactionID') ? undefined : json['xFileOriginatingTransactionID'],
        'xFileTransactionID': !exists(json, 'xFileTransactionID') ? undefined : json['xFileTransactionID'],
        'taxExemptNumber': !exists(json, 'taxExemptNumber') ? undefined : json['taxExemptNumber'],
        'orderID': !exists(json, 'orderID') ? undefined : json['orderID'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'recordStatus': json['recordStatus'],
        'sourceID': json['sourceID'],
        'onHoldAtServerIndicator': json['onHoldAtServerIndicator'],
        'saleSiteID': json['saleSiteID'],
        'salesAuditDate': !exists(json, 'salesAuditDate') ? undefined : (json['salesAuditDate'] === null ? null : new Date(json['salesAuditDate'])),
        'salesAuditIndicator': json['salesAuditIndicator'],
        'dispensingID': !exists(json, 'dispensingID') ? undefined : json['dispensingID'],
        'signatureStatus': !exists(json, 'signatureStatus') ? undefined : json['signatureStatus'],
        'rxSigType': !exists(json, 'rxSigType') ? undefined : json['rxSigType'],
        'sudafedSigType': !exists(json, 'sudafedSigType') ? undefined : json['sudafedSigType'],
        'safetyCapsSigType': !exists(json, 'safetyCapsSigType') ? undefined : json['safetyCapsSigType'],
        'pmsStatus': !exists(json, 'pmsStatus') ? undefined : json['pmsStatus'],
        'ageSource': !exists(json, 'ageSource') ? undefined : json['ageSource'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'onDeliveryBypassCounselPrompt': json['onDeliveryBypassCounselPrompt'],
        'onDeliveryBypassHIPAAPrompt': json['onDeliveryBypassHIPAAPrompt'],
        'emailReceipt': !exists(json, 'emailReceipt') ? undefined : json['emailReceipt'],
        'textReceipt': !exists(json, 'textReceipt') ? undefined : json['textReceipt'],
        'poNumber': !exists(json, 'poNumber') ? undefined : json['poNumber'],
        'items': ((json['items'] as Array<any>).map(TransactionItemFromJSON)),
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customer': !exists(json, 'customer') ? undefined : CustomerFromJSON(json['customer']),
        'payments': ((json['payments'] as Array<any>).map(TransactionPaymentFromJSON)),
        'taxes': ((json['taxes'] as Array<any>).map(TransactionTaxFromJSON)),
        'signatures': ((json['signatures'] as Array<any>).map(TransactionSignatureFromJSON)),
        'extras': ((json['extras'] as Array<any>).map(TransactionExtraFromJSON)),
        'identification': !exists(json, 'identification') ? undefined : TransactionIdentificationFromJSON(json['identification']),
        'pseCheckResult': PseCheckResultEnumFromJSON(json['pseCheckResult']),
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'status': TransactionStatusEnumFromJSON(json['status']),
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'sessionId': value.sessionId,
        'serverTransactionID': value.serverTransactionID,
        'serverTransactionNumber': value.serverTransactionNumber,
        'saleDate': (value.saleDate.toISOString()),
        'subTotal': value.subTotal,
        'taxableSubTotal': value.taxableSubTotal,
        'totalCost': value.totalCost,
        'discount': value.discount,
        'totalTax': value.totalTax,
        'totalSale': value.totalSale,
        'source': value.source,
        'qhpRxQty': value.qhpRxQty,
        'qhpRxAmount': value.qhpRxAmount,
        'qhpOtherQty': value.qhpOtherQty,
        'qhpOtherAmount': value.qhpOtherAmount,
        'qhpCount': value.qhpCount,
        'qhpAmount': value.qhpAmount,
        'qhpPartial': value.qhpPartial,
        'pseApprovedTransID': value.pseApprovedTransID,
        'consumerVerifyID': value.consumerVerifyID,
        'deliveryMethods': value.deliveryMethods,
        'station': value.station,
        'drawerDate': value.drawerDate === undefined ? undefined : (value.drawerDate === null ? null : value.drawerDate.toISOString()),
        'employeeID': value.employeeID,
        'taxExemptIndicator': value.taxExemptIndicator,
        'xFileIndicator': value.xFileIndicator,
        'xFileOriginatingTransactionID': value.xFileOriginatingTransactionID,
        'xFileTransactionID': value.xFileTransactionID,
        'taxExemptNumber': value.taxExemptNumber,
        'orderID': value.orderID,
        'reference': value.reference,
        'recordStatus': value.recordStatus,
        'sourceID': value.sourceID,
        'onHoldAtServerIndicator': value.onHoldAtServerIndicator,
        'saleSiteID': value.saleSiteID,
        'salesAuditDate': value.salesAuditDate === undefined ? undefined : (value.salesAuditDate === null ? null : value.salesAuditDate.toISOString()),
        'salesAuditIndicator': value.salesAuditIndicator,
        'dispensingID': value.dispensingID,
        'signatureStatus': value.signatureStatus,
        'rxSigType': value.rxSigType,
        'sudafedSigType': value.sudafedSigType,
        'safetyCapsSigType': value.safetyCapsSigType,
        'pmsStatus': value.pmsStatus,
        'ageSource': value.ageSource,
        'age': value.age,
        'onDeliveryBypassCounselPrompt': value.onDeliveryBypassCounselPrompt,
        'onDeliveryBypassHIPAAPrompt': value.onDeliveryBypassHIPAAPrompt,
        'emailReceipt': value.emailReceipt,
        'textReceipt': value.textReceipt,
        'poNumber': value.poNumber,
        'items': ((value.items as Array<any>).map(TransactionItemToJSON)),
        'customerId': value.customerId,
        'customer': CustomerToJSON(value.customer),
        'payments': ((value.payments as Array<any>).map(TransactionPaymentToJSON)),
        'taxes': ((value.taxes as Array<any>).map(TransactionTaxToJSON)),
        'signatures': ((value.signatures as Array<any>).map(TransactionSignatureToJSON)),
        'extras': ((value.extras as Array<any>).map(TransactionExtraToJSON)),
        'identification': TransactionIdentificationToJSON(value.identification),
        'pseCheckResult': PseCheckResultEnumToJSON(value.pseCheckResult),
        'roomNumber': value.roomNumber,
        'notes': value.notes,
        'dataVersion': value.dataVersion,
        'status': TransactionStatusEnumToJSON(value.status),
    };
}


