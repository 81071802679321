import {Page} from '@emporos/components';
import {
  NavigateFn,
  RouteComponentProps,
  useMatch,
  WindowLocation,
} from '@reach/router';
import assert from 'assert';
import {ComponentType, PropsWithChildren} from 'react';
import * as React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const SLIDE_TRANSITION_DURATION = 400;

type WithPageChildrenProps = PropsWithChildren<RouteComponentProps>;

export function withRootPage<P extends WithPageChildrenProps>(
  Component: ComponentType<
    React.PropsWithChildren<
      P & {navigate: NavigateFn; location: WindowLocation}
    >
  >,
): ComponentType<React.PropsWithChildren<P>> {
  return function WithPanelChildren(props: P) {
    const {children, navigate, location, ...componentProps} = props;

    assert(
      navigate,
      'Component wrapped by withPage must be a child of a Router',
    );
    assert(
      location,
      'Component wrapped by withPage must be a child of a Router',
    );

    const key = useMatch(props.uri as string)?.path;

    return (
      <>
        <Component
          {...(componentProps as P)}
          navigate={navigate}
          location={location}
        />
        {children && (
          <TransitionGroup className="transition-group" appear>
            <CSSTransition
              key={key}
              classNames="slide"
              timeout={SLIDE_TRANSITION_DURATION}
            >
              {children}
            </CSSTransition>
          </TransitionGroup>
        )}
      </>
    );
  };
}

export type PageProps<P = unknown> = P & {
  location: WindowLocation;
  navigate: NavigateFn;
};

export type WithPageProps<P = unknown> = P &
  RouteComponentProps & {children?: React.ReactNode};

export function withChildPage<
  Props extends PropsWithChildren<RouteComponentProps>,
>(
  Component: React.ComponentType<React.PropsWithChildren<PageProps<Props>>>,
): React.ComponentType<React.PropsWithChildren<WithPageProps<Props>>> {
  const ComponentWithRootPage = withRootPage(Component);

  return function WithChildPage(props: WithPageProps<Props>) {
    return (
      <Page open className="page animate">
        <ComponentWithRootPage {...props} />
      </Page>
    );
  };
}
