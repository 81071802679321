import {TransactionStatusEnum} from '@emporos/api-enterprise';
import {Table} from 'dexie';
import {TransactionConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {ITransactionDatabaseAccess} from './IDatabaseAccess';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
import {reduceSignatures} from '../utils';
import {
  EncryptedTransaction,
  TransactionDbPackage,
  generateTransactionPackage,
  generateTransactionPackages,
} from '../utils/crypto';

// instantiate with DIFactory.getTransactionDatabaseAccess to use in base react classes (uses a hook)
export class TransactionLocaldb implements ITransactionDatabaseAccess {
  db!: HiloDb;
  table!: Table<TransactionConsolidate>;
  private _sessionId!: string;
  private _accessCode!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  initialize = (sessionId: string, accessCode: string): void => {
    this._sessionId = sessionId;
    this._accessCode = accessCode;
    this.db = new HiloDb(this._sessionId, this._accessCode);
    this.table = this.db.TransactionLocaldb;
  };

  async getAll(
    status?: TransactionStatusEnum,
  ): Promise<TransactionConsolidate[]> {
    this._consoleLogger.styledLog(
      'Getting All Transactions with Session ID:',
      ConsoleLoggerVariant.GREEN,
      this._sessionId,
    );

    if (status) {
      const encryptedTransactions = await this.table
        .where({sessionId: this._sessionId, status: status})
        .toArray();
      const transactionPackages = generateTransactionPackages(
        encryptedTransactions as EncryptedTransaction[],
      );
      return transactionPackages.map(
        transactionPackage => transactionPackage.transaction,
      );
    } else {
      const encryptedTransactions = await this.table
        .where({sessionId: this._sessionId})
        .toArray();
      const transactionPackages = generateTransactionPackages(
        encryptedTransactions as EncryptedTransaction[],
      );
      return transactionPackages.map(
        transactionPackage => transactionPackage.transaction,
      );
    }
  }

  async getNonSessionUnsyncedEncryptedTransactions(): Promise<
    EncryptedTransaction[]
  > {
    this._consoleLogger.styledLog(
      'Getting All Unsynced Transactions where Session ID is not: ',
      ConsoleLoggerVariant.GREEN,
      this._sessionId,
    );
    const encryptedTransactions = await this.table.toArray();
    return encryptedTransactions.filter(
      x => x.sessionId != this._sessionId && !x.isSynced,
    ) as EncryptedTransaction[];
  }

  preprocessTransaction = (
    transaction: TransactionConsolidate,
  ): TransactionConsolidate => {
    // remove cipherTextByteArray and secretByteArray from transaction in the IndexDB record
    const obj = {
      ...transaction,
      signatures: reduceSignatures(transaction.signatures),
      cipherTextByteArray: null,
      secretByteArray: null,
    };

    return obj;
  };

  async get(transactionId: string): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'GettingTransaction with ID:',
      ConsoleLoggerVariant.GREEN,
      transactionId,
    );

    const transactionresult = await this.table.get(transactionId);
    if (!transactionresult) {
      return {} as TransactionConsolidate;
    }

    const transactionPackage = generateTransactionPackage(
      transactionresult as EncryptedTransaction,
    );
    if (transactionPackage?.transaction) {
      return transactionPackage.transaction;
    } else {
      return {} as TransactionConsolidate;
    }
  }

  async getDbPackage(transactionId: string): Promise<TransactionDbPackage> {
    this._consoleLogger.styledLog(
      'GettingTransactionPackage with ID:',
      ConsoleLoggerVariant.GREEN,
      transactionId,
    );

    const transactionresult = await this.table.get(transactionId);
    if (!transactionresult) {
      return {} as TransactionDbPackage;
    }

    const transactionPackage = generateTransactionPackage(
      transactionresult as EncryptedTransaction,
    );
    if (!transactionPackage) {
      return {} as TransactionDbPackage;
    }

    return transactionPackage;
  }

  async add(
    transaction: TransactionConsolidate,
  ): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'Adding Transaction:',
      ConsoleLoggerVariant.GREEN,
      transaction,
    );

    const processedTransaction = this.preprocessTransaction(transaction);

    await this.table.put(processedTransaction);
    return processedTransaction;
  }

  async update(
    transaction: TransactionConsolidate,
  ): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'Updating Transaction:',
      ConsoleLoggerVariant.GREEN,
      transaction,
    );

    const processedTransaction = this.preprocessTransaction(transaction);

    await this.table.update(transaction.transactionId, processedTransaction);

    return processedTransaction;
  }

  async delete(transactionId: string): Promise<void> {
    this._consoleLogger.styledLog(
      'Deleting Transaction:',
      ConsoleLoggerVariant.GREEN,
      transactionId,
    );

    return this.table.delete(transactionId);
  }

  async deleteAll(): Promise<void> {
    this._consoleLogger.styledLog(
      'Deleting All Transactions',
      ConsoleLoggerVariant.GREEN,
    );

    return this.table.clear();
  }
}
