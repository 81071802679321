/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CacheFlushApiResponse,
    CacheFlushApiResponseFromJSON,
    CacheFlushApiResponseToJSON,
    OtcItemListApiResponse,
    OtcItemListApiResponseFromJSON,
    OtcItemListApiResponseToJSON,
} from '../models';

export interface ClientOtcItemsSiteIdGetRequest {
    siteId: number;
    includesChildren?: boolean;
    includesDeleted?: boolean;
    sort?: Array<string>;
    page?: number;
    pageSize?: number;
    lastDate?: Date;
}

export interface ClientOtcItemsSiteIdRequiresfulldownloadGetRequest {
    siteId: number;
    lastDate?: Date;
}

/**
 * 
 */
export class OtcItemsApi extends runtime.BaseAPI {

    /**
     * Gets OtcItems.
     */
    async clientOtcItemsSiteIdGetRaw(requestParameters: ClientOtcItemsSiteIdGetRequest): Promise<runtime.ApiResponse<OtcItemListApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientOtcItemsSiteIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includesChildren !== undefined) {
            queryParameters['IncludesChildren'] = requestParameters.includesChildren;
        }

        if (requestParameters.includesDeleted !== undefined) {
            queryParameters['IncludesDeleted'] = requestParameters.includesDeleted;
        }

        if (requestParameters.sort) {
            queryParameters['Sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.lastDate !== undefined) {
            queryParameters['lastDate'] = (requestParameters.lastDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/otc-items/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OtcItemListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets OtcItems.
     */
    async clientOtcItemsSiteIdGet(requestParameters: ClientOtcItemsSiteIdGetRequest): Promise<OtcItemListApiResponse> {
        const response = await this.clientOtcItemsSiteIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientOtcItemsSiteIdRequiresfulldownloadGetRaw(requestParameters: ClientOtcItemsSiteIdRequiresfulldownloadGetRequest): Promise<runtime.ApiResponse<CacheFlushApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientOtcItemsSiteIdRequiresfulldownloadGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastDate !== undefined) {
            queryParameters['lastDate'] = (requestParameters.lastDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/otc-items/{siteId}/requiresfulldownload`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CacheFlushApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientOtcItemsSiteIdRequiresfulldownloadGet(requestParameters: ClientOtcItemsSiteIdRequiresfulldownloadGetRequest): Promise<CacheFlushApiResponse> {
        const response = await this.clientOtcItemsSiteIdRequiresfulldownloadGetRaw(requestParameters);
        return await response.value();
    }

}
