/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PmsBarcodeMapping
 */
export interface PmsBarcodeMapping {
    /**
     * 
     * @type {string}
     * @memberof PmsBarcodeMapping
     */
    barcodeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PmsBarcodeMapping
     */
    pmsName?: string | null;
}

export function PmsBarcodeMappingFromJSON(json: any): PmsBarcodeMapping {
    return PmsBarcodeMappingFromJSONTyped(json, false);
}

export function PmsBarcodeMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PmsBarcodeMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'barcodeName': !exists(json, 'barcodeName') ? undefined : json['barcodeName'],
        'pmsName': !exists(json, 'pmsName') ? undefined : json['pmsName'],
    };
}

export function PmsBarcodeMappingToJSON(value?: PmsBarcodeMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'barcodeName': value.barcodeName,
        'pmsName': value.pmsName,
    };
}


