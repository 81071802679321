import {ReactNode} from 'react';
import {AutoSizer, List, ListRowRenderer} from 'react-virtualized';

export interface VirtualizedListProps<T> {
  data: Array<T>;
  listHeight: number;
  rowHeight?: number;
  renderItem: (item: T) => ReactNode;
  scrollIndex?: number;
}

export function VirtualizedList<T>({
  data,
  listHeight,
  rowHeight = 96,
  scrollIndex,
  renderItem,
}: VirtualizedListProps<T>): JSX.Element {
  const rowRenderer: ListRowRenderer = ({index, key, style}) => (
    <div key={key} style={style}>
      {renderItem(data[index])}
    </div>
  );

  return (
    <AutoSizer disableHeight>
      {({width}) => (
        <List
          style={{outline: 'none'}}
          height={listHeight}
          overscanRowCount={10}
          rowCount={data.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
          scrollToAlignment="start"
          scrollToIndex={scrollIndex}
          width={width}
        />
      )}
    </AutoSizer>
  );
}
