/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * App_Version: (not available)    Build_Version: (not available)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailReceiptResponse,
    EmailReceiptResponseFromJSON,
    EmailReceiptResponseFromJSONTyped,
    EmailReceiptResponseToJSON,
    TextReceiptResponse,
    TextReceiptResponseFromJSON,
    TextReceiptResponseFromJSONTyped,
    TextReceiptResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReceiptResponse
 */
export interface ReceiptResponse {
    /**
     * 
     * @type {TextReceiptResponse}
     * @memberof ReceiptResponse
     */
    textReceiptResponse?: TextReceiptResponse;
    /**
     * 
     * @type {EmailReceiptResponse}
     * @memberof ReceiptResponse
     */
    emailReceiptResponse?: EmailReceiptResponse;
}

export function ReceiptResponseFromJSON(json: any): ReceiptResponse {
    return ReceiptResponseFromJSONTyped(json, false);
}

export function ReceiptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiptResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textReceiptResponse': !exists(json, 'textReceiptResponse') ? undefined : TextReceiptResponseFromJSON(json['textReceiptResponse']),
        'emailReceiptResponse': !exists(json, 'emailReceiptResponse') ? undefined : EmailReceiptResponseFromJSON(json['emailReceiptResponse']),
    };
}

export function ReceiptResponseToJSON(value?: ReceiptResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textReceiptResponse': TextReceiptResponseToJSON(value.textReceiptResponse),
        'emailReceiptResponse': EmailReceiptResponseToJSON(value.emailReceiptResponse),
    };
}


